.footer {
    padding-bottom: 12px;
    
    margin-top: 90px;
    @include break-md-up {
        margin-top: 11%;
        padding-bottom: 24px;
    }
    @include break-lg-up {
        font-size: 14px;
    }

    .food-icons-wrapper {
        width: 295px;
        margin-left: auto;
        position: relative;
        max-width: calc(100% + 20px);
        left: -10px;
        margin-top: -82px;
        @include break-md-up {
            order: 1;
            max-width: 100%;
            margin-top: -10.8%;
            width: 51.6%;
            left: 0;
        }
    }
    .food-icons {
        position: relative;
        padding-top: 54.3%;
    
        img {
            position: absolute;
            height: auto;
    
            &:nth-child(1) {
                width: 21%;
                top: 38%;
                right: 0;
            }
            &:nth-child(2) {
                width: 21%;
                top: 38%;
                right: 20.9%;
            }
            &:nth-child(3) {
                width: 21%;
                top: 20%;
                right: 30.4%;
            }
            &:nth-child(4) {
                width: 12.88%;
                top: 76.3%;
                right: 7.8%;
            }
            &:nth-child(5) {
                width: 21%;
                top: 0;
                right: 9.4%;
            }
            &:nth-child(6) {
                width: 30.5%;
                top: 38.4%;
                right: 41.8%;
            }
            &:nth-child(7) {
                width: 28.13%;
                top: 38.4%;
                left: 0;
            }
        }
    }
}
.footer-content {
    background-color: $color-blue;
    color: $color-white;
    border-radius: 15px;
    padding: 20px 30px;
    a {
        color: $color-white;
    }

    &__main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include break-xs-up {
            gap: 30px;
            // grid-template-columns: 2fr 1fr;
            grid-template-columns: 2fr 1.5fr;
        }
        @include break-md-up {
            display: flex;
            justify-content: space-between;
            gap: 60px;
        }
        @include break-lg-up {
            font-size: 12px;
        }
        @include break-xl-up {
            font-size: 17px;
        }
    }
    &__top {
        @include break-xs-up {
            display: flex;
            flex-direction: row-reverse;
        }
        @include break-md-up {
            flex-direction: row;
            margin-bottom: 3%;
        }
    }
    @include break-md-up {
        border-radius: 35px;
        padding: 1px 50px 30px;
    }
    @include break-lg-up {
        padding-left: 100px;
        padding-right: 100px;
    }
}
.footer-col {
    @include break-md-up {
        flex-grow: 1;
    }
    &--left {
        columns: 3;
        gap: 60px;
    }
    &--right {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        gap: 25px;
        position: relative;
        @include break-xs-up {
            flex-direction: row-reverse
        }
        @include break-md-up {            
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0 60px;
        }
        @include break-lg-up {
            margin-right: -50px;
        }
    }
}
.footer-menu {
    display: none;
    @include break-md-up {
        display: block;
    }
}
.footer-menu-mob {
    @include break-xs-up {
        columns: 2;
    }
    @include break-md-up {
        display: none;
    }
}
.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-top: 15px;
    @include break-md-up {
        display: none;
    }
}
.footer-socials {
    @include break-md-up {
        br {
            display: none;
        }
    }
}
.footer-menu-block {
    break-inside: avoid-column;
    @include break-md-up {
        margin-bottom: 2.4em;
    }
    &__anchor-wrapper {
        margin-bottom: 1.4em;
    }
    &__anchor {
        color: $color-white;
        font-size: 1.4em;
        border-bottom: 1px solid $color-white-30;
        @include fontWeight400;
        @include transition-regular;
        &:hover {
            color: $color-orange;
        }
    }
    &__item-link {
        font-size: 1em;
        @include transition-regular;
        &:hover {
            color: $color-orange
        }
    }
    &__items li:not(:last-child) {
        margin-bottom: 7px;
    }
}
.footer-link {
    border-bottom: 1px solid $color-white-30;
    @include break-md-up {
        white-space: nowrap;
    }
    @include transition-regular;
    &:hover {
        color: $color-orange;
    }
}
.copyright {
    @include break-md-up {
        white-space: nowrap;
    }
}
.subscribe-block-wrapper {
    @include break-md-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }
}
.subscribe-block {
    form {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }
    &__title {
        @include fontWeight600;
    }
    &__input {
        opacity: .4;
        height: 2.6em;
        padding: .8em;
        border: 1px solid;
        border-radius: 7px;
        color: $color-white;
        background-color: transparent;
        &:focus {
            opacity: 1;
        }
        &::placeholder {
            color: $color-white;
        }
        @include break-xl-up {
            padding: .8em 1.3em;
            border-radius: 10px;
        }
    }
}
.footer-slogan {
    margin-bottom: 50px;
    margin-top: 30px;
    img {
        width: 172px;
    }
    @include break-md-up {
        width: 30.5%;
        margin-top: 6.5%;
        img {
            width: 100%;
        }
    }
}
.footer-bottom-element {
    display: none;
    @include break-md-up {
        margin-top: 45px;
        display: block;

        .footer-socials ~ & {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}
.footer-socials {
    display: flex;
    gap: 12px;
    padding-top: 14px;
    @include break-md-down {
        flex-wrap: wrap;
        max-width: 130px;
    }
}