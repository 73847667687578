button {
    user-select: none;
}
.btn:not(.accordeon__headline) {
    @include button;
    @include transition-regular;
    display: inline-flex;
    &:hover {
        background-color: $color-yellow !important;
        // color: $color-blue;
    }
    &.btn-big {
        font-size: 14px;
        height: 32px;
        @include fontWeight400;
        @include break-md-up {
            height: 50px;
            font-size: 16px;
            border-radius: 12px;
    
            svg {
                width: 28px;
                height: auto;
            }
        }
        @include break-xl-up {
            font-size: 24px;
            height: 77px;
            border-radius: 24px;
            gap: 25px;
            padding-left: 1.7em;     
            padding-right: 1.7em;     
    
            svg {
                width: 40px;
            }
        }
    }
    &.btn-primary {
        background-color: $color-blue;
        color: $color-white !important;
    }
}

.button {
    @include button;
    @include transition-regular;
    @include fontWeight400;
    &:hover {
        background-color: $color-white;
        color: $color-blue;
    }
    &--width-auto {
        width: fit-content;
    }
    &--bordered {
        border: 1px solid;
    }
    &--footer {
        @include break-md-down {
            font-size: 10px;
        }
    }
    &--bordered-white {
        border-color: $color-white
    }
    &--yellow {
        background-color: $color-yellow;
        color: $color-blue;
        stroke-width: 2px;
    }
    &--blue {
        background-color: $color-blue;
        color: $color-white;
    }
    &--green {
        background-color: $color-light-green;
        color: $color-white;

        &:hover {
            color: $color-light-green;
            border: inset 1px solid;
        }
    }
    &--bordered-orange {
        border: 1px solid $color-orange;
        color: $color-blue;

        /* @include break-lg-up {
            font-size: 12px;
        } */
        &:hover {
            background-color: $color-orange;
            color: $color-white;
        }
        svg {
            @include break-lg-up {
                width: 17px;
                height: 17px;
            }
            @include break-xl-up {
                width: 26px;
                height: 25px;
            }
        }
    }
    &--icon-orange {
        svg {
            color: $color-orange;
        }
        &:hover svg {
            color: $color-white;
        }
    }
    &--bordered-s {
        box-shadow: 0px 0px 0px 1px currentColor;
    }
    &--orange {
        background-color: $color-orange;
        color: $color-white;
        svg use {
            fill: $color-yellow
        }
        &:hover {
            background-color: $color-orange;
            color: $color-blue;
        }
    }
    &--orange2 {
        color: $color-white;
        background-color: $color-orange;
        &:hover {
            color: $color-orange
        }
    }
    &--bigger {
        @include fontWeight400;
        @include break-md-up {
            height: 50px;
            font-size: 16px;
            border-radius: 12px;

            svg {
                width: 28px;
                height: auto;
            }
        }
        @include break-xl-up {
            font-size: 24px;
            height: 77px;
            border-radius: 24px;
            gap: 25px;
            padding-left: 1.4em;     
            padding-right: 1.4em;     

            svg {
                width: 40px;
            }
        }
    }
    &--biggest {
        height: 77px;
        font-size: 20px;
        border-radius: 24px;
        justify-content: center;
        width: 290px;
        max-width: 100%;
        @include fontWeightNormal;
    }
    &--widget {
        min-height: 37px;
        justify-content: center;
        font-size: 14px;
        @include break-lg-up {
            font-size: 16px;
            min-height: 45px;
            border-radius: 10px;
            padding: 0 30px;
        }
        @include break-xl-up {
            border-radius: 16px;
            min-height: 53px;
        }
    }
    &--widget-no-bg {
        color: $color-blue;
        border: 1px solid $color-white;

        @include break-lg-up {
            border-width: 2px;
        }
    }
    &--centered {
        text-align: center;
        justify-content: center
    }
}
.button-orange {
    @include button;
    font-size: 10px;
    background-color: $color-orange;
    color: $color-white;
    padding: 0.8em 1.5em;

    .header & {
        box-shadow: 0px 0px 14px 0px rgba(52, 191, 198, 0.60);
    }

    @include break-xs-down {
        height: 34px;
    }
    @include break-md-up {
        // height: 34px;
        font-size: 10px;
        padding-left: 2.2em;
        padding-right: 2.2em;
    }
    @include break-lg-up {
        padding-left: 1.8em;
        padding-right: 1.8em;
        svg {
            width: 22px;
            height: auto;
        }
    }
    @include break-xl-up {
        padding-left: 1.7em;
        padding-right: 1.7em;
        font-size: 16px;
    }
    
    /* @include break-xl-up {
        padding: 0.9em 1.6em;
    } */

    .header &:hover {
        background-color: $color-white;
    }
    &:hover .button-text {
        color: $color-blue;
    }
    &--bigger {
        @include break-md-up {
            border-radius: 16px;
            font-size: 16px;
            height: 53px;
        }
    }
}
.button-text {
    @include transition-regular;
}

.button-small {
    background-color: $color-blue;
    color: $color-white;
    border: 2px solid $color-white;
    @include transition-regular;
    @include fontWeight400;
    font-size: 12px;
    padding: 0.4em 1.6em;
    border-radius: 23px;
    &:hover {
        background-color: $color-white;
        color: $color-blue;
    }
    /* @include break-lg-up {
        font-size: 10px;
    } */
    @include break-xl-up {
        font-size: 14px;
    }
}

.submit-button {
    background-color: $color-blue;
    color: $color-white;
    padding: .75em 1.85em;
    border-radius: $form-control-radius;
    cursor: pointer;
    @include transition-regular;
    &:hover {
        background-color: $color-orange;
    }
    &--fs {
        @include text-small;
        @include button-height;
        border-radius: 7px;
        padding: 0 3em;
        @include break-xl-up {
            border-radius: 16px;
        }
    }
}

.button-nav {
    height: 28px;
    border: 1px solid $color-orange-30;
    padding: 0 11px;
    @include text-normal;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: $color-blue;
    border-radius: 100px;

    @include transition-regular;
    @include break-md-up {
        height: 36px;
        padding: 0 17px;
    }
    @include break-lg-up {
        height: 46px;
        gap: 11px;
    }
    @include break-xl-up {
        height: 60px;
        padding: 0 25px;
        gap: 15px;
    }

    &:hover {
        background-color: $color-blue;
        border-color: $color-blue;
        color: $color-white;
    }

    img {
        width: 12px;
        height: auto;
        @include break-lg-up {
            width: 23px;
        }
        @include break-xl-up {
            width: 28px;
        }
    }
    &--prev {
        img {
            transform: scaleX(-1);
        }
    }
    &--next {
        margin-left: auto;
    }
}
.button-listen {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: $color-orange;
    @include fontWeight400;
    @include transition-regular;
    @include break-lg-up {
        font-size: 20px;
    }
    @include break-xl-up {
        font-size: 24px;
        gap: 20px;
    }
    &:hover {
        color: $color-blue;
    }
    svg {
        @include break-lg-up {
            width: 45px;
            height: 45px;
        }
        @include break-xl-up {
            width: 53px;
            height: 53px;
        }
        use {
            @include break-lg-down {
                stroke-width: 2px;
            }
        }
    }
}
.button-white {
    border: 1px solid $color-white;
    color: $color-blue;
    display: inline-flex;

    @include fontWeight400;
    @include break-md-up {
        border-width: 2px;
    }
    @include break-lg-up {
        font-size: 12px;
    }
    @include break-xl-up {
        font-size: 16px;
    }
    svg {
        color: $color-white;
    }
    &:hover {
        border-color: $color-yellow;
        svg {
            color: $color-yellow;
        }
    }
    &--white-text {
        color: $color-white;
        &:hover {
            color: $color-blue;
            border-color: $color-white;
        }
        .sidebar & {
            @include break-md-up {
                height: auto;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
            }
            @include break-xl-up {
                height: 60px;            
            }
        }
        
    }
}
.button-blue {
    border: 1px solid $color-blue;
    color: $color-blue;
    display: inline-flex;

    @include fontWeight400;
    @include break-md-up {
        border-width: 2px;
    }
    @include break-lg-up {
        font-size: 12px;
    }
    @include break-xl-up {
        font-size: 16px;
    }
    &:hover {
        background-color: $color-blue;
        color: $color-white;
    }
}


.big-button {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    margin: 20px 0;
    width: 100%;
    @include transition-regular;

    @include fontWeight600;
    @include break-sm-up {
        height: 60px;
        font-size: 18px;
    }
    @include break-md-up {
        border-width: 2px;
    }
    @include break-lg-up {
        margin: 40px 0;
        font-size: 30px;
        height: 80px;
        border-radius: 25px;
    }
    @include break-xl-up {
        font-size: 40px;
        height: 110px;
    }
    
    &--no-bg {
        border-color: $color-blue-30; 
        color: $color-orange;
        &:hover {
            border-color: $color-orange;
            color: $color-white;
            background-color: $color-orange;
        }
    }
    &--w-bg {
        background-color: $color-orange;
        color: $color-white;
        &:hover {
            border-color: $color-blue-30;
            color: $color-orange;
            background-color: $color-white;
        }
    }
    &--height-lower {
        margin: 0;
        @include fontWeight400;
        @include break-lg-up {
            height: 77px;
            font-size: 30px;
        }
    }
}

.button-centered {
    justify-content: center;
    text-align: center;
}
.button-auto-height {
    height: auto;
    padding-top: 1em;
    padding-bottom: 1em;
}