.lg {
	&-on {
		overflow: hidden;
		position: fixed;
		left: 0;
		right: 0;
    }

	&-actions {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		transition: all 0.5s;
		width: 100%; }

	&-outer {
		transform: translate3d(0, 0, 0);

		.lg {
			&-inner,
			&-sub-html,
			&-toolbar,
			&-thumb-outer {
				transition: all 0.5s;
				transform: translateZ(0); }

			&-sub-html {
				padding-right: 225px;

				@media(max-width: 1024px) {
					padding-right: 0; } } }


		&.lg-thumb-open {
			// padding-right: 225px
			// box-sizing: border-box

			.lg {
				&-inner,
				&-toolbar,
				&-actions {
					@media(min-width: 1025px) {
						width: calc(100% - 226px); } } }

			.lg-thumb-outer {
				transform: translate3d(0px, 0, 0)!important; } } } }


.lightgallery {
	display: block;
	width: 100%;
	max-width: 100%;
	height: 550px;
	overflow: hidden;
	margin-bottom: 34px;
	position: relative;

	.lg-inner {
		transition: all 1s; }

	/* @media(max-width: $xs) {
		height: 400px;
		margin-bottom: 20px; } */

	&-desc {
		padding: 0 85px;
		margin-top: 20px;
		padding-left: 90px;

		p {
			// font-family: $sans-l;
			color: #36123a; } }

	&-wrapper {
		margin-top: 30px;
		margin-bottom: 30px;
		// width: 1200px
		background: #FFF;
		// padding: 40px
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
		position: relative; }

/* 	a {
		display: flex;
		width: 100%;
		height: 100%;
		border-bottom: none!important;
		justify-content: center;
		overflow: hidden;
		border-radius: 8px;

		&:hover {
			border-bottom: none; }

		img {
			display: block;
			width: auto;
			height: 100%;
			max-height: 750px;
			border-radius: 8px;
			overflow: hidden; } } */


	&-wrapper {
		display: block;
		overflow: hidden; }
}

.lg {
	&:hover,
	&:focus,
	&:hover:focus {
		outline: none; }

	&-item,
	&-inner {
		&:hover,
		&:focus,
		&:hover:focus {
			outline: none; } }


	&-outer {
		&:hover,
		&:hover:focus {
			outline: none;

			text: #f37125; }

		.lg-thumb-item {
			border-radius: 8px;

			&.active,
			&:hover {
				border-color: $color-orange; } } }

	&-backdrop {
		background: rgba(255,255,255,0.9); }

	&-toolbar {
		background: rgba(255,255,255,0.5);

		@media(max-width: 1024px) {
			background: #FFF; } }

	&-sub-html {
		background: rgba(243, 113, 37, 0.75);
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 40px;
		padding-right: 40px;
		text-align: left;

        @include break-xs-down {
            padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 10px;
			padding-right: 10px;
        }
    }

	&-toogle-thumb {
		background: transparent!important;

        @include break-xs-down {
            right: 0!important;
        }
        @include break-md-down {
            border-radius: 0px!important;
        }
    }

	&-img-wrap {
		padding-top: 0px!important;
		padding-bottom: 0px !important;
		width: 100%;
		max-width: 100%;

        @include break-xs-down {
            padding: 0!important;
        }
    }

	#lg-counter {
		color: $color-black;
		font-size: 20px;
		// font-family: $sans-l;
		font-weight: normal;
        
        @include break-xs-down {
            font-size: 14px;
			padding: 0 0 0 10px;
			height: 47px;
			line-height: 52px;
        }
    }

	&-actions {
		.lg-next,
		.lg-prev {
			background: transparent;
			width: 90px;
			height: 90px;
			margin-top: 0;
			transform: translate(0, -50%);
			transition: all 0.4s;
			opacity: 0.7;

			&:hover {
				opacity: 1; }

            @include break-sm-down {
                width: 40px;
				height: 40px;
            }

			&:after,
			&:before {
				display: none; } }

		.lg-prev {
			background: url('../images/gallery/left-chevron.svg') center center no-repeat;
			background-size: contain;
            @include break-sm-down {
                left: 5px;
            }
        }

		.lg-next {
			background: url('../images/gallery/right-chevron.svg') center center no-repeat;
			background-size: contain;

            @include break-sm-down {
                right: 5px;
            }
        }
    }

	&-toogle-thumb {
		top: -59px!important;

		@include break-xs-down {
			top: -39px !important; }

		&:after {
			color: #fff; } }

	&-thumb {
		padding: 0;

		&-outer {
			padding-left: 10px;
			height: 120px!important;
			background: #FFF!important;

			img {
				object-fit: cover; } } } }


.lg-progress-bar {
	background: #fff;

	.lg-progress {
		background: $color-orange; } }

.lg-outer.lg-pull-caption-up.lg-thumb-open {
	.lg-sub-html {
		bottom: 120px; } }

#lg-share-vk {
	.lg-icon {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' '!important;
			background: url('../images/gallery/vk.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; } } }

#lg-share-instagram {
	.lg-icon {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' '!important;
			background: url('../images/gallery/instagram.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; } } }

.lg-backdrop {
    z-index: 99990;
}
.lg-outer {
    z-index: 99999;
	.lg-dropdown {

		@include break-xs-down {
			right: initial;
			top: 60px;
            width: 120px;

			&:after {
				right: initial;
				left: 12px; } }

		.lg-icon {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			align-self: flex-start;
			margin: 0;
			text-decoration: none;
			// font-family: $sans-l;
			color: $color-black!important;
			font-size: 14px;

			@include break-xs-down {
				flex-direction: row-reverse;
				flex-grow: 1;
				justify-content: flex-end; }

			&:after {
				margin-right: 5px!important;

				@include break-xs-down {
					margin-left: 0 !important; } } } } }

.lg-dropdown .lg-icon {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	align-self: flex-start;
	margin: 0;
	text-decoration: none;
	font-family: 'Museo Sans Cyrl', Helvetica, Arial, sans-serif;
	color: $color-black!important;
	font-size: 14px;

	&:after {
		margin-right: 5px!important; } }

.lg-outer .lg-dropdown {
	li > a {
		display: flex;
		text-decoration: none; } }

#lg-share-ok {
	.lg-icon {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' '!important;
			background: url('../images/gallery/ok.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; } } }

#lg-share-facebook {
	.lg-icon {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' '!important;
			background: url('../images/gallery/facebook.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; } } }

#lg-share-twitter {
	.lg-icon {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' '!important;
			background: url('../images/gallery/twitter.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; } } }


.lg-toolbar {
	opacity: 1 !important;
	transform: none!important;

	.lg-icon {
		background: #FFF;
		// display: flex
		// align-items: center
		transition: all 0.4s;

		&:hover {
			background: $color-orange;
            color: $color-white !important;
        }

		&:after {
			transition: all 0.4s; }

        @include break-xs-down {
			width: 40px !important;
			font-weight: normal; } }

	.lg-close {
		&:after {
			display: block;
			width: 32px;
			height: 32px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/cross.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal;

			@include break-xs-down {
				width: 20px;
				height: 20px; } }

		&:hover {
			&:after {
				background: url('../images/gallery/cross-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } }

	.lg-autoplay-button {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/play-button.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/play-button-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } }

	.lg-fullscreen {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/resize-full.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/resize-full-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } }

        @include break-xs-down {
			display: none!important; } }

	#lg-zoom-in {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/plus.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/plus-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } }

	#lg-zoom-out {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/minus.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/minus-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } }

	#lg-copy-url {
		// position: relative

		&:after {
			display: block;
			content: ' ';
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/link.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/link-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } } }

.input {
	&-url {
		position: absolute;
    	left: -9999px;
	}
}		

.lg-close {
	padding-top: 8px !important; }

.lg {
	&-toolbar {
 } }		// opacity: 1 !important

.lightgallery-controls {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 101;
	display: flex;

	@include break-xs-down {
		top: 5px;
		right: 5px; }

	&-btn {
		margin-left: 6px;
		width: 25px;
		height: 25px;
		-webkit-border-radius: 5px;
		border-radius: 5px;
		border: 1px solid #fff;
		background-color: $color-orange;
		cursor: pointer;

		.lg {
			&-dropdown {
				a {
					&:hover {
						border-bottom: none !important; } } } } } }

.lg-fullscreen-on {
	.lg-fullscreen {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/resize-mini.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/resize-mini-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } } }


.lg-show-autoplay {
	.lg-autoplay-button {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/pause.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/pause-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } } }

.lg-outer {
	#lg-share {
		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/share.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/share-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } }

	.lg-download {
		display: flex;
		align-items: center;

		&:after {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: auto;
			margin-right: auto;
			content: ' ';
			background: url('../images/gallery/download.svg') center center no-repeat;
			background-size: contain;
			font-weight: normal; }

		&:hover {
			&:after {
				background: url('../images/gallery/download-white.svg') center center no-repeat;
				background-size: contain;
				font-weight: normal; } } }

	#lg-zoom-in {
		&:after {
			// display: block
			// width: 20px
			// height: 20px
			// margin-left: auto
			// margin-right: auto
			// content: ' '
			// background: url('../img/gallery/icons/share.svg') center center no-repeat
			// background-size: contain
 } } }			// font-weight: normal

.lightgallery-controls {
	&--share {
		background-image: url('../img/gallery/icons/share-white.svg');
		background-size: 15px;
		background-repeat: no-repeat;
		background-position: center center;
		position: relative;

		.lg-dropdown {
			top: 40px;
			background: #FFF;
			position: absolute;
			right: 0;
			padding: 10px 0;
			border-radius: 2px;
			transition: all 0.4s;
			opacity: 0;
			pointer-events: none;
			border: 1px solid $color-blue-30;

			@include break-xs-down {
				padding-top: 5px;
				padding-bottom: 5px;
				width: 100px; }

			&:after {
				content: ' ';
				display: block;
				width: 0;
				height: 0;
				position: absolute;
				border: 8px solid transparent;
				border-bottom-color: #FFF;
				right: 5px;
				top: -16px; }

			&:before {
				content: ' ';
				display: block;
				width: 0;
				height: 0;
				position: absolute;
				border: 8px solid transparent;
				border-bottom-color: $color-blue-30;
				right: 5px;
				top: -17px; }



			& > li {
				display: flex;

				padding-left: 0 !important;


				&:before {
					display: none; }

				a {
					text-decoration: none;
					padding: 4px 12px;
					display: flex;
					width: 100%;
					justify-content: flex-start;
					transition: all 0.4s;

					&:hover {
						background: rgba(0, 0, 0, 0.07); }

					border-bottom: none;


					&:hover,
					&:hover:focus {
						@include break-xs-down {
							border-bottom: none; } } }

				&:last-child {
					@include break-xs-down {
						margin-bottom: 0; } } } }

		&.is-share {
			.lg-dropdown {
				opacity: 1;
				pointer-events: all; } } }

	&--fullscreen {
		background-image: url('../images/gallery/full-screen.svg');
		background-size: 15px;
		background-repeat: no-repeat;
		background-position: center center; } }

.lg-thumb-outer {
	@media(min-width: 1025px) {
		padding-right: 10px;
		overflow-y: auto;
		width: 226px;
		max-width: 226px;
		max-height: 100%!important;
		height: 100%!important;
		top: 0;
		right: 0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		transform: translate3d(225px, 0, 0)!important;
		border-left: 1px solid #f7941d;
		box-sizing: border-box;
 }		// padding-left: 50px

	@media(max-width: 1024px) {
		transition: all 0.25s!important;
		transform: translate3d(0, 100%, 0)!important; }

	&::-webkit-scrollbar {
		width: 0; }

	&::-webkit-scrollbar-track {
		width: 0;
		background: transparent; }

	&::-webkit-scrollbar-thumb {
		background: transparent; }

	.lg-thumb {
 }		// background: red

	.lg-thumb-item {
		&:nth-child(even) {
			@media(min-width: 1025px) {
				margin-right: 0 !important; } } } }


.lg-outer {
	&:hover,
	&:focus,
	&:hover:focus {
		outline: none; } }

.lg-thumb-outer .lg-toogle-thumb {
	display: none!important; }

.lg-toogle-thumb.lg-icon {
	position: static;
	height: 47px;
	background: #FFF!important;

	&:after {
		color: #F37125;
		transition: all 0.4s; }

	&:hover {
		background: $color-orange!important;

		&:after {
			color: #fff; } } }

#lg-zoom-out {
	opacity: 1; }