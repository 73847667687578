$fonts-dir: '../fonts/';

$color-black: black;
$color-white: #ffffff;
$color-white-20: rgba(255,255,255,.2);
$color-white-30: rgba(255,255,255,.3);
$color-orange: #E7650C;
$color-orange-15: rgba(231, 101, 12, 0.15);
$color-orange-20: rgba(231, 101, 12, 0.20);
$color-orange-30: rgba(231, 101, 12, 0.30);
$color-orange-40: rgba(231, 101, 12, 0.40);
$color-blue: #054678;
$color-blue-5: rgba(5,70,120,.05);
$color-blue-7: rgba(5,70,120,.07);
$color-blue-15: rgba(5,70,120,.15);
$color-blue-20: rgba(5,70,120,.2);
$color-blue-30: rgba(5,70,120,.3);
$color-blue-40: rgba(5,70,120,.4);
$color-yellow: #F8B508;
$color-purple: #7F55AA;
$color-green: #027500;
$color-light-green: #19B100;
$color-light-green-10: rgba(25, 177, 0, .1);
$color-glow-cyan: rgba(52, 191, 198, 0.60);
$color-light-blue: rgba(5, 70, 120, 0.07);
$color-light-blue10: rgba(5, 70, 120, 0.10); 
$color-light-blue15: rgba(5, 70, 120, 0.15); 
$color-light-blue50: rgba(5, 70, 120, 0.50);
$color-light-blue40: rgba(5, 70, 120, 0.40);
$color-error: red;
$color-red: #E70C41;

$container-padding: 12px;
$container-padding-sm: 36px;
$container-max-width: 1798px;
$container-padding-bigger: 32px;
$container-padding-bigger-sm: 101px;

$transition-duration: 200ms;
$transition-duration500: 500ms;
$box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

$form-control-radius: 7px;

$content-element-mb: 18px;
$content-element-mb--lg: 24px;
$content-element-mb--xl: 30px;

$floatBlockWidthSmall: 37.4%;
$floatBlockWidthMedium: 47.2%;
$floatBlockWidthBig: 68.8%;

/* header vars */
$headerGutterX: 20px;
$headerGutterBiggerX: 30px;
$headerGutterBiggestX: 80px;
$headerInputGutter: 15px;
$headerMobMenuBuffer: 37px;
/* header vars */

$triangelSize: 18px;
$triangelSize--lg: 24px;
$triangelSize--xl: 38px;

$helpHeroSlideMargin: 66px;

$formElementGap: 20px;

$imageContainerAspectRatio: 52.43%;