.swiper-button-disabled {
    opacity: .5;
    pointer-events: none;
}

$heroSliderPadding: 20px;
$heroSliderPaddingMd: 55px;
$heroSliderPaddingXl: 84px;
.hero-slider {
    position: relative;
    padding-top: 10px;
    margin-bottom: 124px;

    @include break-xl-up {
        margin-bottom: 175px;
    }

    .swiper {
        border-radius: 15px;
        @include break-lg-up {
            border-radius: 35px;
        }
        @include break-xl-up {
            border-radius: 50px;
        }
    }
}
    
.hero-slider-slogan {
    background-color: $color-orange;
    padding: 17px 18px;
    width: fit-content;
    z-index: 1;
    position: absolute;
    margin: 0;
    left: 20px;
    bottom: 40px;
    border-radius: 15px;

    @include break-md-up {
        padding: 36px 38px;
        border-radius: 30px;
        left: $heroSliderPaddingMd;
    }
    @include break-xl-up {
        left: $heroSliderPaddingXl;
        padding: 60px 63px;
        bottom: $heroSliderPaddingXl;
        border-radius: 50px;
    }
    img {
        width: 121px;
        height: auto;
        @include break-xs-up {
            width: 200px;
        }
        @include break-md-up {
            width: 250px;
        }
        @include break-xl-up {
            width: 420px;
        }
    }
}
.hero-slider-food {
    position: absolute;
    right: 0;
    z-index: 1;
    bottom: -81px;
    overflow: hidden;
    width: 209px;
    z-index: 2;

    @include break-xs-up {
        width: 300px;
        bottom: -116px;
    }
    @include break-md-up {
        width: 520px;
        right: $heroSliderPaddingMd;
        bottom: -156px;
    }
    @include break-xl-up {
        right: $heroSliderPaddingXl - 23px;
        width: 808px;
        bottom: -242px;
    }

    .food-icons-wrapper {
        width: 129%;
        position: relative;
        @include break-md-up {
            width: 100%;
        }
    }
    .food-icons {
        position: relative;
        padding-top: 57.7%;
    
        img {
            position: absolute;
            height: auto;
    
            &:nth-child(1) {
                width: 27.59%;
                top: 0;
                right: 42.32%;
            }
            &:nth-child(2) {
                width: 19.92%;
                top: 47.9%;
                right: 52.6%;
            }
            &:nth-child(3) {
                width: 22.4%;
                right: 0;
                top: 29%;
            }
            &:nth-child(4) {
                width: 12.5%;
                top: 26.2%;
                right: 69.91%;
            }
            &:nth-child(5) {
                width: 19.92%;
                right: 22.4%;
                top: 13.4%;
            }
            &:nth-child(6) {
                width: 30.07%;
                top: 47.9%;
                right: 22.5%;
            }
            &:nth-child(7) {
                width: 27.59%;
                top: 47.9%;
                right: 72.5%;
            }
            &:nth-child(8) {
                width: 19.92%;
                top: 47.7%;
                right: 2.6%;
            }
        }
    }
}
.hero-slide {
    // height: 555px;
    position: relative;
    // padding: $heroSliderPadding;
    padding-top: 165%;

    @include break-sm-up {
        padding-top: 51%;
    }
    @include break-md-up {
        // height: 583px;
        // padding: $heroSliderPaddingMd;
    }
    @include break-xl-up {
        // height: 900px;
        // padding: 110px $heroSliderPaddingXl $heroSliderPaddingXl $heroSliderPaddingXl;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 58%;
        background: linear-gradient(180deg, #054678 0%, rgba(217, 217, 217, 0.00) 100%);
        mix-blend-mode: multiply;
        @include break-md-up {
            height: 49%;
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 58%;
        background: linear-gradient(0deg, #054678 0%, rgba(217, 217, 217, 0.00) 100%);
        mix-blend-mode: multiply;
        @include break-md-up {
            height: 49%;
        }
    }
    &__img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
        object-position: 50% 0%;
        @include break-sm-up {
            object-position: 100% 0%;
        }
    }
    &__details {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        color: $color-white;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        padding-left: $heroSliderPadding;
        padding-right: $heroSliderPadding;
        padding-top: $heroSliderPadding;
        @include break-sm-up {
            padding-right: 0;
        }
        @include break-md-up {
            padding-left: $heroSliderPaddingMd;
            padding-top: $heroSliderPaddingMd;
        }
        @include break-xl-up {
            gap: 35px;
            padding-left: $heroSliderPaddingXl;
            padding-top: 110px;
        }
    }
    &__text {
        @include fontWeight600;
        font-size: 20px;
        max-height: 10em;
        overflow: hidden;
        // max-width: 500px;
        @include break-sm-up {
            max-width: 44%;
        }
        @include break-md-up {
            font-size: 30px;
        }
        @include break-xl-up {
            font-size: 40px;
            // max-width: 620px;
        }
    }
    &__button {
        @include button;
        font-size: 12px;
        border: 2px solid $color-white;
        padding: 0 2.2em;
        @include transition-regular;
        &:hover {
            background-color: $color-blue;
            border-color: $color-blue;
        }
        @include break-md-up {
            // font-size: 12px;
            padding: 0 3.1em;
        }
        @include break-xl-up {
            font-size: 16px;
        }
    }
}
.slider-arrow {
    @include slider-button(47px, 83px, 113px);

    @include break-xs-up {
        .awards-slider-nav & {
            border-radius: 0 47px*0.5 47px*0.5 0;
        }
    }
    @include break-md-up {
        .awards-slider-nav & {
            border-radius: 0 83px*0.5 83px*0.5 0;
        }
    }
    @include break-xl-up {
        .awards-slider-nav & {
            border-radius: 0 113px*0.5 113px*0.5 0;
        }
    }
    .w-use-stroke {
        width: 12px;
        height: auto;
        @include break-md-up {
            width: 18px;
        }
        @include break-xl-up {
            width: 25px;
        }
        use {
            @include transition-regular;
            stroke-width: 2px;
        }
    }

    .awards-slider-nav &--next {
        svg {
            @include break-xs-up {
                left: -10%;
            }
        }
    }
}
.slider-nav {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 7px;

    .swiper-pagination-bullet {
        $size: 6px;
        $sizeMd: 10px;
        $sizeXl: 12px;

        width: $size;
        height: $size;
        @include break-md-up {
            width: $sizeMd;
            height: $sizeMd;
        }
        @include break-xl-up {
            width: $sizeXl;
            height: $sizeXl;
        }
        vertical-align: middle;
        background: none;
        border: 2px solid $color-orange;
        margin: 0 4px;

        &.swiper-pagination-bullet-active {
            background: $color-orange;
            width: $size*1.33;
            height: $size*1.33;
            @include break-md-up {
                width: $sizeMd*1.33;
                height: $sizeMd*1.33;
            }
            @include break-xl-up {
                width: $sizeXl*1.33;
                height: $sizeXl*1.33;
            }
        }
    }
    
    &--blue .slider-arrow {
        background-color: $color-blue;
    }
    &--blue .swiper-pagination-bullet {
        border-color: $color-blue;
    }
    &--blue .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: $color-blue;
    }
}
.hero-slider-nav {
    top: 50%;
    right: 0;
}
.awards-slider-nav {
    top: 25px;
    right: 0;

    @include break-xs-up {
        right: -3px;
        top: 50%;
        transform: translateY(-50%);
    }
    @include break-md-up {
        right: 15px;
    }
    @include break-xl-up {
        right: 0px;
    }
}

.article-gallery {
    margin-bottom: 20px;

    @include break-lg-up {
        font-size: 18px;
        margin-bottom: 34px;
    }
    @include break-xl-up {
        font-size: 24px;
        margin-bottom: 68px;
    }
    &--no-thumbs {
        margin-bottom: 10px;
        @include break-sm-up {
            margin-bottom: 30px;
        }
        @include break-lg-up {
            margin-bottom: 50px;
        }
    }
    &__slider-main {
        position: relative;
    }
    &__fullscreen-button {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        @include break-lg-up {
            top: 20px;
            right: 20px;

            img {
                height: auto;
                width: 34px;
            }
        }
        @include break-xl-up {
            top: 37px;
            right: 37px;

            img {
                width: 50px;
            }
        }
    }
    &__slider-thumbs {
        padding: 0px 14px;
        margin-top: 8px;
        position: relative;

        @include break-md-up {
            padding: 0 34px;
            margin-top: 20px;
        }
    }
}
.article-slider {
    border-radius: 20px;

    @include break-xl-up {
        border-radius: 50px;
    }
    &__arrow {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);

        &--prev {
            @include slider-button(26px, 60px, 90px, true);
            left: 0;
            svg {
                transform: scaleX(-1)
            }
        }
        &--next {
            @include slider-button(26px, 60px, 90px);
            right: 0;
        }

        svg {
            @include break-md-up {
                height: auto;
                width: 12px;
            }
            @include break-xl-up {
                width: 18px;
            }
            use {
                stroke-width: 3px;
            }
        }
    }
}
.article-gallery-slide {
    position: relative;
    padding-top: 55%;

    &__anchor {
        display: block;
        @include abs100;
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.article-gallery-slide {
    display: block;
    position: relative;
    padding-top: $imageContainerAspectRatio;
    
    &__image {
        @include abs100;
        display: block;
        object-fit: cover;
    }
}


.article-thumbs-slider {
    &__arrow {
        @include abs-valigned;
        color: $color-orange;
        z-index: 1;

        &--prev {
            left: 0;
            svg {
                transform: scaleX(-1);
            }
        }
        &--next {
            right: 0;
        }

        svg {
            @include break-md-up {
                height: auto;
                width: 21px;
            }
            use {
                stroke-width: 2px;
            }
        }
    }
}
.article-gallery-thumbs-slide {
    position: relative;
    padding-top: 12.22%;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;

    &.swiper-slide-thumb-active {
        border-color: $color-orange;
    }
    @include break-md-up {
        border-radius: 10px;
    }
    @include break-xl-up {
        border-radius: 15px;
    }

    img {
        @include abs100;
        object-fit: cover;
    }
}

.content-slider-gallery-wrapper {
    position: relative;
}
.content-slider-gallery {
    @include border-radius15-30-50;
    overflow: hidden;

    &__slide {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__nav {
        top: 50%;
        transform: translateY(-50%);
        right: -23px;
        @include break-md-up {
            right: -42px;
        }
        @include break-xl-up {
            right: -56px;
        }

        .slider-arrow {
            border-radius: 0 47px*0.5 47px*0.5 0;
            @include break-md-up {
                border-radius: 0 83px*0.5 83px*0.5 0;
            }
            @include break-xl-up {
                border-radius: 0 113px*0.5 113px*0.5 0;
            }
            &--next {
                svg {
                    left: -10%;
                }
            }
        }
    }
}

.widget-slider-wrapper {
    overflow: hidden;
    @include border-radius15-35-50;

}
.widget-slider {
    background-color: $color-blue;

    &__nav {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);

        &--prev {
            @include slider-button(40px, 60px, 60px, true);
            left: 0;
            svg {
                transform: scaleX(-1)
            }
        }
        &--next {
            @include slider-button(40px, 60px, 60px);
            right: 0;
        }
        svg {
            width: 10px;
            height: auto;
            @include break-md-up {
                width: 12px;
            }
            @include break-xl-up {
                width: 18px;
            }
            use {
                stroke-width: 3px;
            }
        }
    }

    &--wide {
        @include break-sm-up {
            background-color: transparent;
        }
        @include break-sm-down {
            margin-top: 20px;
            @include border-radius15-35-50;
        }
    }
    &--wide &__nav {
        @include break-sm-up {
            top: calc(50% + $helpHeroSlideMargin/2);
        }
    }
    &--wide &__nav--prev {
        @include slider-button(40px, 60px, 90px, true);
    }
    &--wide &__nav--next {
        @include slider-button(40px, 60px, 90px);
    }

    &__count {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 1;
        @include fontWeight400;
        color: $color-white;
        @include text-smaller;
        @include break-sm-up {
            bottom: 20px;
        }
    }
    &--wide &__count {
        @include break-lg-up {
            bottom: 30px;
        }
    }
}
.widget-slide {
    color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0;
    height: auto;

    @include break-lg-up {
        padding: 25px 40px 0;
    }
    @include break-xl-up {
        padding: 40px 60px 0;
    }
    
    &__content {
        flex-grow: 1;
        padding-bottom: 40px;
    }
    &__title {
        @include fontWeight600;
        @include text-bigger;
        margin-bottom: 1em;
    }
    &__description {
        @include fontWeight400;
        @include text-smaller;
        margin-bottom: 1.3em;
    }
    &__text-bottom {
        @include fontWeight600;
        @include text-smaller;
    }
    &__image-wrapper {
        img {
            width: 100%;
            max-height: 200px;
            display: block;
            margin: auto;
            object-fit: contain;
            object-position: center 100%;
            @include break-sm-up {
                max-height: 330px;
            }
        }
    }
    .widget-slider--wide & {
        gap: 20px;

        padding: 25px 35px 0;
        
        @include break-sm-up {
            padding: 0 45px 0 0;
            margin-top: $helpHeroSlideMargin;
            flex-direction: row;
            @include border-radius15-35-50;
            background-color: $color-blue;
        }
    }
    .widget-slider--wide &__content {
        @include break-sm-up {
            padding: 25px 0 25px 40px;
        }
        @include break-md-up {
            padding: 35px 0 35px 55px;
        }
        @include break-lg-up {
            padding: 50px 0 50px 50px;
        }
        @include break-xl-up {
            padding: 75px 0 75px 110px;
        }
    }
    .widget-slider--wide &__description {
        @include text-bigger;
        margin-bottom: 1.7em;
    }
    .widget-slider--wide &__text-bottom {
        @include text-bigger;
    }
    .widget-slider--wide &__image-wrapper {
        @include break-sm-up {        
            flex: 0 0 39%;
            max-width: 39%;
            position: relative;
            img {
                max-height: calc(100% + $helpHeroSlideMargin);
                position: absolute;
                max-width: 100%;
                bottom: 0;
                width: auto;
                left: 0;
                right: 0;
            }                
        }
    }
}