.help-types {
    max-width: 500px;
    margin-top: 36px;
    position: relative;
    z-index: 1;
    @include break-sm-up {
        display: flex;
        align-items: center;
        max-width: 730px;
        gap: 50px;
    }
    @include break-md-up {
        // max-width: 1035px;
        max-width: none;
        padding-left: 55px;
    }
    @include break-lg-up {
        // max-width: 1175px;
    }
    @include break-xl-up {
        max-width: none;
        gap: 80px;
        padding-right: 70px;
    }
    &--inner-page {
        padding: 0;
        @include section-margin-y;
        @include break-xs-down {
            margin-left: -20px;
            margin-right: -20px;
        }
    }
}
.tabs {
    $paddingX: 20px;
    $radius: 15px;
    $radius--md: 20px;
    $radius--xl: 30px;

    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    background-color: $color-white;

    .help-types & {
        flex-grow: 1;
    }
    @include break-md-up {
        margin-bottom: 0;
    }
    &__controls {
        display: flex;
    }
    &__button {
        @include fontWeight600;
        font-size: 12px;
        flex-grow: 1;
        padding: 0.4em $paddingX - 1px;
        border-radius: $radius $radius 0 0;
        border-bottom: 1px solid transparent;
        color: $color-white;
        &--orange {
            background-color: $color-orange;
        }
        &--purple {
            background-color: $color-purple;
        }
        &--green {
            background-color: $color-green;
        }
        &.is-active {
            border-color: $color-white-20;
        }
        @include break-md-up {
            font-size: 14px;
            padding-top: .9em;
            padding-bottom: .9em;
            border-radius: $radius--md $radius--md 0 0;
        }
        @include break-md-up {
            font-size: 18px;
        }
        @include break-xl-up {
            font-size: 24px;
            border-radius: $radius--xl $radius--xl 0 0;
            padding-top: 1em;
            padding-bottom: 1em;
        }
    }
    &__content {
        position: relative;
        padding: $paddingX;
        border-radius: 0 0 $radius $radius;
        overflow: hidden;
        @include break-xs-up {
            min-height: 220px;
        }
        @include break-sm-up {
            min-height: 280px;
        }
        @include break-md-up {
            border-radius: 0 0 $radius--md $radius--md;
            padding: 40px;
        }
        @include break-lg-up {
            min-height: 390px;
        }
        @include break-xl-up {
            padding: 60px;
        }
    }

    .tabs-background {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        &.is-active {
            display: block;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            animation: fadeIn 1s ease-out both;
        }
        &--orange {
            &::before {
                background-color: $color-orange;
            }
            &::after {
                // background: radial-gradient(165.66% 52.28% at 101.79% 33.00%, rgba(231, 101, 12, 0.00) 47.72%, $color-orange 100%);
                background-image: url(../images/bg-orange-gradient.png);
                @include break-md-up {
                    background-image: url(../images/bg-orange-gradient--md.png);
                }
            }
        }
        &--purple {
            &::before {
                background-color: $color-purple;
            }
            &::after {
                // background: radial-gradient(165.66% 52.28% at 101.79% 33.00%, rgba(127, 85, 170, 0.00) 47.72%, $color-purple 100%);
                background-image: url(../images/bg-purple-gradient.png);
                @include break-md-up {
                    background-image: url(../images/bg-purple-gradient--md.png);
                }
            }
        }
        &--green {
            &::before {
                background-color: $color-green;
            }
            &::after {
                // background: radial-gradient(165.66% 52.28% at 101.79% 33.00%, rgba(2, 117, 0, 0.00) 47.72%, $color-green 100%);
                background-image: url(../images/bg-green-gradient.png);
                @include break-md-up {
                    background-image: url(../images/bg-green-gradient--md.png);
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            // width: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            animation: fadeIn 0.5s ease-out both;

            /* @include break-md-up {
                width: 65%;
            } */
        }
    }
    .tabs__list {
        position: relative;
        .tab {
            animation: fadeIn 1s ease-out both;

            color: $color-white;
            display: none;
            &.is-active {
                display: block;
            }
            &__text {
                font-size: 12px;
                width: 55%;
                margin-bottom: 15px;

                @include break-sm-up {
                    font-size: 14px;
                }
                @include break-lg-up {
                    font-size: 20px;
                    margin-bottom: 25px;
                }
                @include break-xl-up {
                    font-size: 30px;
                    margin-bottom: 40px;
                }
            }
            &__buttons {
                
                display: flex;
                gap: 7px;

                @include break-sm-up {
                    width: 65%;
                }
                @include break-md-up {
                    gap: 15px;
                }
            }
            &__bottom-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @include fontWeight400;
                margin-top: 20px;
            }
            &__bottom-text-small {
                font-size: 20px;
                @include break-md-up {
                    font-size: 40px;
                }
                @include break-xl-up {
                    font-size: 60px;
                }
            }
            &__bottom-text-big {
                font-size: 40px;
                line-height: 1;
                @include break-md-up {
                    font-size: 54px;
                }
                @include break-lg-up {
                    font-size: 84px;
                }
                @include break-xl-up {
                    font-size: 128px;
                }
            }
        }
    }
}