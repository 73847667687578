.targeted-help {
    margin-top: 90px;

    h2 {
        margin-bottom: 20px;
        @include break-lg-up {
            margin-bottom: 40px;
        }
    }
    .nice-select {
        font-size: 14px;
        @include break-xs-up {
            font-size: 16px;
        }
    }
}
.targeted-help-controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px 60px;
}

.donation-tab-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    @include break-xs-up {
        column-gap: 40px;
    }
}
.donation-tab-button {
    @include anchor();
    @include fontWeight600;
    font-size: 14px;
    @include break-xs-up {
        font-size: 16px;
    }

    &.is-active {
        border-color: transparent;
        color: $color-blue;
    }
}
.targeted-help-cards {
    display: grid;
    gap: 25px 12px;
    margin-top: 40px;
    @include break-sm-up {
        grid-template-columns: 1fr 1fr;
    }
    @include break-md-up {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include break-lg-up {
        gap: 40px 70px;
    }
}
.targeted-help-card {
    display: flex;
    flex-direction: column;
    border: 2px solid $color-blue;
    border-radius: 15px;
    overflow: hidden;
    color: $color-blue;
    @include fontWeight400;
    @include break-lg-up {
        border-radius: 30px;
    }
    &__image-wrapper {
        position: relative;
        padding-top: 66%;

        img {
            @include abs100;
            object-fit: cover;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 14px 20px 24px 20px;
        @include break-lg-up {
            padding: 20px 40px 40px 40px;
        }
    }
    &__info {
        flex-grow: 1;
    }
    &__title {
        @include text-regular;
        @include fontWeight600;
        font-size: 16px;
        margin-bottom: 6px;
    }
    &__city {
        font-size: 12px;
        margin-bottom: 6px;
        @include break-lg-up {
            font-size: 14px;
            margin-bottom: 17px;
        }
    }
    &__description {
        font-size: 13px;
        margin-bottom: 5px;
        @include break-lg-up {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
    &__read-button {
        @include anchor();
        font-size: 12px;
        @include break-lg-up {
            font-size: 14px;
        }
    }
    &__bottom {
        padding-top: 12px;
        @include break-lg-up {
            padding-top: 20px;
        }
    }
    .button {
        @include text-small;
        @include button-height;
        border-radius: 7px;
        padding: 0 3em;
        @include break-xl-up {
            border-radius: 16px;
        }
        background-color: $color-blue;
        color: $color-white;
        &:hover {
            background-color: #fff;
            color: #054678;
        }
    }
    &.is-closed .button {
        margin-left: auto;
        background-color: $color-green;
        pointer-events: none;
    }
}
.donation-progress {
    $progressbarHeight: 25px;
    $progressbarWidth: 44px;

    margin-bottom: 16px;
    @include break-lg-up {
        margin-bottom: 20px;
    }
    &__numbers {
        display: flex;
        flex-wrap: wrap;
        gap: 0 15px;
        margin-bottom: 12px;
    }
    &__number {
        &:last-child {
            margin-left: auto;
            text-align: right;
        }
    }
    &__caption {
        opacity: .4;
        font-size: 12px;
        margin-bottom: 2px;
        @include break-lg-up {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
    &__amount {
        font-size: 16px;
        @include break-lg-up {
            font-size: 19px;
        }
    }
    &__bar {
        position: relative;
        height: $progressbarHeight;
        display: flex;
        align-items: center;
        color: $color-orange;
        margin: auto;
        width: calc(100% - $progressbarWidth);

        .is-closed & {
            color: $color-green;
        }
    }
    &__bar-track {
        background-color: currentColor;
        height: 6px;
        border-radius: 14px;
        width: 100%;
    }
    &__bar-thumb {
        height: $progressbarHeight;
        width: $progressbarWidth;
        position: absolute;
        left: 0;
        top: 0;
        background-color: currentColor;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
        transform: translateX(-50%);
        span {
            color: $color-white;
            font-size: 14px;
            @include fontWeightNormal;
        }
    }
}

.load-more-button-wrapper {
    margin-top: 30px;
    @include break-lg-up {
        margin-top: 70px;
    }
    .refresh-button {
        margin: auto;
    }
}
.no-donation-items-text {
    color: $color-blue;
    @include text-regular;
    @include fontWeight600;
    text-align: center;
    margin-top: 60px;
    @include break-lg-up {
        margin-top: 80px;
    }
}