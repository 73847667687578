.search {
    $imageWith: 88px;
    $imageWith--md: 304px;

    .search-title {
        @extend .h4;
        font-weight: 400;
        font-family: 'Museo Sans Cyrl 500';

        @include break-md-up {
            margin-bottom: 20px;
        }
        @include break-lg-up {
            margin-bottom: 38px;
        }
    }
    .search-page-form {
        margin-bottom: 15px;
        @include break-md-up {
            width: calc(100% - $imageWith--md);
        }
        @include break-lg-up {
            margin-bottom: 20px;
        }
        @include break-lg-up {
            margin-bottom: 34px;
        }
        &__input {
            height: 28px;
            padding: 0 16px;
            border: 1px solid $color-blue-40;
            border-radius: 7px;
            width: 100%;
            color: $color-blue;
            font-size: 12px;
            @include break-md-up {
                border-radius: 10px;
                height: 52px;
                padding: 0 22px;
                font-size: 16px;
            }
            &::placeholder {
                color: $color-blue;
            }
        }
    }
    .search-categories {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        @include fontWeight600;
        @include break-lg-up {
            gap: 30px;
            margin-bottom: 20px;
        }

        .search-categorie {
            font-size: 12px;
            @include anchor($color-orange, $color-blue, $color-blue-20);
            @include break-sm-up {
                font-size: 14px;
            }
            @include break-lg-up {
                font-size: 24px;
            }

            &__count {
                opacity: .4;
                @include fontWeight400;
            }
        }
        
    }
    .search-page-results-list {
        margin-bottom: 20px;

        @include break-lg-up {
            margin-bottom: 116px;
        }
    }
    .search-results-item {
        // display: flex;
        // flex-direction: column;
        padding: 20px 0;

        &:not(:last-child) {
            border-bottom: 1px solid $color-orange;
            @include break-sm-up {
                border-width: 2px;
            }
        }
        @include break-lg-up {
            padding: 37px 0;
        }

        &__content {
            display: flex;
        }
        &__image-wrapper {
            margin-bottom: 15px;
            flex-shrink: 0;
            padding-left: 8px;
            @include break-sm-up {
                padding-left: 20px;
            }
            img {
                width: $imageWith;
                display: block;
                border-radius: 7px;
                @include break-sm-up {
                    width: $imageWith--md;
                }
                @include break-lg-up {
                    border-radius: 15px;
                }
            }
        }
        &__link-wrapper {
            margin-bottom: 12px;
            @include break-xl-up {
                margin-bottom: 25px;
            }
            a {
                @include anchor($color-orange, $color-blue, $color-blue-20);
                @extend .h2;
            }
        }
        &__snippet {
            margin-bottom: 8px;
            font-size: 8px;
            @include break-sm-up {
                font-size: 14px;
                margin-bottom: 15px;
            }
            @include break-lg-up {
                font-size: 16px;
            }
            @include break-xl-up {
                font-size: 19px;
            }
            color: $color-blue;
        }
        &__images {
            margin-top: 10px;
            display: flex;
            gap: 5px;
            flex-wrap: wrap;

            @include break-md-up {
                margin-top: 20px;
                gap: 12px;
            }
            img {
                /* width: 90px;
                height: 60px; */
                
                width: 55px;
                height: 37px;
                object-fit: cover;
                border-radius: 5px;
                @include break-md-up {
                    width: 110px;
                    height: 70px;
                }
                @include break-lg-up {
                    border-radius: 15px;
                    width: 169px;
                    height: 114px;
                }
            }
        }
    }
}