body {
    // line-height: 1;
    font-size: 62.5%;
    font-family: 'Museo Sans Cyrl', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: $color-black;
    min-width: 320px;
  }
.container {
    @include mx-auto;
	width: 100%;
    max-width: $container-max-width;
    padding-left: $container-padding;
    padding-right: $container-padding;
    @include break-md-up {
      padding-left: $container-padding-sm;
      padding-right: $container-padding-sm;
    }

    .inner-page .main & {
        padding-left: $container-padding-bigger;
        padding-right: $container-padding-bigger;
        @include break-md-up {
          padding-left: $container-padding-bigger-sm;
          padding-right: $container-padding-bigger-sm;
        }
    }
}
// .main {
//     h1, .h1, h2, .h2, h3, .h3, p, ul, ol, div {
//         color: $color-base;
//     }
// }
h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    color: $color-blue;
}
h1, .h1 {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Museo Sans Cyrl', Helvetica, Arial, sans-serif;
    &--bigger {
        @include break-sm-down {
            font-size: 20px;
        }
    }
    @include break-sm-up {
        font-size: 20px;
    }
    @include break-lg-up {
        font-size: 30px;
    }
    @include break-xl-up {
        font-size: 40px;
    }
}
h2, .h2 {
    @extend .h1;
    @include fontWeight600;
}
.section-title {
    @extend .h1;
}
h3, .h3 {
    font-family: 'Museo Sans Cyrl', Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 11px;
    @include break-sm-up {
        font-size: 16px;
    }
    @include break-lg-up {
        font-size: 20px;
        margin-bottom: 22px;
    }
    @include break-xl-up {
        font-size: 30px;
    }
}
h4, .h4 {
    /* @include break-xs-down {
        font-size: 14px;
    } */
    @extend .h3;
    @include fontWeight600;
}
h5, .h5 {
    @include fontWeight600;
    font-size: 14px;
    margin-bottom: 15px;
    
    @include break-sm-up {
        font-size: 16px;
    }
    @include break-lg-up {
        font-size: 20px;
    }
    @include break-xl-up {
        // font-size: 30px;
        font-size: 25px;
        margin-bottom: 40px;
    }
}
h6, .h6 {
    font-weight: 400;
    margin-top: 10px;
    @include text-smaller;
    @include break-md-up {
        margin-top: 40px;
    }
    @include break-xl-up {
        font-size: 16px;
    }
}
.regular-text {
    @include text-regular;
    color: $color-blue;
    @include fontWeight400;
    h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        @include regular-mb;
    }
    p {
        @include regular-mb;
        &:last-child {
            margin-bottom: 0;
        }
    }
    big {
        @include text-bigger;
    }
    ol, ul {
        margin-bottom: 20px;
        margin-left: 10px;
        @include break-md-up {
            margin-bottom: 40px;
        }
        @include break-xl-up {
            margin-bottom: 60px;
        }
        li {
            margin-bottom: 1em;
        }
    }
    li ul, li ol {
        margin-bottom: 0;
    }
    ul {
        li {
            position: relative;
            padding-left: 1.1em;
            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                content: '\2022';
                position: absolute;
                top: 0;
                left: 0;
            }
            
            ul {
                margin-top: 1em;
            }
        }
    }
    a {
        @include anchor($color-orange, $color-blue, $color-blue-30);
    }
}
.mb-40 {
    @include mb-40;
}
.mb-70 {
    @include mb-70;
}
p:last-child {
    margin-bottom: 0;
}
a {
    color: inherit;
}
.tac {
    text-align: center;
}
.clear {
    clear: both;
}
.font-normal {
    @include fontWeight400;
    font-weight: normal;
}
.highlighted {
    color: $color-white;
    background-color: $color-blue;
}
.is-disabled {
    opacity: .3;
    pointer-events: none;
}
img {
    max-width: 100%;
}
.bold {
    font-weight: bold;
}
.semibold {
    @include fontWeight600;
}
.no-overflow {
    overflow: hidden;
}
.underlined {
    border-bottom: 1px solid $color-blue-30;
}
ol, ul {
	list-style-type: none;
}
input, textarea {
	outline: none;
	border: none;
}
.w-use-fill {
    use {
        @include transition-regular;
        fill: currentColor
    }
}
.w-use-stroke {
    use {
        @include transition-regular;
        fill: none;
        stroke: currentColor;
        stroke-miterlimit: 10;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}
.is-orange {
    color: $color-orange;
}
.is-blue {
    color: $color-blue;
}
.is-yellow {
    color: $color-yellow;
}
.is-yellow-bg {
    background-color: $color-yellow;
}
.is-orange-bg {
    background-color: $color-orange;
}
.float-right {
    @include floatRight
}
.float-left {
    @include floatLeft
}

.is-hidden {
    display: none !important;
}
.hidden-md {
    @include break-md-down {
        display: none !important;
    }
}
.hidden-sm-down {
    @include break-sm-down {
        display: none !important;
    }
}
.hidden-sm-up {
    @include break-sm-up {
        display: none !important;
    }
}
.fg-1 {
    flex-grow: 1;
}
.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.main {
    flex-grow: 1;
}
.page-title {
    @extend .h1;
    margin-bottom: 14px;
    @include break-lg-up {
        margin-bottom: 20px;
    }
    @include break-xl-up {
        margin-bottom: 30px;
    }
}
.link-social {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $color-white;
    @include transition-regular;
    img {
        @include transition-regular
    }
    &:hover {
        // color: $color-orange;
        img {
            opacity: .5;
        }
    }

    &__icon {
        width: 36px;
        height: 36px;

        .footer & {
            @include break-lg-up {
                width: 51px;
                height: 51px;
            }
        }
    }
}

.animable-mob {
    @include break-md-down {
        opacity: 0;
        transform: translateY(10px);
        @include transition-regular;
        transition-duration: $transition-duration500;
        transition-delay: 0ms;
        .is-active & {
            opacity: 1;
            transform: translateY(0px);
            transition-delay: $transition-duration;
        }
    }    
}
.food-icons {
    pointer-events: none;
}
.introduction-text {
    color: $color-blue;
    width: 81%;
    max-width: 260px;

    @include break-sm-up {
        max-width: none;
        width: 53%;
    }
    @include break-md-up {
    }
    p {
        font-size: 14px;
        @include fontWeight400;
        margin-top: 1.3em;
        @include break-md-up {
            font-size: 16px;
        }
        @include break-lg-up {
            font-size: 20px;
        }
        @include break-xl-up {
            font-size: 30px;
        }
    }
}
.scheme-and-card {
    display: grid;
    grid-template-columns: 1fr minmax(178px, 1fr);
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 500px;

    @include break-sm-up {
        max-width: none;
        margin-top: -50px;
        grid-template-columns: 1fr 220px;
        max-width: 750px;
    }
    @include break-md-up {
        // grid-template-columns: 1fr 290px;
        display: block;
        margin-top: 50px;
    }
    @include break-xl-up {
        max-width: 900px;
    }
}
.main-page-about {
    position: relative;
    margin-bottom: 30px;
    @include break-md-up {
        margin-bottom: 30px;
    }
    @include break-xl-up {
        margin-bottom: 80px;
    }
    &__inner {
        padding-left: 20px;
        position: relative;
        @include break-md-up {
            position: relative;
            padding: 55px;
        }
    }
}
.scheme {
    @include break-sm-up {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 20px;
    }
    @include break-md-up {
        width: 75%;
    }
    @include break-xl-up {
        width: 100%;
    }
}
.scheme-item {
    $gap: 14px;

    margin-bottom: $gap;

    &__icon-wrapper {
        margin-bottom: $gap*0.5;

        @include break-sm-up {
            height: 50px;
            display: flex;
            align-items: flex-end;
            position: relative;

            @include break-md-up {
                margin-bottom: $gap;
            }
            @include break-xl-up {
                height: 80px;

                img {
                    height: 90%;
                    width: auto;
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 13px;
                height: 10px;
                background: url(../images/arrow.svg) no-repeat 50% / contain;
                top: 50%;
                transform: translateY(-50%);
                right: 13px;
                @include break-xl-up {
                    width: 19px;
                    height: 16px;
                    right: 35px;
                }
            }
        }
    }
    &__icon {

    }
    &__text {
        @include fontWeight600;
        color: $color-blue;
        font-size: 12px;
        @include break-md-up {
            font-size: 14px;
        }
        @include break-xl-up {
            font-size: 19px;
        }
    }
    &:not(:last-child)::after {
        content: '';
        display: block;
        background: url(../images/arrow.svg) no-repeat center / contain;
        width: 10px;
        height: 8px;
        transform: rotate(90deg);
        margin-top: $gap;
        @include break-sm-up {
            display: none;
        }
    }
    &:last-child &__icon-wrapper::after {
        display: none;
    }
}
.main-page-events-wrapper {
    position: relative;
    z-index: 1;
    @include break-md-up {
        width: 53%;
    }
    @include break-xl-up {
        width: 60%;
    }
}
.technologies {
    background-color: $color-blue;
    padding: 20px 20px 30px;
    border-radius: 15px;
    color: $color-white;
    position: relative;
    z-index: 1;
    margin-top: 15px;

    @include break-xs-up {
        padding-top: 40px;
    }
    @include break-md-up {
        margin-top: 90px;
        padding: 53px 75px 75px;
        border-radius: 35px;
    }
    @include break-lg-up {
        margin-top: 140px;
        padding: 50px 75px 70px;
    }
    @include break-xl-up {
        margin-top: 216px;
        padding: 83px 120px 111px;
        border-radius: 50px;
    }

    &__title {
        font-size: 16px;
        font-weight: bold;
        width: 74%;
        margin-bottom: 15px;

        @include break-sm-up {
            width: 54%;
        }
        @include break-md-up {
            width: 63%;
            font-size: 40px;
            margin-bottom: 30px;
        }
        @include break-xl-up {
            font-size: 64px;
            margin-bottom: 60px;
        }
        a {
            @include anchor($color-white, $color-orange, $color-white-20)
        }
    }
    &__text {
        font-size: 12px;
        width: 71%;

        @include break-sm-up {
            width: 54%;
        }
        @include break-md-up {
            width: 75%;
            font-size: 20px;
        }
        @include break-xl-up {
            font-size: 30px;
        }
    }
    &__cards {
        display: grid;
        gap: 14px 20px;
        margin-top: 20px;
        @include break-xs-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include break-md-up {
            margin-top: 35px;
        }
        @include break-lg-up {
            margin-top: 55px;
            gap: 34px 58px;
        }
        @include break-xl-up {
            margin-top: 80px;
        }
    }
    .technologies-card {
        position: relative;
        background-color: $color-white;
        padding: 15px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        box-shadow: 0px 7px 40px 0px rgba(12, 71, 119, 0.10);

        @include break-xs-up {
            display: grid;
            grid-template-columns: 2fr 1fr;
            // padding: 20px;
        }
        @include break-md-up {
            border-radius: 30px;
            padding: 30px 40px;
        }
        @include break-lg-up {
            gap: 15px;
        }
        @include break-xl-up {
            border-radius: 50px;
            padding: 30px 60px 30px;
            gap: 25px;
        }
        &__image-wrapper {
            height: 77px;
            display: flex;
            align-items: flex-end;

            @include break-xs-up {
                grid-row: 1 / span 2;
                grid-column: 2 / span 1;
                align-self: center;
                height: 100%;
            }

            img {
                max-height: 100%;
                max-width: 100%;

                @include break-xs-up {
                    display: block;
                    margin: auto;
                    height: 65px;
                    margin-left: 10px;
                }
                @include break-md-up {
                    height: 95px;
                }
                @include break-lg-up {
                    height: 135px;
                }
                @include break-xl-up {
                    height: 220px;
                }
            }
        }
        &__name {
            font-size: 14px;
            @include break-xs-up {
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;
                align-self: end;
            }
            @include break-md-up {
                font-size: 30px;
            }
            @include break-xl-up {
                font-size: 40px;
            }
            a {
                @include anchor($color-orange, $color-blue, $color-blue-30);
                @include fontWeight600;
            }
        }
        &__description {
            color: $color-blue;
            @include text-normal;
            @include break-xs-up {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
            }
        }
    }
    .technologies-bubble {
        border: 1px solid $color-white-30;
        border-radius: 15px;
        padding: 10px 12px;
        display: flex;
        align-items: flex-end;
        position: relative;
        margin-top: 25px;
        
        @include break-md-up {
            border-radius: 20px;
            padding: 30px 38px;
        }        
        @include break-xl-up {
            border-radius: 30px;
            padding: 40px 65px;
            margin-top: 90px;
            align-items: center;
        }

        &::before, &::after {
            content: '';
            position: absolute;
        }
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 18px 18px 0;
            border-color: transparent $color-white-30 transparent transparent;
            top: 100%;
            right: 25px;
            @include break-xl-up {
                border-width: 0 38px 38px 0;
                right: 75px;
            }
        }
        &::after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 16px 16px 0;
            border-color: transparent $color-blue transparent transparent;
            top: 100%;
            right: 26px;
            @include break-xl-up {
                border-width: 0 36px 36px 0;
                right: 76px;
            }
        }
        &__text-big {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 8px;
            
            @include break-md-up {
                font-size: 24px;
                margin-bottom: 13px;
            }
            @include break-xl-up {
                font-size: 40px;
            }
        }
        &__text-small {
            font-size: 8px;
            width: 80%;
            @include break-xs-up {
                font-size: 10px;
            }
            @include break-md-up {
                font-size: 14px;
            }
            @include break-xl-up {
                font-size: 24px;
            }
        }
        &__button-wrapper {
            flex-shrink: 0;
            padding-bottom: 5px;

            @include break-lg-up {
                margin-right: 100px;
            }
            @include break-xl-up {
                margin-right: 40px;
            }

            button {
                @include break-md-up {
                    box-shadow: 0px 0px 20px 0px $color-glow-cyan;
                }
            }
        }
    }
}
.technologies-food-top {
    position: absolute;
    right: 0;
    z-index: 1;
    top: -77px;
    overflow: hidden;
    width: 128px;

    @include break-xs-up {
        width: 150px;
        top: -90px;
    }
    @include break-sm-up {
        right: auto;
        left: 500px;
    }
    @include break-md-up {
        width: 220px;
        top: -174px;
        left: auto;
        right: 11px;
    }
    @include break-lg-up {
        width: 300px;
        top: -238px;
        right: 8px;
    }
    @include break-xl-up {
        right: 31px;
        top: -400px;
        width: 505px;
    }
    
    .food-icons-wrapper {
        width: 125%;
        position: relative;
        @include break-md-up {
            width: 100%;
        }
    }
    .food-icons {
        position: relative;
        padding-top: 116.24%;
    
        img {
            position: absolute;
            height: auto;
    
            &:nth-child(1) {
                width: 48.12%;
                top: 41%;
                left: 20.2%;
            }
            &:nth-child(2) {
                left: 48.1%;
                top: 13.8%;
                width: 31.69%;
            }
            &:nth-child(3) {
                top: 0;
                left: 0;
                width: 48.12%;
            }
            &:nth-child(4) {
                top: 41%;
                right: 0;
                width: 31.69%;
                display: none;
                @include break-md-up {
                    display: block;
                }
            }
            &:nth-child(5) {
                left: 17%;
                bottom: 0;
                width: 20%;
            }
        }
    }
}

.technologies-food-bottom {
    position: absolute;
    right: 9px;
    z-index: 1;
    bottom: -48px;
    width: 89px;

    @include break-md-up {
        width: 168px;
        bottom: -58px;
        right: 30px;
    }
    @include break-xl-up {
        width: 260px;
        bottom: -108px;
        right: 35px;
    }
    
    .food-icons-wrapper {
        position: relative;
    }
    .food-icons {
        position: relative;
        padding-top: 69%;
    
        img {
            position: absolute;
            height: auto;
    
            &:nth-child(1) {
                right: 37.94%;
                top: 10%;
                width: 62.07%;
            }
            &:nth-child(2) {
                width: 37.94%;
                top: 0;
                right: 0;
            }
        }
    }
}

.main-page-press {
    @include block-extra-padding;
    margin-bottom: 30px;
}
.main-page-news-title {
    @include block-title;
    margin-bottom: 20px;
    a {
        @include anchor($color-orange, $color-blue, $color-blue-20);
    }
    @include break-lg-up {
        margin-bottom: 40px;
    }
    @include break-xl-up {
        margin-bottom: 60px;
    }
}
.main-page-press__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: flex-start;
    @include break-xs-up {
        grid-template-columns: 2fr 1fr;
    }
    @include break-sm-up {
        gap: 30px;
    }
    @include break-lg-up {
        gap: 58px;
    }
    @include break-lg-up {
        gap: 90px;
    }
}
.main-page-news {
    display: flex;
    flex-direction: column;
    gap: 11px;
    @include break-sm-up {
        flex-direction: row;
        align-items: stretch;
    }
    @include break-md-up {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 26px 38px;
    }
    @include break-xl-up {
        gap: 26px 38px;
        gap: 40px 60px;
    }
}
.news-item-card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 5px 20px 0px $color-light-blue10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include fontWeight400;

    @include break-sm-up {
        .main-page-news & {
            @include fwidth6;
        }
    }
    @include break-md-up {
        .main-page-news & {
            @include fwidth12
        }
    }
    @include break-lg-up {
        border-radius: 20px;
    }
    @include break-xl-up {
        border-radius: 30px;
    }

    &__image-wrapper {
        position: relative;
        padding-top: 61%;
        width: 100%;

        @include break-lg-up {
            padding-top: 50%;
        }
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__meta {
        display: flex;
        gap: 10px;
        color: $color-blue;
        font-size: 8px;
        margin-bottom: 6px;
        @include break-xs-up {
            font-size: 10px;
        }
        @include break-lg-up {
            gap: 14px;
        }
        @include break-xl-up {
            font-size: 16px;
            margin-bottom: 16px;
        }
    }
    &__meta-date {
        opacity: .5;
        white-space: nowrap;
        flex: 0 0 75px;
        max-width: 75px;
        @include break-xs-up {
            flex: 0 0 95px;
            max-width: 95px;
        }
        @include break-xl-up {
            flex: 0 0 140px;
            max-width: 140px;
        }
    }
    &__meta-source {
        word-break: break-word;
    }

    &__content {
        padding: 6px 11px 13px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;

        .news-list &,
        .widget-news & {
            @include break-sm-down {
                padding: 13px 22px;
            }
        }

        @include break-lg-up {
            padding: 15px 20px;
        }
        @include break-xl-up {
            padding: 24px 36px;
        }
    }
    &__content-inner {
        flex-grow: 1;
    }
    &__top-line {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 14px;

        .article-card-icon-video {
            margin-left: 0;
        }
    }
    &__source {
        font-size: 10px;
        color: $color-blue;
        margin-bottom: 10px;
        .main-page-news & {
            @include break-xs-down {
                font-size: 7px;
            }
        }
        @include break-lg-up {
            font-size: 12px;
            margin-bottom: 20px;
        }
        @include break-xl-up {
            font-size: 16px;
        }
    }
    &__date {
        font-size: 6px;
        color: $color-light-blue50;
        flex-shrink: 0;

        display: flex;
        align-items: center;
        
        .news-list &,
        .widget-news & {
            // @include break-sm-down {
            @include break-xs-down {
                font-size: 8px;
            }
        }

        @include break-xs-up {
            font-size: 10px;
        }
        @include break-xl-up {
            font-size: 16px;
        }
    }
    &__title {
        font-size: 8px;
        margin-bottom: 20px;
        
        .news-list &,
        .widget-news & {
            @include break-sm-down {
                font-size: 14px;
            }
        }

        @include break-xs-up {
            font-size: 12px;
        }
        @include break-lg-up {
            font-size: 16px;
            margin-bottom: 30px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
        a {
            @include anchor($color-blue, $color-orange, $color-blue-30)
        }
    }

    .main-page-news &:nth-child(n+3) {
        display: none;
        @include break-md-up {
            display: flex;
        }
    }
    &__tags {
        font-size: 6px;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    
        .news-list &,
        .widget-news & {
            @include break-sm-down {
                font-size: 8px;
            }
        }
        @include break-xs-up {
            font-size: 10px;
            gap: 8px;
        }
        @include break-xl-up {
            font-size: 16px;
        }
    }
}
.article-tag {
    font-size: 1em;
    border-radius: 10px;
    border: 1px solid $color-light-blue40;
    // padding: .3em 1em;
    padding: .4em 1em;
    color: $color-orange;
    @include transition-regular;
    &:hover,
    &.is-active {
        background-color: $color-blue;
        color: $color-white;
    }
    .news-tags &,
    .team-tabs & {
        @include break-md-up {
            border-radius: 23px;
        }
    }
    .team-tabs & {
        padding-left: 1.4em;
        padding-right: 1.4em;
    }
}
.articles-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &__pagination {
        margin-left: auto;
    }
}

.more-tags {    
    font-size: 1em;
    @include anchor($color-orange, $color-blue, $color-light-blue40);
}
.sidebar-title {
    margin-bottom: 15px;
    @include block-title-smaller;
    @include break-lg-up {
        margin-bottom: 35px;
    }
    @include break-xl-up {
        margin-bottom: 55px;
    }
    a {
        @include anchor($color-orange, $color-blue, $color-blue-20);
        font-weight: bold;
        padding-bottom: .1em;
    }
}
.main-page-media {
    border-radius: 10px;
    border: 1px solid $color-light-blue10;
    padding: 10px 12px 16px 12px;
    @include break-lg-up {
        border-radius: 30px;
        padding: 28px 38px;
        border-color: $color-blue-30;
    }
    @include break-xl-up {
        border-radius: 50px;
        padding: 38px 58px;
    }
}
.media-item {
    @include fontWeight400;
    &:not(:last-child) {
        border-bottom: 1px solid $color-blue-5;
        padding-bottom: 11px;
        margin-bottom: 11px;
        @include break-lg-up {
            margin-bottom: 28px;
            padding-bottom: 28px;
        }
        @include break-xl-up {
            margin-bottom: 35px;
            padding-bottom: 35px;
        }
    }
    &__meta {
        display: flex;
        flex-direction: column;
        gap: 2px;
        color: $color-blue;
        font-size: 6px;
        margin-bottom: 6px;
        @include break-xs-up {
            font-size: 10px;
        }
        @include break-lg-down {
            position: relative;
        }
        @include break-lg-up {
            flex-direction: row;
            gap: 14px;
        }
        @include break-xl-up {
            font-size: 16px;
            margin-bottom: 16px;
        }
        .article-card-icon-video {
            margin-left: 0;
            @include break-lg-down {
                position: absolute;
                right: 0;
                top: 0px;
            }
        }
    }
    &__source {
        @include break-lg-up {
            max-width: 120px;
        }
        @include break-xl-up {
            max-width: 180px;
        }
    }
    &__date {
        opacity: .5;
        white-space: nowrap;
        @include break-lg-down {
            margin-bottom: 3px;
        }
        @include break-lg-up {
            flex: 0 0 28%;
            max-width: 28%;
        }
        @include break-xl-up {
            flex: 0 0 32%;
            // max-width: 32%;
            max-width: none;
        }
    }
    &__title {
        font-size: 8px;
        @include break-xs-up {
            font-size: 12px;
        }
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
        a {
            @include anchor($color-blue, $color-orange, $color-blue-30);
        }
    }
}
.main-page-ambassadors {
    @include block-extra-padding;
    position: relative;
    margin-bottom: 30px;

    @include break-lg-up {
        margin-top: 110px;
        margin-bottom: 70px;
    }

    &__title {
        width: 50%;
        margin-bottom: 30px;
        @include break-lg-up {
            margin-bottom: 45px;
            width: auto;
        }
        @include break-xl-up {
            margin-bottom: 64px;
        }
        a {
            @include block-title;
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
    }
    &__decoration {
        position: absolute;
        // width: 37.5%;
        top: 0;
        width: 130px;
        right: 10%;
        @include break-md-up {
            width: 170px;
            top: -7%;
            right: 15%;
        }
        @include break-lg-up {
            width: 250px;
            top: -11%;
        }
        @include break-xl-up {
            width: 340px;
            top: -7%;
            right: 14%;
        }
    }
    &__button-wrapper {
        .button {
            display: inline-flex;
        }
        @include break-xs-up {
            margin-top: 20px;
            padding-left: calc(50% + 10px);
        }
        @include break-lg-up {
            margin-top: 42px;
        }
        @include break-xl-up {
            margin-top: 65px;
        }
    }
}
.ambassadors-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    &__col {
        @include break-xs-up {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        &:nth-child(even) {
            padding-top: 24px;
            @include break-xs-up {
                padding-top: 0;
            }
        }
    }
}
.ambassadors-list-item {
    border-radius: 15px;
    border: 1px solid $color-blue;
    overflow: hidden;
    min-height: 200px;
    @include break-xs-up {
        min-height: 0;
        &:last-child {
            transform: translateY(24px);
            @include break-lg-up {
                transform: translateY(42px);
            }
            @include break-xl-up {
                transform: translateY(65px);
            }
        }
    }
    @include break-lg-up {
        border-radius: 30px;
    }
    @include break-xl-up {
        border-radius: 50px;
    }

    &__image-wrapper {
        padding-top: 100%;
        position: relative;

        img {
            @include abs100;
            object-fit: cover;
        }
    }
    &__text-wrapper {
        padding: 8px 11px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        color: $color-blue;
        @include break-md-up {
            padding: 12px 15px;
        }
        @include break-lg-up {
            padding: 15px 22px 25px;
        }
        @include break-xl-up {
            gap: 10px;
            padding: 20px 36px 30px;
        }
    }
    &__title {
        font-size: 10px;
        @include fontWeight600;
        @include break-md-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
    &__description {
        font-size: 7px;
        @include fontWeight400;
        @include break-md-up {
            font-size: 10px;
        }
        @include break-xl-up {
            font-size: 16px;
        }
    }
}

.main-page-awards {
    @include section-margin-bottom;
    &__title {
        @include block-extra-padding;
        @include block-title;
        @include transition-regular;
        color: $color-blue;
        margin-bottom: 18px;
        @include break-lg-up {
            margin-bottom: 50px;
        }
        @include break-xl-up {
            margin-bottom: 70px;
        }
    }
}
.awards-slider-wrapper { 
    position: relative;   
    @include block-extra-padding;
    @include break-xs-down {
        padding: 0;
    }
}
.awards-slider {
    border-radius: 15px;
    border: 1px solid $color-orange;
    overflow: hidden;
    @include break-lg-up {
        border-radius: 30px;
    }
    @include break-xl-up {
        border-radius: 50px;
    }
}
.awards-slide {
    display: flex;
    flex-direction: column;
    @include break-xs-up {
        flex-direction: row;
        justify-content: space-between;
        height: auto;
    }
    
    &__text-wrapper {
        padding: 14px 20px;
        color: $color-blue;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include break-lg-up {
            padding: 32px 40px;
            gap: 25px;
        }
        @include break-xl-up {
            padding: 50px 60px;
            gap: 35px;
        }
    }
    &__top-text {
        @include fontWeight600;
        font-size: 10px;
        @include break-sm-up {
            font-size: 12px;
        }
        @include break-md-up {
            font-size: 14px;
        }
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
    &__title {
        font-size: 14px;
        padding-right: 60px;
        @include fontWeight600;        
        @include break-sm-up {
            font-size: 16px;
        }
        @include break-md-up {
            font-size: 18px;
        }
        @include break-lg-up {
            font-size: 24px;
        }
        @include break-xl-up {
            font-size: 40px;
        }
    }
    &__description {
        @include text-normal;
    }
    &__image-wrapper {
        position: relative;
        padding-top: 36.6%;

        @include break-xs-up {
            flex-shrink: 0;
            width: 200px;
            padding: 0;
        }
        @include break-md-up {
            width: 300px;
        }
        @include break-lg-up {
            width: 36%;
        }
        @include break-xl-up {
            width: 40%;
        }

        img {
            @include abs100;
            object-fit: cover;
        }
    }
}
.contribution {
    $paddingX: 13px;
    $paddingX-lg: 50px;
    $paddingX-xl: 50px;
    @include block-extra-padding;
    @include section-margin-bottom;
    @include break-xs-down {
        padding: 0;
    }

    &__content {
        
        color: $color-blue;
        padding: 18px 20px 25px;
        box-shadow: 0px 10px 40px 0px $color-orange-15;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr .7fr;

        @include break-md-up {            
            grid-template-columns: 1fr 0.5fr;
            border-radius: 30px;
        }
        @include break-lg-up {
            padding: 38px;
        }
        @include break-xl-up {
            border-radius: 50px;
            padding: 60px;
        }
    }
    &__nation-goals-title {
        @include fontWeight400;
        font-size: 12px;
        margin-bottom: 24px;

        @include break-md-up {
            font-size: 16px;
        }
        @include break-lg-up {
            font-size: 20px;
            margin-bottom: 30px;
        }
        @include break-xl-up {
            font-size: 30px;
            margin-bottom: 45px;
        }
    }
    &__title {
        @include block-title-bigger-start;
        color: $color-blue;
        margin-bottom: 18px;
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }
    &__nation-goals {        
        @include fontWeight600;
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: $paddingX;
        padding-bottom: $paddingX*0.5;

        @include break-lg-up {
            padding-right: 25px;
        }
    }
    &__un {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        padding-left: $paddingX;
        padding-bottom: $paddingX*0.5;
        border-left: 1px solid $color-blue-30;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        @include break-lg-up {
            padding-left: $paddingX-lg;
        }
        @include break-xl-up {
            padding-left: $paddingX-xl;
        }
    }
    &__un-title {
        @include fontWeight600;
        margin-bottom: 13px;
        font-size: 12px;
        @include break-lg-up {
            font-size: 16px;
            margin-bottom: 25px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
    &__button-wrapper {
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
        @include fontWeight400;
    }
    
    @include break-sm-up {
        &__title {            
            grid-column: 1/span 1;
            grid-row: 1/span 2;
        }
        &__nation-goals {
            grid-column: 1/span 1;
            grid-row: 3/span 4;
        }
        &__un {
            grid-row: 1/span 5;
        }
        &__button-wrapper {
            grid-column: 2/span 1;
            grid-row: 6/span 1;
        }
    }
    &__button-wrapper {
        padding-top: 25px;
        display: flex;
        justify-content: center;
        @include break-sm-up {
            border-left: 1px solid $color-blue-30;
            justify-content: flex-start;
            padding-left: $paddingX;
        }
        @include break-lg-up {
            padding-left: $paddingX-lg;
        }
        @include break-xl-up {
            padding-left: $paddingX-xl;
        }
    }
}
.un-icons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    img {
        width: 54px;
        height: auto;
    }
    @include break-sm-up {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 30px;
        img {
            width: auto;
        }
    }
    @include break-lg-up {
        gap: 20px;
    }
    @include break-xl-up {
        gap: 30px;
    }
}
.nation-goals-list {    
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
    flex-grow: 1;
    justify-content: space-between;

    @include break-sm-up {
        display: grid;
        gap: 50px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.nation-goal {
    &__image-wrapper {
        margin-bottom: 10px;
        @include break-xl-up {
            margin-bottom: 20px;
        }
        img {
            max-width: 50%;
            @include break-lg-up {
                width: auto;
                height: 52px;
            }
            @include break-xl-up {
                height: 80px;
            }
        }
    }
    &__text {
        font-size: 12px;
        @include break-md-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 19px;
        }
    }
}

.main-page-partners {
    // @include section-margin-bottom;
    @include block-extra-padding;
    &__title {
        @include block-title;
        a {
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
    }
    &__cols {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include break-sm-up {
            flex-direction: row;
        }
        @include break-lg-up {
            align-items: flex-start;
        }
        @include break-xl-up {
            gap: 90px;
        }
    }
}
.partners-list-wrapper {
    position: relative;
    padding-top: 25px;

    @include break-sm-up {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @include break-lg-up {
        padding-top: 50px;
        flex: 0 0 63%;
        max-width: 63%;
    }
    @include break-xl-up {
        padding-top: 70px;
        flex: 0 0 56%;
        max-width: 56%;
    }

    .all-partners-button-wrapper {
        position: absolute;
        bottom: 100%;
        right: 0;
        @include break-sm-up {
            position: static;
            margin-top: 50px;
        }
        @include break-xl-up {
            margin-top: 80px;
        }
    }
}
.partners-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px 25px;

    @include break-sm-up {
        justify-content: flex-start;
    }
    @include break-lg-up {
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }

    img {
        // max-height: 45px;
        max-height: 70px;
        max-width: 80px;
        object-fit: contain;
        @include transition-regular;
        filter: grayscale(1);

        &:hover {
            filter: grayscale(0);
        }

        @include break-lg-up {
            /* max-height: 70px;
            max-width: 100px; */
            max-height: 130px;
            max-width: 100%;
            width: 100%;
        }
        /* @include break-xl-up {
            max-width: 190px;
        } */
    }
}

.block-w-sidebar {
    $leftColWidth: 240px;
    $leftColWidth--md: 310px;
    $leftColWidth--xl: 466px;
    $leftColWidth-Bigger: 320px;
    $leftColWidth--md-Bigger: 400px;
    $leftColWidth--xl-Bigger: 545px;
    $gap: 24px;
    $gap--md: 58px;
    $gap--xl: 100px;

    @include break-sm-up {
        display: flex;
        gap: 0 24px;
    }
    @include break-md-up {
        gap: 0 58px;
    }
    @include break-xl-up {
        gap: 0 100px;
    }

    &__left {
        @include break-sm-up {
            flex: 0 0 calc(100% - $leftColWidth - $gap);
            max-width: calc(100% - $leftColWidth - $gap);
        }
        @include break-md-up {
            flex: 0 0 calc(100% - $leftColWidth--md - $gap--md);
            max-width: calc(100% - $leftColWidth--md - $gap--md);
        }
        @include break-xl-up {
            flex: 0 0 calc(100% - $leftColWidth--xl - $gap--xl);
            max-width: calc(100% - $leftColWidth--xl - $gap--xl);
        }

    }
    &__right {
        @include break-sm-down {
            margin-top: 54px;
        }
        @include break-sm-up {
            flex: 0 0 $leftColWidth;
            max-width: $leftColWidth;
        }
        @include break-md-up {
            flex: 0 0 $leftColWidth--md;
            max-width: $leftColWidth--md;
        }
        @include break-xl-up {
            flex: 0 0 $leftColWidth--xl;
            max-width: $leftColWidth--xl;
        }
    }
    &--bigger &__left {
        @include break-sm-up {
            flex: 0 0 calc(100% - $leftColWidth-Bigger - $gap);
            max-width: calc(100% - $leftColWidth-Bigger - $gap);
        }
        @include break-md-up {
            flex: 0 0 calc(100% - $leftColWidth--md-Bigger - $gap--md);
            max-width: calc(100% - $leftColWidth--md-Bigger - $gap--md);
        }
        @include break-xl-up {
            flex: 0 0 calc(100% - $leftColWidth--xl-Bigger - $gap--xl);
            max-width: calc(100% - $leftColWidth--xl-Bigger - $gap--xl);
        }
    }
    &--bigger &__right {
        @include break-sm-up {
            flex: 0 0 $leftColWidth-Bigger;
            max-width: $leftColWidth-Bigger;
        }
        @include break-md-up {
            flex: 0 0 $leftColWidth--md-Bigger;
            max-width: $leftColWidth--md-Bigger;
        }
        @include break-xl-up {
            flex: 0 0 $leftColWidth--xl-Bigger;
            max-width: $leftColWidth--xl-Bigger;
        }
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    gap: 23px;
    @include break-lg-up {
        gap: 32px;
    }
    @include break-xl-up {
        gap: 40px;
    }
}

.media-companies {
    @extend .widget-light-blue;

    @include break-sm-up {
        margin-top: -20px;
        &.widget-media-companies {
            margin-top: 0;
        }
    }
    @include break-lg-up {
        padding: 32px 40px;
    }
    /* @include break-xl-up {
        padding: 50px 60px;
    } */

    &__text {
        color: $color-blue;
        /* font-size: 12px;
        @include fontWeight400;
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 24px;
        } */
        @include text-normal;
        &--mt {
            margin-top: 20px;
            @include break-lg-up {
                margin-top: 47px;
            }
            & + .media-companies__button-wrapper {
                @include break-xl-up {
                    margin-top: 40px;
                }
            }
        }
    }
    &__button-wrapper {
        margin-top: 20px;
        @include break-lg-up {
            margin-top: 40px;
        }
        @include break-xl-up {
            margin-top: 65px;
        }
    }
    /* &::before, &::after {
        content: '';
        position: absolute;
    }
    &::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 18px 18px 0;
        border-color: transparent $color-blue-30 transparent transparent;
        top: 100%;
        right: 22px;

        @include break-sm-up {
            right: auto;
            left: 22px;
            border-width: 18px 18px 0 0;
            border-color: $color-blue-30 transparent transparent transparent;
        }
        @include break-lg-up {
            border-width: 25px 25px 0 0;
            left: 42px;
        }
        @include break-xl-up {
            border-width: 40px 40px 0 0;
            left: 65px;
        }
    }
    &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 15px 0;
        border-color: transparent $color-white transparent transparent;
        top: 100%;
        right: 23px;

        @include break-sm-up {
            right: auto;
            left: 23px;
            border-width: 16px 16px 0 0;
            border-color: $color-white transparent transparent transparent;
        }
        @include break-lg-up {
            left: 44px;
            border-width: 22px 22px 0 0;
        }
        @include break-xl-up {
            border-width: 38px 38px 0 0;
            left: 66px;
        }
    } */
}
.media-companies-list {
    gap: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
    .widget-media-companies & {
        justify-content: flex-start;
        @include break-sm-up {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        @include break-xl-up {
            gap: 40px 60px;
        }
    }
    @include break-lg-up {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include break-xl-up {
        margin-top: 50px;
    }

    &__item {
        img {
            @include break-lg-up {
                max-height: 52px;
                max-width: 130px;
                width: 100%;
                
                .widget-media-companies & {
                    object-position: top left;
                    object-fit: contain;
                    @include break-xl-up {
                        max-width: 160px;
                    }
                }
            }
            @include break-xl-up {
                max-height: 82px;
            }
        }
    }
}

.digest-grid {
    display: grid;
    gap: 10px;
    margin: 20px 0 25px;

    @include break-sm-up {
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 0;
    }
    @include break-md-up {
        grid-template-columns: repeat(3, 1fr);
        gap: 25px 47px;
        margin-top: 40px;
    }
    @include break-lg-up {
        margin-top: 60px;
    }
    @include break-xl-up {
        gap: 50px 70px;
    }

    .digest-grid-item {
        position: relative;
        .digest-anchor {
            @include abs100;
            z-index: 1;
        }
        .widget-digest,
        .block-w-image-and-link {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .button,
        a {
            position: relative;
            z-index: 2;
        }
    }
}

.block-w-image-and-link {
    @include border-radius15-30-50;
    border: 1px solid;
    padding: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: relative;
    background-color: $color-white;

    @include break-lg-up {
        padding: 35px;
    }
    @include break-xl-up {
        padding: 55px 70px;
    }

    &--green {
        border-color: $color-light-green;
    }
    &__text {
        color: $color-blue;
        font-size: 14px;
        @include fontWeight400;

        p:not(:last-child) {
            margin-bottom: 1em;
        }
        @include break-lg-up {
            font-size: 20px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
    &__glow {
        position: absolute;
        top: -60px;
        left: -40px;
        width: 138px;
        height: auto;
        z-index: -1;
    }
    &__content-wrapper {
        gap: 20px;
        display: flex;
        flex-direction: column;
    }
}

.digest-content-card {
    margin-bottom: 15px;
    @include border-radius10-20-30;
    box-shadow: 0px 5px 20px 0px $color-blue-7;
    overflow: hidden;

    @include break-md-up {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
    }

    &__text-area {
        padding: 14px 20px 20px;
        @include break-lg-up {
            padding: 34px;
        }
    }
    &__link-wrapper {
        margin-bottom: 8px;
        @include break-lg-up {
            margin-bottom: 15px;
        }
        @include break-xl-up {
            margin-bottom: 25px;
        }

        a {
            font-size: 14px;
            @include fontWeight600;
            @include anchor($color-orange, $color-blue, $color-blue-20);
            @include break-sm-up {
                font-size: 16px;
            }
            @include break-lg-up {
                font-size: 24px;
            }
            @include break-xl-up {
                font-size: 30px;
            }
        }
    }
    &__text {
        color: $color-blue;
        margin-bottom: 20px;
        p:not(:last-child) {
            margin-bottom: 1em;
        }
        @include text-normal;
        @include break-xl-up {
            margin-bottom: 35px;
        }
    }
    &__image-area {
        position: relative;
        padding-top: 60%;

        a {
            display: block;
            @include transition-regular;
            &:hover {
                opacity: .7;
            }
        }
        img {
            object-fit: cover;
            @include abs100;
        }
        
        @include break-md-up {
            flex: 0 0 140px;
            max-width: 140px;
            height: 140px;
            order: 1;
            margin: 10px;
            padding-top: 0;
            overflow: hidden;
            border-radius: 13px;
        }
        @include break-lg-up {
            flex: 0 0 220px;
            max-width: 220px;
            height: 220px;
        }
        @include break-xl-up {
            flex: 0 0 327px;
            max-width: 327px;
            height: 327px;
            margin: 18px;
            border-radius: 17px;
        }
    }
    /* .external-link {
        @include break-md-down {
            font-size: 10px;
        }
        @include break-sm-down {
            font-size: 8px;
            &__arrow {
                width: 12px;
            }
        }
    } */
}
.digest-nav {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin-top: 34px;

    @include break-lg-up {
        margin-top: 53px;
    }
    @include break-xl-up {
        margin-top: 80px;
    }
}

.file {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__info {
        font-size: .667em;
        display: block;
        color: $color-blue;
        @include fontWeight600;
        &::before {
            content: '';
            display: inline-block;
            vertical-align: bottom;
            background: url(../images/icons/icon-doc.svg) no-repeat 50% 100% / contain;
            width: 1.75em;
            height: 2.125em;
            margin-right: .56em;
        }
    }
    &__anchor {
        font-size: 1em;
        line-height: 1.3;

        span {
            @include anchor($color-orange, $color-blue, $color-blue-20);
        }
    }
    &:hover &__anchor span {
        color: $color-blue;
    }

    &--info-bottom &__info {
        order: 1;
        &::before {
            width: .875em;
            height: 1.0625em;
        }
    }
}
.multi-column {
    display: grid;
    gap: 15px;

    margin: 15px 0;

    @include break-sm-up {
        margin: 25px 0;
    }
    @include break-md-up {
        margin: 40px 0;
    }
    @include break-xl-up {
        margin: 60px 0;
    }

    &--2 {
        @include break-sm-up {
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
        @include break-md-up {
            gap: 40px;
        }
        @include break-xl-up {
            gap: 60px;
        }
    }
    &--3 {
        @include break-sm-up {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 15px;
        }
        @include break-md-up {
            gap: 20px;
        }
        @include break-xl-up {
            gap: 30px;
        }
    }

    &-item {
        &__image-wrapper {
            position: relative;
            padding-top: 60%;
            margin-bottom: 10px;
            img {
                @include abs100;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        &__title {
            line-height: 1.3;
        }
    }
    &--2 &-item {
        &__image-wrapper {
            @include break-lg-up {
                margin-bottom: 29px;
            }
            img {
                @include break-lg-up {
                    border-radius: 30px;
                }
            }
        }
        &__title {
            @extend .h3;
            margin-bottom: 10px !important;
            @include break-lg-up {
                margin-bottom: 23px !important;
            }
        }
    }
    &--3 &-item {
        &__image-wrapper {
            @include break-lg-up {
                margin-bottom: 20px;
            }
            img {
                @include break-lg-up {
                    border-radius: 15px;
                }
            }
        }
        &__title {
            @extend .h4;
            margin-bottom: 7px !important;
            @include break-lg-up {
                margin-bottom: 15px !important;
            }
        }
        /* &__text {
            @include text-smaller;
            @include break-sm-down {
                font-size: 12px;
            }
        } */
    }
}
.toggable-text-block {
    margin: 25px 0;
    @include break-md-up {
        margin: 35px 0;
    }
    @include break-xl-up {
        margin: 65px 0;
    }
    &__text-wrapper {
        overflow: hidden;
        max-height: 100px;
        position: relative;
        margin-bottom: 15px;

        @include break-md-up {
            max-height: 192px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 80px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
            z-index: 1;
            .is-toggled & {
                display: none;
            }
        }
    }
    &__button {
        color: $color-orange;

        &::after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            background: url(../images/arrow-orange.svg) no-repeat center / contain;
            width: 14px;
            height: 16px;
            margin-left: 7px;
            transform: rotate(90deg);
            .is-toggled & {
                transform: rotate(-90deg);
            }
            @include break-lg-up {
                width: 20px;
                height: 22px;
                margin-left: 15px;
            }
        }
    }
}

.video-block {
    position: relative;
    overflow: hidden;
    padding-top: 56.2%;
    margin-bottom: 20px;
    @include break-lg-up {
        margin-bottom: 40px;
    }
    @include border-radius15-35-50;


    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .started & {
            display: none;
        }
    }

    &__poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__play-icon {
        position: relative;
        z-index: 1;
        height: auto;
        width: 13.3%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.achivs-cards {
    display: grid;
    gap: 20px 30px;
    
    @include break-xs-up {        
        grid-template-columns: 1fr 1fr;
    }
    @include break-lg-up {
        gap: 30px 60px;
    }
}
.card-w-icon-and-text {
    @include border-radius10-20-30;
    box-shadow: 0px 5px 20px 0px $color-blue-7;
    padding: 20px;

    @include break-md-up {
        padding: 40px;
    }

    &__image-wrapper {
        height: 60px;
        margin-bottom: 15px;

        @include break-lg-up {
            height: 120px;
            margin-bottom: 30px;
        }

        img {
            height: 100%;
            width: auto;
            max-width: 100%;
        }
    }

    &__title {
        color: $color-blue;
        margin-bottom: 10px;
        @include fontWeight600;
        font-size: 16px;
        @include break-lg-up {
            font-size: 34px;
            margin-bottom: 30px;
        }
    }
    &--bigger-title &__title {
        font-size: 18px;
        line-height: 1;
        @include break-lg-up {
            font-size: 40px;
        }
    }
    &__text {
        color: $color-blue;        
        font-size: 12px;
        @include fontWeight400;
        @include break-sm-up {
            font-size: 14px;
        }
        @include break-lg-up {
            font-size: 24px;
        }

        ul li {
            position: relative;
            padding-left: 1em;
            &:not(:last-child) {
                margin-bottom: 1.2em;
            }

            &:before {
                content: '\2022';
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &--bigger-title &__text {
        @include break-lg-up {
            font-size: 19px;
        }
    }
}
.two-cols-cards-layout {
    $gapY: 20px;
    $gapY--xs: 40px;
    display: grid;
    gap: $gapY 30px;
    align-items: flex-start;

    @include break-xs-up {
        grid-template-columns: 1fr 1fr;
    }
    @include break-lg-up {
        gap: $gapY--xs 60px;
    }

    .col {
        display: grid;
        gap: $gapY;
        @include break-lg-up {
            gap: $gapY--xs;
        }
    }
    &--height-equal .col {
        height: 100%;
    }
}
.about-achievements {
    @include section-margin-y;
    
    .section-title {
        margin-bottom: 15px;
        @include break-md-up {
            margin-bottom: 30px;
        }
    }
}
.activities-types-section {

    .section-title {
        margin-bottom: 20px;
        @include break-md-up {
            margin-bottom: 45px;
        }
    }
}

.definition-block {
    @include border-radius10-20-30;
    box-shadow: 0px 7px 40px 0px rgba(12, 71, 119, 0.10);
    padding: 25px;
    margin-bottom: 40px;
    margin-top: 25px;

    @include break-md-up {
        padding: 40px 55px 30px;
        margin-top: 45px;
        margin-bottom: 70px;
    }

    &__title {
        @extend .h2;
        margin-bottom: 20px;
        
        a {
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
    }
    &__bottom {
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px 40px;
        margin-top: 20px;

        img {
            flex-shrink: 0;
            max-width: 40%;
        }
    }
    &__button-wrapper {
        margin: 20px 0;
    }
}

.steps-nowrap {
    display: grid;
    gap: 20px;
    max-width: 1408px;

    @include break-sm-up {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
.block-step {
    position: relative;

    &__inner {
        @include border-radius15-35-50;
        @include card-border;
        padding: 25px;
        background-color: $color-white;
        min-height: 210px;

        @include break-sm-up {
            // min-height: 230px;
            min-height: 305px;
        }
        @include break-lg-up {
            padding: 45px 55px;
            // min-height: 0;
            min-height: 390px;
        }
        @media screen and (min-width: 1680px) {
            min-height: 0;
        }
    }
    &--register {
        &:not(:last-child)::after {
            content: '';
            position: absolute;
            width: 40px;
            height: 1px;        
            background: url(../images/dash.svg) repeat-x 0 center;
            right: -24px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;

            @include break-sm-down {
                right: auto;
                left: 20px;
                top: auto;
                transform: rotate(90deg);
                bottom: -5px;
            }
            @include break-lg-up {
                height: 2px;
            }
        }
    }
    &--register &__inner {
        height: 100%;
        min-height: 0;
        @include break-lg-up {
            padding: 28px 35px 35px;
        }
    }

    &__title {
        @extend .h2;
        margin-bottom: 15px;
        @include break-lg-up {
            margin-bottom: 35px;
        }
    }
    &--register &__title {
        margin-bottom: 0;
    }
    &__subtitle {
        @include text-regular;
        @include fontWeight600;
        color: $color-blue;
        margin-bottom: 1em;
    }
    &__text {
        // @include text-smaller;
        font-size: 12px;
        @include break-sm-up {
            font-size: 14px;
        }
        @include break-xl-up {
            font-size: 19px;
        }
        @include fontWeight400;
        color: $color-blue;

        p:not(:last-child) {
            margin-bottom: 1.5em;
        }
        a:not(.button) {
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
    }
    &--register &__text {
        @include text-small;
    }

    &--blue {
        @include break-xs-up {
            margin-top: 140px;
        }
        @include break-sm-up {
            margin-top: 240px;
        }
        @include break-xl-up {
            margin-top: 330px;
        }
    }
    &--blue &__inner {
        min-height: 0;
        background-color: $color-blue;
    }
    &--blue &__text {
        color: $color-white;
        @include text-bigger;
    }
    
    @include glow;
    &::before {
        background: $color-blue;
        box-shadow: 0px 0px 9em 5em $color-blue;
        top: 4em;
        left: 2em;
        opacity: .2;

        @include break-lg-down {
            font-size: 4px;
        }
    }
}
.blue-step-image {
    position: absolute;
    z-index: -1;
    width: 50px;
    right: 13%;
    top: -35px;

    @include break-xs-up {
        width: 110px;
        bottom: 84%;
        top: auto;
    }
    @include break-sm-up {
        width: 50%;
    }
}
.history-track {
    $gap: 30px;
    $gap--sm: 60px;

    margin-top: 30px;

    &__desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        gap: $gap;
        @include break-lg-up {
            gap: $gap--sm;
        }
        @include break-xs-down {
            display: none;
        }

        .left-col {
            .block-step:nth-last-child(n+2) {
                margin-bottom: 50px;
                @include break-lg-up {
                    margin-bottom: 100px;
                }
            }
        }
        .right-col {
            padding-top: 120px;

            @include break-lg-up {
                padding-top: 240px;
            }

            .block-step:nth-last-child(n+3) {
                margin-bottom: 60px;
                @include break-lg-up {
                    margin-bottom: 120px;
                }
            }
        }
    }
    &--taxes &__desktop {
        .right-col {
            @include break-lg-up {
                padding-top: 280px;
            }
        }
    }
    
    &__mob {
        .bubble {
            margin-left: auto;
        }
        @include break-xs-up {
            display: none;
        }
    }

    .step-line {
        position: absolute;
        bottom: 100%;
        width: auto;
        height: 40px;
    
        @include break-lg-up {
            height: 62px;
        }
    
        &--right {
            right: -$gap;
    
            @include break-lg-up {
                right: -$gap--sm;
            }
        }
        &--left {
            left: -$gap;
    
            @include break-lg-up {
                left: -$gap--sm;
            }
        }

        &--vertical {
            position: static;
            display: block;
            margin-left: 40px;
        }
    }
}


.steps-track {
    $gap: 30px;
    $gap--sm: 100px;

    margin-top: 30px;
    @include break-lg-up {
        margin-top: 60px;
    }

    .block-step__inner {
        min-height: 0;
    }

    &__desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        gap: $gap;
        @include break-lg-up {
            gap: $gap--sm;
        }
        @include break-xs-down {
            display: none;
        }

        .left-col {
            .block-step:nth-last-child(n+2) {
                margin-bottom: 50px;
                @include break-lg-up {
                    margin-bottom: 100px;
                }
            }
        }
        .right-col {
            padding-top: 120px;

            @include break-lg-up {
                padding-top: 150px;
            }

            .block-step:first-child {
                margin-bottom: 60px;
                @include break-lg-up {
                    margin-bottom: 300px;
                }
            }
        }
    }
    &__mob {
        .bubble {
            margin-left: auto;
        }
        @include break-xs-up {
            display: none;
        }
    }

    .step-line {
        position: absolute;
        bottom: 100%;
        width: auto;
        height: 40px;
    
        @include break-lg-up {
            height: 88px;
        }
    
        &--right {
            right: -$gap;
    
            @include break-lg-up {
                right: -$gap--sm;
            }
        }
        &--left {
            left: -$gap;
    
            @include break-lg-up {
                left: -$gap--sm;
            }
        }

        &--vertical {
            position: static;
            display: block;
            margin-left: 40px;
        }
    }
}

.misson-cards {
    margin-top: 30px;
    @include break-md-up {
        margin-top: 70px;
    }
}
.misson-card {
    margin-bottom: 28px;
    @include border-radius10-20-30;
    box-shadow: 0px 5px 20px 0px $color-blue-7;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @include break-xs-up {
        flex-direction: row;
    }
    @include break-lg-up {
        padding: 40px 45px 40px 60px;
    }

    &__image-wrapper {
        flex: 0 0 40%;
        max-width: 40%;
        @include break-xs-up {            
            flex: 0 0 20%;
            max-width: 20%;
        }
        img {
            width: 100%;
        }
    }
    &__text {
        order: -1;
        color: $color-blue;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-small {
            @include fontWeight400;
            @include text-smaller;
            font-size: 12px;
            @include break-sm-up {
                // font-size: 14px;
            }
            p {
                margin-bottom: 1em;
            }
        }
        @include break-xs-down {
            width: 100%;
            text-align: center;
        }
        @include break-md-up {
            gap: 60px;
        }
    }
}

.results-numbers {
    display: grid;
    gap: 15px;
    margin-top: 25px;
    @include break-xs-up {
        gap: 28px 19px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include break-sm-up {
        margin-top: 45px;
    }
    @include break-lg-up {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
.results-card {
    $spread: 7em;
    @include border-radius15-35-50;
    background-color: $color-blue;
    padding: 25px;
    color: $color-white;
    position: relative;
    
    @include break-xs-up {
        padding: 40px;
    }
    @include break-xl-up {
        padding: 60px;
    }

    @include glow;
    &::before {
        background: $color-blue;
        box-shadow: 0px 0px 9em $spread $color-blue;
        top: 4em;
        left: 2em;
        opacity: .2;

        @include break-lg-down {
            font-size: 4px;
        }
    }

    &--long {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        @include break-xs-up {
            grid-column: 1/3;
        }
        @include break-lg-up {
            grid-column: 1/4;
        }
    }
    &--green {
        background-color: $color-green;
        &::before {
            box-shadow: 0px 0px 9em $spread $color-green;
        }
    }
    &--orange {
        background-color: $color-orange;
        &::before {
            box-shadow: 0px 0px 9em $spread $color-orange;
        }
    }

    .button {
        width: 100%;
        max-width: 183px;
        // justify-content: center;

        &:hover {
            border-color: $color-white;
        }
    }
    /* &--long .button {
        max-width: 262px;
    } */
    &__image-wrapper {
        display: none;
        max-width: 25%;
        align-self: center;
        img {
            width: 100%;
        }
        @include break-xs-up {
            display: block;
        }
        @include break-md-up {
            max-width: none;
            img {
                height: 100%;
                width: auto;
            }
        }
    }
    &__big-text {
        font-size: 50px;
        font-weight: bold;
        line-height: 1;
        @include break-xs-up() {
            font-size: 80px;
        }
        @include break-md-up() {
            font-size: 140px;
        }
        @include break-xl-up() {
            font-size: 200px;
        }
    }
    &__smaller-text {
        font-weight: bold;
        font-size: 24px;
        line-height: 1;
        @include break-md-up() {
            font-size: 40px;
        }
        @include break-xl-up() {
            font-size: 60px;
        }
    }
    &--long &__smaller-text {
        @include block-title-smaller;
        @include fontWeight600;
    }
    &__smallest-text {
        @include text-regular;
        margin-top: 15px;
        line-height: 1.6;
    }
    &__button-wrapper {
        margin-top: 20px;
        @include break-xs-up {
            margin-top: 35px;
        }
    }
    &--long &__button-wrapper {
        margin-top: 30px;
        @include break-xs-up {
            margin-top: 45px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__texts {
        flex-grow: 1;
    }
}
.reports {
    margin-top: 85px;

    &__grid {
        margin-top: 20px;
        display: grid;
        gap: 20px;
        
        @include break-xs-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include break-sm-up {
            margin-top: 50px;
        }
        @include break-md-up {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @include break-lg-up {
            gap: 70px 20px;
        }
    }
    &__year {
        @include block-title;
        color: $color-blue; 
        @include fontWeight600;
        margin-bottom: 20px;
    }
    &__list {
        @include text-regular;

        li {
            margin-bottom: 1.5em;
            @include fontWeight600;
        }
    }
}

.ambassadors-page-text {
    @include break-xs-up {
        padding-right: 35%;
    }
}

.ambassadors-grid-wrapper {
    position: relative;
    margin-bottom: 30px;
    @include break-xs-up {
        margin-bottom: 70px;
    }
}
.ambassadors-grid {
    display: grid;
    gap: 20px;
    margin-top: 30px;

    @include break-xs-up {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 48px 20px;
        margin-top: 68px;
    }

    &__decor {
        position: absolute;
        width: 27.3%;
        right: 3%;
        top: 0;
        transform: translateY(-55%);
        @include break-xs-down {
            display: none;
        }
    }
}
.ambassador-card {
    @include border-radius15-35-50;
    @include card-border;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    @include break-lg-up {
        flex-direction: row;
    }

    &:nth-child(even) {
        @include break-xs-up {
            transform: translateY(60px);
        }
    }

    &__image-wrapper {
        position: relative;
        background-color: $color-blue-7;
        padding-top: 100%;

        @include break-lg-up {
            @include fwidth6;
            padding-top: 0;
        }

        img {
            @include abs100;
            object-fit: cover;
            
            &.no-photo {
               @include no-photo;
            }
        }
    }
    &__text {
        padding: 20px;
        color: $color-blue;
        background-color: $color-white;

        @include fontWeight400;
        @include break-lg-up {
            padding: 40px;
            @include fwidth6;
        }
    }
    &__name {
        @include text-regular;
        @include fontWeight600;
    }
    &__role,
    &__description {
        font-size: 12px;
        margin-top: 1em;

        @include break-sm-up {
            font-size: 14px;
        }
        @include break-lg-up {
            font-size: 16px;
        }
    }
}

.two-cols-block {
    display: grid;
    gap: 20px;
    @include break-xs-up {
        gap: 40px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.jur-docs-list {
    @include text-regular;
    @include fontWeight400;
    li:not(:last-child) {
        margin-bottom: 22px;
    }
}
.jur-info-item {
    margin-bottom: 26px;
    color: $color-blue;
    @include fontWeight400;

    &__big-text {
        @include text-bigger;
        margin-top: 10px;
    }
    &__small-text {
        @include text-small;
    }
}

.contacts-block {
    color: $color-blue;
    @include fontWeight400;

    &:not(:last-child) {
        margin-bottom: 25px;
        @include break-lg-up {
            margin-bottom: 55px;
        }
    }

    a {
        @include anchor();
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    &__title {
        @include text-regular;
        @include fontWeight600;
        color: $color-blue;
        margin-bottom: 17px;
    }
    &__cols {
        display: grid;
        gap: 15px;

        @include break-xs-up {
            gap: 30px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    &__text-small {
        @include text-small;
    }
    &__text-big {
        @include text-bigger;
    }
}
.map-wrapper {
    margin-top: 30px;
    @include break-md-up {
        margin-top: 70px;
    }
}
.map {
    @include border-radius15-35-50;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    overflow: hidden;
    position: relative;

    iframe {
        height: 400px;
        @include break-sm-up {
            height: 656px;
        }
    }
}
.map-bar {
    background-color: $color-blue;
    color: $color-white;
    @include border-radius10-20-30;
    padding: 24px;
    position: absolute;
    top: 20px;
    left: 20px;
    @include fontWeight400;
    max-width: calc(100% - 40px);
    @include break-xs-up {
        top: 35px;
        left: 35px;
        max-width: 500px;
    }

    &__title {
        @include fontWeight600;
        @include text-bigger;
        margin-bottom: .5em;
    }
    &__text {
        @include text-regular
    }
}

.team-page-text {
    max-width: 1110px;
}

.management {
    margin-top: 30px;
}
.management-cards {
    display: grid;
    gap: 20px;
    margin-top: 20px;

    @include break-xs-up {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 40px;
    }
    @include break-lg-up {
        gap: 20px 60px;
        grid-template-columns: repeat(auto-fill, 25.3%);
        margin-top: 42px;
    }
}
.management-card {
    @include card-border;
    @include border-radius15-35-50;
    overflow: hidden;

    &__image-wrapper {
        position: relative;
        padding-top: 100%;
        background-color: $color-blue-7;

        img {
            @include abs100;
            object-fit: cover;
            &.no-photo {
                @include no-photo;
            }
        }
    }
    &__text {
        padding: 15px;
        color: $color-blue;
        @include fontWeight400;
        display: grid;
        gap: 5px;
        @include break-sm-up {
            padding: 20px 36px 34px 36px;
        }
    }
    &__name {
        @include text-regular;
        @include fontWeight600;
    }
    &__post {
        @include text-small;
    }
}

.team {
    margin-top: 65px;
}
.team-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    font-size: 12px;
    @include fontWeight400;
    margin-top: 20px;
    @include break-md-up {
        font-size: 19px;
    }
}

.team-cards {
    $listItemGap: 25px;
    $listItemGap--lg: 55px;
    
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    align-items: flex-start;

    @include break-xs-up {
        flex-wrap: nowrap;
    }
    @include break-md-up {
        margin-top: 57px;
    }

    &__main {
        @include fwidth12;
        @include break-xs-up {
            flex: 0 0 160px;
            max-width: 160px;
        }
        @include break-sm-up {
            flex: 0 0 200px;
            max-width: 200px;
        }
        @include break-lg-up {
            flex: 0 0 404px;
            max-width: 404px;
        }
    }
    &__list {
        /* padding-left: 15px;
        padding-top: 30px; */
        /* display: grid;
        gap: $listItemGap; */
        width: 100%;
        display: grid;
        @include break-xs-up {
            // grid-template-columns: repeat(2,minmax(0,1fr));
            // padding-left: 50px;
            // column-count: 2;
            column-gap: 50px;
            grid-auto-flow: row;
            grid-template-columns: repeat(2,1fr);
        }
        /* @include break-sm-up {
            grid-template-columns: repeat(auto-fit, minmax(0, 220px));
        } */
        @include break-md-up {
            // column-count: 4;
            grid-template-columns: repeat(4,1fr);
        }
        @include break-lg-up {
            // grid-template-columns: repeat(auto-fit, minmax(0, 270px));
            // gap: $listItemGap--lg;
            /* padding-left: 94px;
            padding-top: 50px; */
        }
        
        &--single-col-cards {
            /* grid-template-rows: none;
            grid-auto-flow: row; 
            grid-template-columns: repeat(auto-fit, minmax(0, 270px));*/
            display: flex;
            row-gap: 25px;
            flex-direction: column;
            @include break-sm-up {
                flex-direction: row;
            }
        }
        .team-card {
            break-inside: avoid-column;
            margin-bottom: $listItemGap;
            @include break-lg-up {
                margin-bottom: $listItemGap--lg;
            }
        }
    }
    &__list-inner {
        /* display: grid;
        gap: $listItemGap;
        @include break-lg-up {
            gap: $listItemGap--lg;
        } */
    }
    &__bar-area {
        margin-bottom: $listItemGap;
        max-width: 443px;
        @include break-sm-up {
            margin-left: 80px;
            align-self: flex-end;
        }
        @include break-lg-up {
            margin-bottom: $listItemGap--lg;
        }
        @include break-xl-up {
            margin-left: 140px;
        }
    }
}
.team-card-info {
    color: $color-blue;
    @include fontWeight400;

    &__name {
        @include text-regular;
        @include fontWeight600;
    }
    &__post {
        @include text-small;
        margin-top: 4px;
    }
    &__contact {
        margin-top: 19px;
        word-break: break-word;
        @include text-smaller;
        a {
            @include anchor;
        }
    }
}
.team-card-main {
    @include card-border;
    @include border-radius15-35-50;
    overflow: hidden;

    &__image-wrapper {
        position: relative;
        padding-top: 90%;
        background-color: $color-blue-7;

        img {
            @include abs100;
            object-fit: cover;
            &.no-photo {
                @include no-photo;
                width: 62%;
            }
        }
    }
    .team-card-info {
        padding: 15px;
        @include break-lg-up {
            padding: 23px 36px 38px;
        }
    }
}

.team-block {
    &:not(:last-child) {
        margin-bottom: 25px;
        // @include break-md-up {
        //     margin-bottom: 40px;
        // }
        @include break-sm-up {
            margin-bottom: 65px
        }
    }
    &__head {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px 50px;
    }
}

.cards-to-3cols {
    display: grid;
    gap: 20px;
    @include break-sm-up {
        grid-template-columns: repeat(3, minmax(0,1fr));
    }
    &--xl {
        grid-template-columns: 1fr;
        @include break-xl-up {
            grid-template-columns: repeat(3, minmax(0,1fr));
        }
    }
}
.cards-to-2cols {
    display: grid;
    gap: 20px;
    @include break-sm-up {
        grid-template-columns: repeat(2, minmax(0,1fr));
    }
    @include break-lg-up {
        gap: 45px;
    }
}
.bubbled-text-block {
    @extend .widget-light-blue;
    @include border-radius10-20-30;
    
    padding: 25px !important;
    @include break-md-up {
        padding: 30px !important;
    }
    @include break-xl-up {
        padding: 40px 45px 50px !important;
    }
    ul {
        margin-bottom: 0;
    }
    &__title {
        margin-bottom: 15px;
        @include break-md-up {
            margin-bottom: 25px;
        }
    }

}

.accepting-info {
    display: grid;
    gap: 20px;
    @include break-sm-up {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include break-lg-up {
        gap: 60px;
    }
}
.colored-bar {
    @include border-radius10-20-30;
    color: $color-white;
    padding: 20px;

    @include break-md-up {
        padding: 20px 30px;
    }
    @include break-xl-up {
        padding: 40px 60px;
    }

    &--red {
        background-color: $color-red;
    }
    &--green {
        background-color: $color-green;
    }

    &__title {
        @include text-bigger;
        @include fontWeight600;
        margin-bottom: 18px;
    }
    &__text {
        @include fontWeight400;
        @include text-smaller;
    }
}

.card-simple {
    @include border-radius10-20-30;
    box-shadow: 0px 5px 20px 0px $color-blue-7;
    padding: 20px;
    color: $color-blue;

    @include break-lg-up {
        padding: 30px 40px;
    }
    @include break-xl-up {
        padding: 50px 60px;
    }

    &__title {
        @include text-bigger;
        @include fontWeight600;
        margin-bottom: 1em;
    }
    &__text {
        @include text-smaller;
        @include fontWeight400;

        p:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}

.volunteer-grid {
    display: grid;
    gap: 15px 30px;
    @include break-xs-up {
        grid-template-columns: repeat(2, minmax(0,1fr))
    }
    @include break-lg-up {
        gap: 30px 60px;
    }
    &--bigger-gap {
        @include break-lg-up {
            row-gap: 45px;
        }
    }
}
.card-w-image {
    @include border-radius10-20-30;
    overflow: hidden;
    box-shadow: 0px 5px 20px 0px $color-blue-15;

    &__image-wrapper {
        position: relative;
        padding-top: 73%;

        img {
            @include abs100;
            object-fit: cover;
        }
    }
    &--titled &__image-wrapper {
        padding-top: 60%;
    }
    &__content {
        color: $color-blue;
        padding: 20px 25px 25px;
        @include fontWeight400;
        @include break-lg-up {
            padding: 30px 45px 45px;
        }
        @include break-xl-up {
            padding: 40px 60px 60px;
        }
        p:not(:last-child) {
            margin-bottom: 1.2em;
        }
    }
    &--titled &__content {
        @include break-xl-up {
            padding-top: 30px;
        }
    }
    &__text-big {
        @include block-text-big;
        line-height: 1;
        margin-bottom: .8em;
    }
    &__text-medium {
        @include text-regular;
        margin-bottom: 15px;
        @include break-md-up {
            margin-bottom: 30px;
        }
    }
    &__text-small {
        @include text-smaller;
    }
}

.help-grid {
    display: grid;
    gap: 15px;

    @include break-sm-up {
        grid-template-columns: repeat(3,minmax(0,1fr));
        gap: 30px;
    }
    @include break-xl-up {
        gap: 60px;
    }
    @include break-xxl-up {
        gap: 100px;

    }
}

.propagation-cols {
    display: grid;
    margin: 20px 0 30px;
    @include break-sm-up {
        grid-template-columns: repeat(2, minmax(0,1fr));
        margin: 40px 0 50px;
    }
    @include break-xl-up {
        margin: 70px 0 85px;
    }
}
.propagation-col {
    color: $color-blue;
    display: flex;
    flex-direction: column;
    @include text-smaller;
    @include fontWeight400;

    &:first-child {
        padding-bottom: 25px;
        border-bottom: 1px solid $color-orange-30;
        @include break-sm-up {
            padding-right: 40px;
            padding-bottom: 0;
            border-bottom: none;
            border-right: 2px solid $color-orange-30;
        }
        @include break-xl-up {
            padding-right: 80px;
        }
    }
    &:last-child {
        padding-top: 25px;
        @include break-sm-up {
            padding-left: 40px;
        }
        @include break-xl-up {
            padding-left: 80px;
        }
    }

    &__image-wrapper {
        margin-bottom: 10px;
        @include break-sm-down {
            margin-right: 15px;
            flex-shrink: 0;
        }
        @include break-lg-up {
            margin-bottom: 30px;
        }
        img {
            width: 50px;
            @include break-lg-up {
                width: 80px;
            }
            @include break-xl-up {
                width: 110px;
            }
        }
    }
    &__top {
        flex-grow: 1;
        @include break-sm-down {
            display: flex;
            align-items: center;
        }
    }
    &__bottom {
        margin-top: 15px;
        @include break-sm-up {
            margin-top: 30px;
        }
    }
    &__text {
        a {
            @include anchor();
        }
    }
}
.social-media-link {
    &:not(:last-child) {
        margin-bottom: 12px;
    }
    &__anchor {
        display: flex;
        align-items: flex-start;
        @include anchor($color-blue, $color-orange, 0);
    }
    &__text {
        padding-left: .68em;
    }
    &__icon {
        width: 1.79em;
        height: 1.79em;
        position: relative;
        top: -.3em;
        flex-shrink: 0;
    }
}

.ask-questions {
    @include border-radius15-35-50;
    box-shadow: 0px 10px 40px 0px $color-orange-15;
    padding: 20px 30px;
    color: $color-blue;
    position: relative;

    @include break-sm-up {
        padding-right: 37%;
    }
    @include break-lg-up {
        padding: {
            top: 35px;
            bottom: 50px;
            left: 50px;
        }
    }
    @include break-xl-up {
        padding: {
            top: 50px;
            bottom: 60px;
            left: 60px;
        };
    }

    &__text-big {
        @include text-regular;
        @include fontWeight600;
    }
    &__image-wrapper {
        @include break-sm-down {
            margin-right: 15px;
            flex-shrink: 0;
        }
        img {
            width: 50px;
            @include break-sm-up {
                @include abs-valigned;
                right: 7%;
                width: 20%;
                max-height: 80%;
            }
        }
    }
    &__top {
        @include break-sm-down {
            display: flex;
            align-items: center;
        }
    }
    &__bottom {
        @include text-smaller;
        @include fontWeight400;
        margin-top: 20px;

        @include break-lg-up {
            margin-top: 36px;
        }
        p:not(:last-child) {
            margin-bottom: 1em;
        }
        a {
            @include anchor();
        }
    }
}

.partners-grid {
    &:not(.is-active) {
        display: none;
    }
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    gap: 25px 40px;
    @include break-xs-up {
        grid-template-columns: repeat(3, minmax(0,1fr));
        gap: 25px 80px;
    }
    @include break-xl-up {
        grid-template-columns: repeat(4, minmax(0,1fr));
        gap: 25px 120px;
    }
    &__item {
        $heightIMG: 80px;
        $heightIMG--lg: 100px;
        $heightIMG--xl: 160px;
        height: $heightIMG;
        display: flex;
        align-items: center;
        justify-content: center;
        @include break-lg-up {
            height: $heightIMG--lg;
        }
        @include break-xl-up {
            height: $heightIMG--xl;
        }

        .button-nav {
            white-space: nowrap;
        }
        img {
            max-height: $heightIMG;
            @include break-lg-up {
                max-height: $heightIMG--lg;
            }
            @include break-xl-up {
                max-height: $heightIMG--xl;
            }
            filter: grayscale(1);
            @include transition-regular;
        }
        &:hover img {
            filter: grayscale(0);
        }
    }
}

.card-w-text {
    padding: 20px;
    box-shadow: 0px 5px 20px 0px $color-blue-15;
    @include border-radius10-20-30;
    @include break-sm-up {
        padding: 30px 20px;
    }
    @include break-lg-up {
        padding: 50px 40px;
    }
    &__title {
        line-height: 1;
        margin-bottom: .8em;
    }
    &__text {
        @include text-smaller;
        @include fontWeight400;
        color: $color-blue;
        p:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}

.widget-nation-goals {
    display: grid;
    gap: 20px;
    margin-bottom: 25px;
    @include break-md-up {
        gap: 40px;
        margin-bottom: 50px;
    }
}
.widget-nation-goal {
    &__image-wrapper {
        margin-bottom: 17px;
        img {
            width: 50px;
            @include break-lg-up {
                width: 90px;
            }
        }
    }
    &__text {
        @include fontWeight600;
        @include text-smaller;
        color: $color-blue;
    }
}
.bar-w-button-n-icon {
    $gap: 20px;
    position: relative;
    @include border-radius10-20-35;
    @include glow;
    background-color: white;
    display: flex;
    padding: 15px;
    box-shadow: 0px 7px 40px 0px $color-blue-15;
    justify-content: space-between;
    @include break-sm-up {
        padding: 25px;
    }
    
    &--green::before {
        background: $color-green;
        box-shadow: 0px 0px 9em 7em $color-green;
        top: 4em;
        left: 2em;
        opacity: .2;
        @include break-lg-down {
            font-size: 4px;
        }
    }
    &__text {
        color: $color-blue;
        @include fontWeight400;
        @include text-regular;
    }
    &__icon-wrapper {
        flex-shrink: 0;
        width: 15%;
        max-width: 61px;
        margin-left: $gap;
        position: relative;
        img {
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }
    &__content {
        display: grid;
        gap: $gap;
    }
}