.article-top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    @include break-lg-up {
        margin-bottom: 20px;
    }

    &__left  {
        display: flex;
        align-items: center;
        gap: 9px;

        @include break-lg-up {
            gap: 14px;
        }
        @include break-xl-up {
            gap: 26px;
        }
    }
    &__all-news {
        @include fontWeight400;
        @include anchor($color-blue, $color-orange, 0);
        font-size: 16px;
        @include break-lg-down {
            display: none;
        }
    }

    .share-block {
        @include break-lg-up {
            display: none;
        }
    }
}
.article-date {
    color: $color-blue;
    font-size: 9px;
    @include fontWeight600;

    @include break-lg-up {
        font-size: 16px;
    }
    @include break-xl-up {
        font-size: 24px;
    }
}
.article-category {
    background-color: $color-orange;
    color: $color-white;
    font-size: 7px;
    padding: 0.6em 1.3em;
    border-radius: 10px;
    @include transition-regular;
    @include break-lg-up {
        font-size: 13px;
    }
    @include break-xl-up {
        font-size: 16px;
        padding-top: .3em;
        padding-bottom: .3em;
    }
    &:hover {
        background-color: $color-blue;
    }
}
.article {
    color: $color-blue;

    .share-block {
        display: inline-flex;
        margin-left: 15px;
        @include break-lg-down {
            display: none;
        }
        @include break-xl-up {
            margin-left: 25px;
        }
    }
    &__title-wrapper {
        margin-bottom: 10px;
        @include break-lg-up {
            margin-bottom: 35px;
        }
        @include break-xl-up {
            margin-bottom: 45px;
        }
    }
    &__title {
        margin-bottom: 14px;
        @include break-lg-up {
            margin-bottom: 20px;
        }
        @include break-xl-up {
            margin-bottom: 30px;
        }
        &--w-share-block {
            display: inline;
            margin-bottom: 0;
            vertical-align: middle;
            + .share-block {
                vertical-align: middle;
            }
        }
        // @include fontWeight600;
        @extend .h1;
    }
    &__main-image-wrapper {
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 11px;
        padding-top: 62%;
        position: relative;

        @include break-md-up {
            margin-bottom: 30px;
            padding-top: 48%;
        }
        @include break-lg-up {
            margin-bottom: 50px;
        }
        @include break-xl-up {
            border-radius: 50px;
        }

        img {
            object-fit: cover;
            @include abs100;
        }
    }
}

.content {
    color: $color-blue;
    @include text-normal;

    .h1, h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        @include regular-mb;
    }
    p {
        margin-bottom: $content-element-mb;
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        &:not(.file):not(.big-button):not(.button-orange):not(.btn):not(.links__item--primary):not(.button) {
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
    }
    blockquote {
        color: $color-orange;
        font-weight: bold;
        @include fontWeightNormal;
        font-size: 14px;
        @include text-block-margin;
        @include break-sm-up {
            font-size: 20px;
        }
        @include break-lg-up {
            font-size: 30px;
        }
        @include break-xl-up {
            font-size: 40px;
        }
    }
    .float-block {
        margin-bottom: 15px;
        @include border-radius10-15-25;

        @include break-sm-down {
            @include mx-auto;
            width: fit-content;
            text-align: center;
        }
        &--small {
            @include fontWeightNormal;
            color: $color-white;
            background-color: $color-yellow;
            line-height: .8;
            font-weight: bold;
            
            font-size: 18px;
            padding: 15px 20px;
            @include break-sm-up {
                width: $floatBlockWidthSmall;
            }
            @include break-md-up {
                font-size: 24px;
            }
            @include break-lg-up {
                font-size: 36px;
                padding: 30px;
            }
            @include break-xl-up {
                font-size: 50px;
                padding: 40px;
            }
        }
        &--medium {
            border: 1px solid $color-blue;
            color: $color-blue;
            padding: 15px 20px;

            @include break-sm-up {
                width: $floatBlockWidthMedium;
                border-width: 2px;
            }
            @include break-lg-up {
                padding: 20px 30px;
            }
            @include break-xl-up {
                padding: 30px 40px;
            }
        }
        &--big {
            @include fontWeightNormal;
            background-color: $color-orange;
            color: $color-white;
            padding: 15px 20px;
            font-weight: bold;
            font-size: 14px;

            @include break-sm-up {
                font-size: 20px;
                width: $floatBlockWidthBig;
            }
            @include break-lg-up {
                font-size: 30px;
                padding: 30px 40px;
            }
            @include break-xl-up {
                padding: 40px 55px;
                font-size: 40px;
            }
        }
    }
    .framed-small-text {
        border: 1px solid $color-blue-30;
        border-radius: 14px;
        padding: 15px 20px;
        @include text-normal;
        @include text-block-margin;
        @include break-lg-up {
            border-radius: 30px;
            padding: 30px 40px;
        }
        @include break-xl-up {
            border-radius: 50px;
            padding: 50px 60px;
        }
    }
    ol, ul {
        margin-bottom: 20px;
        @include break-md-up {
            margin-bottom: 40px;
        }
        @include break-xl-up {
            margin-bottom: 60px;
        }
        li {
            margin-bottom: 1em;
        }
    }
    &--politics {
        ol, ul {
            margin-bottom: 20px;
        }
    }
    li ul, li ol {
        margin-bottom: 0;
    }
    ul {
        li {
            position: relative;
            padding-left: 1.1em;
            &::before {
                content: '\2022';
                position: absolute;
                top: 0;
                left: 0;
            }
            
            ul {
                margin-top: 1em;
            }
        }
    }
    ol {
        counter-reset: level1;
        li {
            position: relative;
            padding-left: 1.1em;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
            }

            ol {
                margin-top: 1em;
            }
        }
        & > li {
            counter-increment: level1;
            &::before {
                content: counter(level1)". ";
            }
            & > ol {
                counter-reset: level2;
                & > li {
                    counter-increment: level2;
                    &::before {
                        content: counter(level2, lower-alpha)". ";
                    }
                    
                    & > ol {
                        counter-reset: level3;
                        & > li {
                            counter-increment: level3;
                            &::before {
                                content: counter(level3, lower-roman)". ";
                            }
                        }
                    }
                }
            }
        }
    }
    .table-wrapper {
        @include break-xs-down {
            overflow: auto;
        }
    }
    table {
        $radius: 10px;
        $radius--sm: 25px;
        $border-width: 1px;
        $border-width--sm: 2px;

        margin: 20px 0;
        border-collapse: separate;
        color: $color-blue;
        
        @include break-sm-up {
            margin: 30px 0;
        }
        @include break-lg-up {
            margin: 70px 0;
        }
        
        th, td {
            &:first-child {
                padding-left: 15px;
                @include break-sm-up {
                    padding-left: 20px;
                }
                @include break-lg-up {
                    padding-left: 40px;
                }
            }
        }
        th {
            text-align: left;
            padding: 20px 10px;
            background-color: $color-blue;
            @include text-bigger;
            color: $color-white;
            position: relative;
            &:first-child {
                border-top-left-radius: $radius;
                @include break-sm-up {
                    border-top-left-radius: $radius--sm;
                }
            }
            &:last-child {
                border-top-right-radius: $radius;
                @include break-sm-up {
                    border-top-right-radius: $radius--sm;
                }
            }
            @include break-sm-down {
                font-size: 12px;
            }
            @include break-sm-up {
                padding: 20px;
            }
            @include break-lg-up {
                padding: 30px 20px;
            }
            
            &:not(:first-child) {
                &::before {
                    content: '';
                    position: absolute;
                    width: $border-width;
                    height: calc(100% - 20px);
                    background-color: $color-white-30;
                    bottom: 0;
                    left: calc(-1 * $border-width);
                    @include break-sm-up {
                        left: calc(-1 * $border-width--sm);
                        width: $border-width--sm;
                    }
                    @include break-lg-up {
                        height: calc(100% - 30px);
                    }
                }
            }
        }
        td {
            padding: 10px;
            border-bottom: $border-width solid $color-blue-30;
            border-right: $border-width solid $color-blue-30;
            vertical-align: middle;
            &:first-child {
                border-left: $border-width solid $color-blue-30;
            }
            small {
                font-size: .7em;
                display: block;
            }
            @include break-sm-down {
                font-size: 10px;
            }
            @include break-sm-up {
                padding: 20px;                    
                border-bottom-width: $border-width--sm;
                border-right-width: $border-width--sm;
                vertical-align: middle;
                &:first-child {
                    border-left-width: $border-width--sm;
                }
            }
        }
        tr {
            &:hover td {
                background-color: $color-blue-30;
            }
            &:last-child {
                td:first-child {
                    border-bottom-left-radius: $radius;
                    @include break-sm-up {
                        border-bottom-left-radius: $radius--sm
                    }
                }
                td:last-child {
                    border-bottom-right-radius: $radius;
                    @include break-sm-up {
                        border-bottom-right-radius: $radius--sm
                    }
                }
            }
        }
    }
    
}

.img {
    position: relative;
    margin-bottom: 10px;

    @include break-sm-up {
        margin-bottom: 20px;
        &--small {
            width: $floatBlockWidthSmall;
            padding-top: 19.7%;
        }
        &--medium {
            width: $floatBlockWidthMedium;
            padding-top: 28.6%;
        }
        &--big {
            width: $floatBlockWidthBig;
            padding-top: 41.7%;
        }
    }
    @include break-lg-up {
        margin-bottom: 35px;
    }
    @include break-xl-up {
        margin-bottom: 57px;
    }

    img {
        object-fit: cover;
        width: 100%;
        display: block;
        border-radius: 10px;
        @include break-lg-up {
            border-radius: 35px;
        }
        @include break-xl-up {
            border-radius: 50px;
        }
    }
    &--float img {
        @include border-radius10-15-25;
        @include abs100;
        @include break-sm-down {
            position: static;
            height: auto;
        }
    }
    .article-gallery & {
        padding-top: $imageContainerAspectRatio;
        img {
            @include abs100;            
        }
    }
    p {
        @include text-smaller;
        margin-top: 7px;
        padding-left: 15px;
        // @include bre
        @include break-sm-up {
            margin-top: 15px;
        }
        @include break-lg-up {
            padding-left: 60px;
        }
    }
}
.article-tags {
    margin-top: 20px;
    @include break-xl-up {
        margin-top: 40px;
    }
}
.more-articles {
    margin-top: 40px;
    @include break-lg-up {
        margin-top: 60px;
    }
    @include break-xl-up {
        margin-top: 100px;
    }
}

.quotation {
    $gap: 13px;
    $gap--lg: 20px;
    $gap--xl: 30px;
    $imageSize: 61px;
    $imageSize--lg: 138px;
    $imageSize--xl: 233px;
    $padding: 13px;
    $padding--lg: 32px;
    $padding--xl: 40px;
    
    @include card-border;
    @include border-radius15-30-50;
    padding: $padding;
    position: relative;
    margin-bottom: $content-element-mb * 1.7;
    margin-top: $content-element-mb * 1.7;

    @include fontWeight400;

    &--big-text {
        font-size: 12px;
    }
    @include break-sm-up {
        &--big-text {
            font-size: 16px;
        }
    }
    @include break-lg-up {
        padding: $padding--lg;
        margin-bottom: $content-element-mb--lg * 1.7;
        margin-top: $content-element-mb--lg * 1.7;

        &--w-photo {
            // padding-left: $imageSize--lg + $padding--lg + $gap--lg;
            display: flex;
            gap: $gap--lg;
        }
        &--big-text {
            font-size: 20px;
        }
    }
    @include break-xl-up {
        padding: $padding--xl;
        margin-bottom: $content-element-mb--xl * 1.7;
        margin-top: $content-element-mb--xl * 1.7;

        &--w-photo {
            gap: $gap--xl;
        }
        &--big-text {
            font-size: 25px;
        }
    }
    
    &__head {
        display: flex;
        align-items: center;
        gap: $gap;
        margin-bottom: $gap;

        @include break-lg-up {
            margin-bottom: $gap--lg;
        }
        @include break-xl-up {
            margin-bottom: $gap--xl;
        }

        &-image-wrapper {
            max-width: $imageSize;
            height: $imageSize;
            flex: 0 0 $imageSize;
            border-radius: 7px;
            overflow: hidden;

            &--desktop {
                display: none;
            }
            @include break-lg-up {
                &--mob {
                    display: none;
                }
                &--desktop {
                    display: block;
                }
                height: $imageSize--lg;
                flex: 0 0 $imageSize--lg;
                max-width: $imageSize--lg;
                border-radius: 20px;
                /* position: absolute;
                top: $padding--lg;
                left: $padding--lg; */
            }
            @include break-xl-up {
                height: $imageSize--xl - 23px;
                flex: 0 0 $imageSize--xl;
                max-width: $imageSize--xl;
                top: $padding--xl;
                left: $padding--xl;
            }

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }
        &-text-big {
            font-size: 12px;
            margin-bottom: 2px;
            @include fontWeight600;
            @include break-sm-up {
                font-size: 16px;
            }
            @include break-lg-up {
                font-size: 20px;
            }
            @include break-xl-up {
                font-size: 24px;
                margin-bottom: 8px;
            }
        }
        &-text-small {
            font-size: 8px;
            @include break-sm-up {
                font-size: 12px;
            }
            @include break-lg-up {
                font-size: 14px;
            }
            @include break-xl-up {
                font-size: 16px;
            }
        }
    }
    &__body {
        @include text-normal;
        p {
            margin-bottom: $gap;

            @include break-lg-up {
                margin-bottom: $gap--lg;
            }
            @include break-xl-up {
                margin-bottom: $gap--xl;
            }
        }
    }
    &__subtext {
        font-size: 8px;
        @include fontWeight600;
        @include break-sm-up {
            font-size: 12px;
        }
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 20px;
        }
    }
    &--big-text {
        p {
            margin-bottom: 8px;
            @include break-lg-up {
                margin-bottom: 14px;
            }
            @include break-xl-up {
                margin-bottom: 20px;
            }
        }
    }

    @include quote-spike;
}
.comm {    
    $gap: 13px;
    $gap--lg: 20px;
    $gap--xl: 30px;
    $imageSize: 61px;
    $imageSize--lg: 138px;
    $imageSize--xl: 233px;
    $padding: 13px;
    $padding--lg: 32px;
    $padding--xl: 40px;

    position: relative;
    display: flex;
    flex-direction: column;
    @include border-radius15-30-50;
    @include card-border;
    padding: $padding;
    margin: 30px 0;

    @include break-lg-up {
        padding: $padding--lg;
        // padding-left: calc($imageSize--lg + $padding--lg + $gap--lg);
    }
    @include break-xl-up {
        padding: $padding--xl;
        // padding-left: calc($imageSize--xl + $padding--xl + $gap--xl);
    }


    & > p {        
        @include text-normal;
        margin-bottom: $gap;
        @include break-lg-up {
            margin-bottom: $gap--lg;
        }
        @include break-xl-up {
            margin-bottom: $gap--xl;
        }        
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .comm_autor {
        order: -1;
        margin-bottom: $gap;
        font-size: 12px;
        display: grid;
        grid-template-columns: auto 1fr;

        @include break-sm-up {
            font-size: 16px;
        }
        @include break-lg-up {
            margin-bottom: $gap--lg;
            font-size: 20px;
        }
        @include break-xl-up {
            margin-bottom: $gap--xl;
            font-size: 24px;
        }

        img {
            width: $imageSize;
            grid-column: 1 /span 1;
            grid-row: 1 / span 4;
            height: $imageSize;
            object-fit: cover;
            border-radius: 7px;
            margin-right: $gap;

            @include break-lg-up {
                width: $imageSize--lg;
                height: $imageSize--lg;
                border-radius: 20px;
                margin-right: $gap--lg;
            }
            @include break-xl-up {
                width: $imageSize--xl;
                height: $imageSize--xl - 23px;
                margin-right: $gap--xl;
            }
        }

        p {
            margin-bottom: 0;

            &:not(.comm_autor_txt) {
                grid-column: 2/span 1;
                grid-row: 2 /span 1;
                margin-bottom: 2px;
                @include break-xl-up {
                    margin-bottom: 8px;
                }
                strong {
                    @include fontWeight600;
                }
            }
            &.comm_autor_txt { 
                grid-column: 2 /span 1;
                grid-row: 3 /span 1;           
                font-size: 8px;
                @include break-sm-up {
                    font-size: 12px;
                }
                @include break-lg-up {
                    font-size: 14px;
                }
                @include break-xl-up {
                    font-size: 16px;
                }
            }
        }
    }

    @include quote-spike;
}
.article-info {
    font-size: 10px;
    border-top: 1px solid $color-blue-20;
    padding-top: 11px;
    margin-top: 20px;
    margin-bottom: 40px;
    @include break-sm-up {
        font-size: 12px;
    }
    @include break-lg-up {
        font-size: 14px;
        padding-top: 20px;
        margin-top: 30px;
    }
    @include break-xl-up {
        font-size: 19px;
    }
    p {
        margin-bottom: 5px;
    }
    a {
        @include anchor($color-orange, $color-blue, $color-blue-20)
    }
}

.iframe-box {
    margin: 40px 0;
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        @include abs100;
        display: block;
    }
}

.big-link-wrapper {
    margin: 15px 0;
    @include break-md-up {
        margin: 30px 0;
    }
    @include break-lg-up {
        margin: 60px 0;
    }
}
.big-link {
    @include fontWeight600;
    @include text-bigger;
}

.links {
    margin-bottom: $content-element-mb;
    @include break-lg-up {
        margin-bottom: $content-element-mb--lg;
    }
    @include break-xl-up {
        margin-bottom: $content-element-mb--xl;
    }
    &__item {
        @extend .big-link;

        &:hover .txt-wrapper {
            color: $color-blue;
        }

        &--primary {
            position: relative;

            &::before {
                content: '';
                display: inline-block;
                margin-right: .3em;
                width: .8em;
                height: .9em;
                background: url(../images/icons/icon-doc.svg) no-repeat 50% 100%/contain;
            }
        }
    }
    &__desc {
        display: inline;
        .txt-wrapper {
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
    }
}