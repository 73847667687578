.form-control {
    &.error {
        border-color: $color-error !important;
    }
}
.form {
    $gap: 24px;
    @include fontWeight400;
    position: relative;
    max-width: 950px;

    color: $color-blue;
    &__fields {
        display: grid;
        gap: $gap;
    }
    &__title {
        @include widget-header;
        margin-bottom: 20px;
    }
    &__text {
        margin-top: $gap;
        @include text-small;
        p:not(:last-child) {
            margin-bottom: .6em;
        }
        a {
            @include anchor;
        }
        .form-submit-area & {
            margin-top: 0;
        }
    }
    &__sent-message {
        text-align: center;
        @include text-bigger;
        padding: 30px 0;
    }
    &__submit-wrapper {
        position: relative;
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 30px;
    }
    &__error-message {
        color: $color-error;
        @include text-small;
        @include fontWeight600;
    }
}
.form-element {
    @include text-small;
    color: $color-blue;
    display: grid;
    gap: 10px;

    input,
    textarea {
        border-radius: 10px;
        padding: 15px 22px;
        border: 1px solid $color-light-blue40;
        width: 100%;
        @include break-sm-up {
            padding: 18px 22px;
        }

        &:focus {
            border-color: $color-blue;
        }

        &::placeholder {
            color: inherit;
            color: $color-light-blue40;
        }
    }
    textarea {
        height: 100px;
        resize: none;
    }
    &__field-wrapper {
        position: relative;

        &--msgd {
            display: flex;
            align-items: center;
            gap: 5px;

            .form-element__error {
                margin-left: 0;
                @include break-xs-down {
                    position: absolute;
                    bottom: -15px;
                }
            }
        }
        &--input-width-limited {
            input {
                @include break-xs-up {
                    max-width: 145px;
                }
            }
        }
        &--radio::after {
            display: none;
        }
    }

    &.is-error input,
    &.is-error textarea {
        border-color: $color-error;
        color: $color-error;
    }
    &.is-ok input,
    &.is-ok textarea {
        border-color: $color-green;
        color: $color-green;
    }
    &.is-ok &__field-wrapper::after {
        content: '';
        @include abs-valigned;
        width: 15px;
        height: 15px;
        right: 17px;
        background: url(../images/icons/icon-success.svg) no-repeat center / contain;
    }
    &.is-ok &__field-wrapper--input-width-limited::after {
        right: 10px;
        @include break-xs-up {
            left: 120px;
            right: auto;
        }
    }
    &__caption {
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        gap: 5px 15px;

        & > * {
            white-space: nowrap;
        }
        .form-donate & {
            flex-wrap: wrap;
            @include fontWeight400;
            @include break-md-up {
                font-size: 20px;
            }
        }
    }
    &__error {
        color: $color-error;
        margin-left: auto;

        .form-donate & {
            font-size: .8em;
        }
        .donation-custom & {
            position: absolute;
            left: 0;
            top: calc(100% + 3px);
        }
    }
}

.form-section {
    display: grid;
    gap: 17px;
    margin-bottom: 30px;
    @include break-sm-up {
        margin-bottom: 70px;
        gap: 30px;

        .form-donate & {
            gap: 20px;
            margin-bottom: 30px;
        }
    }
}
.form-row {
    display: grid;
    gap: $formElementGap;
    &--2cols {
        grid-template-columns: 1fr 1fr;
    }
    &--to-2 {
        @include break-sm-up {
            grid-template-columns: 1fr 1fr;
        }
    }
    &--to-3 {
        @include break-sm-up {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &--gap-low {
        gap: 10px;
    }
}
.form-block-head {
    color: $color-blue;
    display: flex;
    gap: 5px 25px;
    flex-direction: column;
    @include break-sm-down {        
        margin-bottom: 13px;
        .form-donate & {
            margin-bottom: 0;
        }
    }
    @include break-sm-up {
        flex-direction: row;
        align-items: center;
    }

    &__title {
        font-size: 16px;
        @include fontWeight600;
        @include break-md-up {
            font-size: 24px;
        }
    }
    &__note {
        opacity: .4;
        font-size: 12px;
        @include break-md-up {
            font-size: 16px;
        }
    }
}
.radio-label {
    position: relative;
    border-radius: 15px;
    padding: 15px 13px;
    overflow: hidden;
    border: 1px solid $color-light-blue40;
    cursor: pointer;
    user-select: none;
    margin-right: 4px;
    display: inline-block;
    @include break-xs-up {
        padding: 18px 22px;
    }
    .bg {
        @include abs100;
    }
    &__text {
        position: relative;
    }
    input {
        position: absolute;
        visibility: hidden;
    }
    input:checked ~ .bg {
        background-color: $color-green;
    }
    input:checked ~ &__text {
        color: $color-white;
    }
}
.cb-label {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    gap: 7px;
    user-select: none;
    input {
        position: absolute;
        left: -9999px;
    }
    &__cb {
        width: 18px;
        height: 18px;
        border: 1px solid $color-light-blue40;
        position: relative;
        flex-shrink: 0;

        input:checked ~ &::after {
            content: '';
            margin: 1px auto;
            display: block;
            background-color: $color-green;
            width: 14px;
            height: 14px;
        }
    }
    &__text {
        font-size: 12px;
        @include break-sm-up {
            font-size: 14px;
        }
        @include break-md-up {
            font-size: 16px;
        }
        a {
            @include anchor()
        }
    }
}
.form-submit-area {
    display: flex;
    gap: 26px 36px;
    flex-direction: column;
    margin-top: 40px;
    width: fit-content;
    position: relative;
    @include break-sm-up {
        flex-direction: row;
        margin-top: 70px;
    }
    @include break-xl-up {
        margin-top: 100px;
    }

    &__btn-wrapper {
        position: relative;
        width: fit-content;
        .load-anim-area {
            @include abs-valigned;
            right: 25px;
            .load-anim {
                color: white;
                font-size: 16px;
            }
        }
    }    
}

.form-donate-wrapper {
    @include border-radius15-35-50;
    box-shadow: 0px 10px 40px 0px $color-light-blue15;
    padding: 20px;
    margin-top: 40px;
    @include break-xs-up {
        padding: 30px 40px;
    }
}
.form-donate {
    @include fontWeight400;
    color: $color-blue;
    user-select: none;
    .select-block {
        margin-bottom: 20px;
    }
    
    .form-element {
        input {
            padding: 7px 22px;
            @include fontWeight400;
            @include text-bigger;
        }
    }
    .form-row {
        align-items: flex-start;
    }
    .notetext {
        font-size: 14px;
        @include break-lg-up {
            font-size: 18px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
}
.radio-group-tabbed {
    border-radius: 17px;
    padding: 4px;
    border: 1px solid $color-blue-40;
    @include break-xs-up {
        display: flex;
    }

    &__tab {
        position: relative;
        border-radius: 14px;
        overflow: hidden;
        cursor: pointer;
        padding: 8px;
        display: block;
        @include break-xs-up {
            padding: 15px 10px;
            @include fwidth6;
        }

        input {
            position: absolute;
            left: -9999px;
        }

        .radio-tab-text {
            @include fontWeight600;
            text-align: center;
            color: $color-blue-40;
            position: relative;
            @include text-regular;
        }
            
        input:checked ~ .radio-tab-text {
            color: $color-white;
        }
        .bg {
            @include abs100;
        }

        input:checked ~ .bg {
            background-color: $color-blue;
        }
    }
    
}
.donation-amount {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include break-xs-up {
        flex-direction: row;
        align-items: flex-end;
    }
    @include break-lg-up {
        gap: 5px 22px;
    }
}
.donation-presets {
    display: flex;
    gap: 5px 10px;
    flex-wrap: wrap;
    @include break-lg-up {
        gap: 5px 22px;
    }
}
.donation-preset {
    position: relative;
    overflow: hidden;
    @include text-bigger;
    @include fontWeight400;
    padding: 0.2em 0.4em;
    width: 4.7em;
    cursor: pointer;

    &__text {
        text-align: center;
        color: $color-orange;
        position: relative;
    }
    input:checked ~ &__text {
        color: $color-white;
    }
    input {
        position: absolute;
        left: -9999px;
    }
    .bg {
        @include abs100;
        border: 1px solid $color-orange-40;
        border-radius: 17px;
        @include break-sm-up {
            border-width: 2px;
        }
    }
    input:checked ~ .bg {
        background-color: $color-green;
        border-color: $color-green;
    }
}

.donation-custom {
    max-width: 290px;
}

.form-submit-wrapper {
    position: relative;

    .load-anim-area {
        @include abs-valigned;
        right: 25px;

        .load-anim {
            color: $color-white;
            font-size: 20px;
            @include break-sm-down {
                font-size: 12px;
            }
        }
    }
}

.suggestions-suggestions {
    border-color: $color-light-blue40;
    border-radius: 10px;
    overflow: hidden;
}