.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 990;
    &__logo {
        margin-right: 20px;
        @include break-md-up {
            margin-right: 40px;
            img {
                height: auto;
                width: 95px;
            }
        }
        @include break-xl-up {
            // margin-right: 90px;
            margin-right: 60px;
            img {
                width: 145px;
            }
        }
    }
    &__cta {
        margin-left: auto;
    }
    &__burger-area {
        margin-left: 40px;
        @include break-md-up {
            display: none;
        }
    }
}
.header-content {
    box-shadow: 0px 10px 40px 0px rgba(5, 70, 120, 0.40);
    background-color: $color-blue;
    @include border-radius(0, 20px, 20px, 0);
    position: relative;

    @include break-md-up {
        display: flex;
        padding: 0 $headerGutterBiggerX;
    }
    @include break-lg-up {
        padding: 0 55px;
    }
    @include break-xl-up {
        padding: 0 $headerGutterBiggestX;
    }

    &__top {
        padding: 12px $headerGutterX;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;
        background-color: $color-blue;
        @include border-radius(0, 20px, 20px, 0);
        @include break-md-up {
            flex-shrink: 0;
            padding: $headerGutterX $headerGutterX $headerGutterX 0;
        }
        @include break-xl-up {
            flex-shrink: 0;
            // padding: $headerGutterX 60px $headerGutterX 0;
            padding: $headerGutterX 30px $headerGutterX 0;
        }
    }
}
.menu-area {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 62px;
    padding-bottom: $headerGutterBiggerX;
    width: 100%;
    transform: translateY(calc(-100% - 100px));
    transition-duration: $transition-duration;
    @include border-radius(0, 20px, 20px, 0);
    background-color: $color-blue;
    // min-height: 100vh;
    height: 100vh;
    overflow: auto;

    &.is-active {
        transform: translateY(0px);
    }
    @include break-md-up {
        // min-height: 0;
        height: auto;
        overflow: visible;
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        padding: $headerGutterX 0;
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        gap: $headerGutterX;
        padding: 0 $headerGutterBiggerX;

        @include break-md-up {
            flex-wrap: nowrap;
            padding-right: 0;
        }
    }
    &__cta {        
        @include break-md-up {
            flex-shrink: 0;
        }
    }
}
.menu {
    display: none;
    @include break-md-up {
        display: block;
        padding-left: 20px;
        margin-top: calc($headerGutterX / 2);
        display: flex;
        gap: 5px;
        justify-content: space-between;
    }
    @include break-lg-up {
        padding-left: 15px;
        gap: 10px;
    }
    @include break-xl-up {
        margin-top: $headerGutterX;
    }
}
.menu-item {
    position: relative;
    font-size: 10px;
    @include break-lg-up {
        // font-size: 12px;
        font-size: 14px;
    }
    @include break-xl-up {
        font-size: 16px;
    }
    &__anchor {
        white-space: nowrap;
        font-size: 1em;
        color: $color-white;
        padding: 0.7em 1em;
        display: block;
        // @include transition-regular;
        @include border-radius(10px, 0, 0, 10px);
        &--radius360 {
            @include border-radius(10px);
        }
    }
    &:hover &__anchor {
        color: $color-orange;
        background-color: $color-white;
    }
    &:hover .submenu {
        display: block;
    }
}
.submenu {
    position: absolute;
    left: 0;
    background-color: $color-white;
    padding: .8em 0;
    min-width: calc(100% + 20px);
    @include border-radius(10px, 10px, 10px, 0);
    box-shadow: $box-shadow;
    display: none;

    .menu-item:last-child & {
        @include break-xl-up {
            min-width: 180px;
        }
    }
}
.submenu-item {
    padding: 0.3em 1em;
    &__anchor {
        font-size: 1em;
        color: $color-blue;
        white-space: nowrap;
        @include transition-regular;
        &:hover {
            color: $color-orange;
        }
        .menu-item:last-child & {
            white-space: normal;
        }
    }
    
}

.mob-menu {
    padding: 17px $headerGutterBiggerX $headerMobMenuBuffer $headerGutterBiggerX;
    columns: 2;
    gap: 40px;
    @include break-md-up {
        display: none;
    }
    
    a {
        color: $color-white;
        @include transition-regular;
        &:hover {
            color: $color-orange;
        }
    }
}
.mob-menu-item {
    margin-bottom: 15px;
    break-inside: avoid-column;

    &__anchor {
        font-size: 14px;
        @include fontWeight600;
        border-bottom: 1px solid $color-white-30;
    }
}
.mob-submenu {
    margin-top: 10px;
}
.mob-submenu-item {
    margin-top: 7px;
}

.burger {
    position: relative;
    width: 20px;
    height: 18px;
    transition-duration: $transition-duration;
    transition-delay: 0ms;

    span {
        background-color: $color-white;
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        border-radius: 2px;
        transition-duration: $transition-duration;
        
        &:nth-child(1) {
            top: 8px;
            transition-delay: $transition-duration;
        }
        &:nth-child(2) {
            top: 0px;
            transition-delay: $transition-duration;
        }
        &:nth-child(3) {
            top: 16px;
            transition-delay: $transition-duration;
        }
        &:nth-child(4) {
            height: 0px;
            width: 2px;
            left: 9px;
            right: 9px;
            top: 50% !important;
            transform: translateY(-50%);
            transition-delay: 0ms;
        }
    }
    &.is-active {
        transform: rotate(45deg);
        transition-delay: calc($transition-duration * 2);
    }
    &.is-active span {
        top: 8px;
        transition-delay: 0ms;

        &:nth-child(2),
        &:nth-child(3) {
            opacity: 0;
        }
        &:nth-child(4) {
            height: 20px;
            transition-delay: $transition-duration;
        }
    }
}

.header-search {
    $searhInputHeight: 28px;
    width: calc(100% + $headerGutterX);
    margin-left: calc(-1 * $headerGutterX / 2);
    margin-right: calc(-1 * $headerGutterX / 2);
    font-family: 'Museo Sans Cyrl 500';
    position: relative;
    
    @include break-md-up {
        margin: 0;
        height: $searhInputHeight;
    }

    .search-icon {
        position: absolute;
        left: calc($headerGutterX / 2);
        top: 50%;
        transform: translateY(calc(-50% + 1px));
        pointer-events: none;
    }
    &__input-wrapper {
        position: relative;
    }
    &__input {
        background-color: $color-white;
        border-radius: 23px;
        height: $searhInputHeight;
        width: 100%;
        padding: 0 $headerInputGutter*2 0 $headerInputGutter;
        font-size: 12px;
        color: $color-blue;
        &:focus + .search-icon,
        &.not-empty + .search-icon {
            left: auto;
            right: calc($headerGutterX / 2);
            pointer-events: all;
            cursor: pointer;
            @include break-md-up {
                height: 21px;
                width: 21px;
            }
        }
        @include break-md-up {
            width: 54px;
            font-family: 'Museo Sans Cyrl';
            font-size: 24px;
            @include transition-regular;
            &:focus,
            &.not-empty {
                width: 100%;
                height: 42px;
            }
        }
    }
    &__results-wrapper {
        display: none;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        background-color: $color-white;
        box-shadow: $box-shadow;
        @include border-radius(0, 14px, 14px, 0);
        padding: 10px $headerInputGutter;
    }
    &.is-active &__input {
        @include border-radius(14px, 0, 0, 14px)
    }
    &.is-active &__results-wrapper {
        display: block;
    }
}
.search-results-list {
    li {
        &:not(:last-child) {
            margin-bottom: 1em;
        }
        a {
            color: $color-blue;
            font-size: 12px;
            @include transition-regular;
            &:hover {
                color: $color-orange;
            }
            @include break-xl-up {
                font-size: 19px;
            }
        }
    }
}
.header-city-picker {
    /* padding-left: $headerGutterBiggerX;
    padding-top: $headerGutterBiggerX; */
    @include break-md-up {
        order: -1;
        flex-shrink: 0;
    }
}
.city-pick-btn {
    display: inline-flex;
    align-items: flex-end;
    gap: 5px;
    padding-bottom: .4em;
    border-bottom: 1px solid $color-white-30;
    color: $color-white;
    font-size: 8px;

    @include break-md-up {
        font-size: 10px;
    }
    @include break-xl-up {
        font-size: 14px;
    }

    &__icon {
        height: 1.2em;
        width: auto;
    }
    &__text {
        @include transition-regular;
    }
    &:hover &__text {
        color: $color-orange;
    }
}
.header-socials {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 150px;
    padding: 0 $headerGutterBiggerX;
    // margin-top: calc(-1 * $headerMobMenuBuffer * 2 + 2px);
    @include break-md-up {
        display: none;
    }
}