@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
    @font-face {
    font-family: $font-family;
    src:
        url('#{$file-path}.woff2') format('woff2'),
        url('#{$file-path}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    }
}

@mixin orientation-portrait {
    @media screen and (orientation:portrait) {
        @content;
    }
}

@mixin break-xxs-up {
    @media screen and (min-width: 360px) {
        @content;
    }
}
@mixin break-xs-up {
    @media screen and (min-width: 512px) {
        @content;
    }
}
@mixin break-sm-up {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin break-md-up {
    @media screen and (min-width: 1024px) {
        @content;
    }
}
@mixin break-lg-up {
    @media screen and (min-width: 1280px) {
        @content;
    }
}
@mixin break-xl-up {
    @media screen and (min-width: 1600px) {
        @content;
    }
}
@mixin break-xxl-up {
    @media screen and (min-width: 1680px) {
        @content;
    }
}
@mixin break-xs-down {
    @media screen and (max-width: 511px) {
        @content;
    }
}
@mixin break-sm-down {
    @media screen and (max-width: 767px) {
        @content;
    }
}
@mixin break-md-down {
    @media screen and (max-width: 1023px) {
        @content;
    }
}
@mixin break-lg-down {
    @media screen and (max-width: 1279px) {
        @content;
    }
}
@mixin break-xl-down {
    @media screen and (max-width: 1599px) {
        @content;
    }
}

// grid settings
@mixin fwidth1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
}
@mixin fwidth2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
}
@mixin fwidth3 {
    flex: 0 0 25%;
    max-width: 25%;
}
@mixin fwidth4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
@mixin fwidth5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
}
@mixin fwidth6 {
    flex: 0 0 50%;
    max-width: 50%;
}
@mixin fwidth7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
}
@mixin fwidth8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
}
@mixin fwidth9 {
    flex: 0 0 75%;
    max-width: 75%;
}
@mixin fwidth10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
}
@mixin fwidth11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
}
@mixin fwidth12 {
    flex: 0 0 100%;
    max-width: 100%;
}
@mixin offset1 {
    margin-left: 8.33%;
}
@mixin offset2 {
    margin-left: 16.66%;
}
@mixin offset3 {
    margin-left: 25%;
}
@mixin offset4 {
    margin-left: 33.33%;
}
@mixin offset5 {
    margin-left: 41.66%;
}
@mixin offset6 {
    margin-left: 50%;
}
@mixin offset7 {
    margin-left: 58.33%;
}
@mixin offset8 {
    margin-left: 66.66%;
}
@mixin offset9 {
    margin-left: 75%;
}
@mixin offset10 {
    margin-left: 83.33%;
}
@mixin offset12 {
    margin-left: 91.66%;
}

@mixin overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    z-index: 1;
}
@mixin abs100 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin abs-aligned {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin abs-valigned {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
@mixin fontWeightNormal {
    font-family: 'Museo Sans Cyrl', Helvetica, Arial, sans-serif;
}
@mixin fontWeight400 {
    font-family: 'Museo Sans Cyrl 500', Helvetica, Arial, sans-serif;;
}
@mixin fontWeight600 {
    font-family: 'Museo Sans Cyrl 700', Helvetica, Arial, sans-serif;;
}
@mixin underline($width: 1px, $color: $color-blue-30) {
    border-bottom: $width solid $color;
}
@mixin anchor($color: $color-orange, $hover-color: $color-blue, $border-color: $color-blue-20) {
    @include transition-regular();
    @if $border-color != 0 {
        border-bottom: 1px solid $border-color;
    }
    color: $color;
    &:hover {
        color: $hover-color;
    }
}
@mixin article-element-mb {
    margin-bottom: $content-element-mb;
    @include break-lg-up() {
        margin-bottom: $content-element-mb--lg;
    }
    @include break-xl-up() {
        margin-bottom: $content-element-mb--xl;
    }
}
@mixin block-title {
    font-weight: bold;
    font-size: 16px;
    @include break-md-up() {
        font-size: 28px;
    }
    @include break-lg-up() {
        font-size: 40px;
    }
    @include break-xl-up() {
        font-size: 64px;
    }
}
@mixin block-title-bigger-start {
    font-weight: bold;
    font-size: 20px;
    @include break-md-up() {
        font-size: 28px;
    }
    @include break-lg-up() {
        font-size: 40px;
    }
    @include break-xl-up() {
        font-size: 64px;
    }
}
@mixin block-title-smaller {
    font-weight: bold;
    font-size: 16px;
    @include break-md-up() {
        font-size: 28px;
    }
    @include break-lg-up() {
        font-size: 40px;
    }
}
@mixin block-text-big {
    font-weight: bold;
    font-size: 16px;
    @include break-md-up() {
        font-size: 24px;
    }
    @include break-lg-up() {
        font-size: 30px;
    }
    @include break-xl-up() {
        font-size: 40px;
    }
}
@mixin block-extra-padding {
    padding-left: 20px;
    padding-right: 20px;
    @include break-md-up() {
        padding-left: 55px;
        padding-right: 55px;
    }
}
@mixin mx-auto {
    margin-left: auto;
    margin-right: auto;
}
@mixin valigned {
    display: inline-block;
    vertical-align: middle;
}
@mixin flex-centered {    
    display: flex;
    align-items: center;
    justify-content: center;
}

// transitions
@mixin transition-regular {
    transition-timing-function: ease-out;
    transition-duration: $transition-duration;
}

@mixin border($side, $width, $color, $style: solid) {
    @if $side == all {
        border: {
            width: $width;
            color: $color;
            style: $style;
        }
    } @else {
        border-#{$side}: {
            width: $width;
            color: $color;
            style: $style;
        }
    }    
}
@mixin border-radius($top-right, $bottom-right: $top-right, $bottom-left: $top-right, $top-left: $top-right) {
    border-top-right-radius: $top-right;
    border-bottom-right-radius: $bottom-right;
    border-bottom-left-radius: $bottom-left;
    border-top-left-radius: $top-left;
}

@mixin button-height {
    // height: 26px;
    height: 34px;
    /* @include break-md-up {
        height: 34px;
    } */
    @include break-xl-up {
        height: 53px;
    }
}
@mixin button {
    @include border-radius(7px);
    @include button-height();
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    text-align: left;
    font-size: 12px;
    // padding: 0.6em 1em;
    padding: 0 1em;

    /* @include break-md-up {
        font-size: 12px;
    }
    @include break-lg-up {
        font-size: 12px;
    } */
    @include break-xl-up {
        @include border-radius(16px);
        gap: 18px;
        font-size: 16px;
        // padding: 0.9em 1.6em;
        // padding: 0 1.6em;
        svg {
            width: 25px;
            height: auto;
        }
    }
}

@mixin ul {
    li {
        position: relative;
        padding-left: 1.1em;
        &:not(:last-child) {
            margin-bottom: 1em;
        }
        &::before {
            content: '\2022';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
@mixin section-margin-bottom {    
    margin-bottom: 40px;
    @include break-md-up {
        margin-bottom: 70px;
    }
    @include break-xl-up {
        margin-bottom: 130px;
    }
}

@mixin border-radius10-15-25 {
    border-radius: 10px;
    @include break-lg-up() {
        border-radius: 15px;
    }
    @include break-xl-up() {
        border-radius: 25px;
    }
}
@mixin border-radius10-20-30 {
    border-radius: 10px !important;
    @include break-lg-up() {
        border-radius: 20px !important;
    }
    @include break-xl-up() {
        border-radius: 30px !important;
    }
}
@mixin border-radius10-20-35 {
    border-radius: 10px;
    @include break-lg-up() {
        border-radius: 20px;
    }
    @include break-xl-up() {
        border-radius: 30px;
    }
}
@mixin border-radius15-30-40 {
    border-radius: 15px;
    @include break-lg-up() {
        border-radius: 30px;
    }
    @include break-xl-up() {
        border-radius: 40px;
    }
}
@mixin border-radius15-35-50 {
    border-radius: 15px;
    @include break-lg-up() {
        border-radius: 35px;
    }
    @include break-xl-up() {
        border-radius: 50px;
    }
}
@mixin border-radius15-30-50 {
    border-radius: 15px;
    @include break-lg-up() {
        border-radius: 30px;
    }
    @include break-xl-up() {
        border-radius: 50px;
    }
}
@mixin widget-regular($top-xl-smaller: false, $radius-lg-smaller: false) {
    @include border-radius15-35-50;
    @if $radius-lg-smaller {
        @include border-radius15-30-50;
    }
    padding: 20px;

    @include break-lg-up() {
        padding: 35px;
    }
    @include break-xl-up() {
        padding: 60px;
        @if $top-xl-smaller {
            padding-top: 40px;
        }   
    }
}
@mixin widget-header {
    font-size: 24px;
    font-weight: bold;
    @include break-lg-up {
        font-size: 30px;
    }
    @include break-xl-up {
        font-size: 40px;
        margin-bottom: 30px;
    }
}
@mixin widget-title {
    font-size: 16px;
    @include break-md-up {
        font-size: 20px;
    }
    @include break-lg-up {
        font-size: 30px;
    }
    @include break-xl-up {
        font-size: 40px;
    }
}

@mixin slider-button($height, $heightMd, $heightXl, $reverse: false) {
    color: $color-white;
    display: flex;
    align-items: center;
    background-color: $color-orange;
    justify-content: center;
    height: $height;
    width: calc($height / 2);

    @if $reverse {
        border-radius: 0 $height*0.5 $height*0.5 0;
        svg {
            left: -10%;
        }
    } @else {
        border-radius: $height*0.5 0 0 $height*0.5;
        svg {
            left: 10%;
        }
    }

    svg {
        position: relative;
    }
    
    @include transition-regular;
    @include break-md-up {
        height: $heightMd;
        width: calc($heightMd / 2);
        @if $reverse {
            border-radius: 0 $heightMd*0.5 $heightMd*0.5 0;
        } @else {
            border-radius: $heightMd*0.5 0 0 $heightMd*0.5;
        }
    }
    @include break-xl-up {
        height: $heightXl;
        width: calc($heightXl / 2);
        @if $reverse {
            border-radius: 0 $heightXl*0.5 $heightXl*0.5 0;
        } @else {
            border-radius: $heightXl*0.5 0 0 $heightXl*0.5;
        }
    }

    &:not(.no-hover):hover {
        color: $color-orange;
        background-color: $color-white;
    }
}
@mixin regular-mb {    
    margin-bottom: 15px;
    @include break-md-up {
        margin-bottom: 30px;
    }
}
@mixin mb-40 {
    margin-bottom: 25px;
    @include break-lg-up() {
        margin-bottom: 40px;
    }
}
@mixin mb-70 {
    margin-bottom: 35px;
    @include break-lg-up() {
        margin-bottom: 70px;
    }
}
@mixin text-small {
    font-size: 12px;
    @include break-lg-up {
        font-size: 16px;
    }
}
@mixin text-normal {
    font-size: 12px;
    @include fontWeight400;

    @include break-sm-up {
        font-size: 14px;
    }
    @include break-lg-up {
        font-size: 18px;
    }
    @include break-xl-up {
        // font-size: 24px;
        font-size: 20px;
    }
}
@mixin text-regular {
    font-size: 12px;
    @include break-lg-up {
        font-size: 18px;
    }
    @include break-xl-up {
        font-size: 24px;
    }
}
@mixin text-smaller {
    font-size: 10px;
    @include break-sm-up {
        font-size: 12px;
    }
    @include break-lg-up {
        font-size: 14px;
    }
    @include break-xl-up {
        font-size: 19px;
    }
}
@mixin text-bigger {
    font-size: 14px;
    @include break-sm-up {
        font-size: 16px;
    }
    @include break-lg-up {
        font-size: 20px;
    }
    @include break-xl-up {
        font-size: 30px;
    }
}
@mixin text-block-margin {
    margin: 20px 0;
    @include break-sm-up {
        margin: 30px 0;
    }
    @include break-xl-up {
        margin: 70px 0;
    }
}
@mixin no-photo {
    width: 50%;
    height: auto;
    @include abs-aligned;
}
@mixin card-border {
    border: 1px solid $color-blue;
    @include break-lg-up() {
        border-width: 2px;
    }
}
@mixin quote-spike {
    &::before, &::after {
        content: '';
        position: absolute;
        font-size: 10px;
        @include break-lg-up {
            font-size: 17px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
    &::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 1.8em 1.2em 0;
        border-color: transparent $color-blue transparent transparent;
        top: 100%;
        right: 25px;
        @include break-lg-up {
            right: 55px;
        }
        @include break-xl-up {
            right: 85px;
        }
    }
    &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 1.6em 1.1em 0;
        border-color: transparent $color-white transparent transparent;
        top: 100%;
        right: 26px;
        @include break-lg-up {
            right: 56px;
        }
        @include break-xl-up {
            right: 86px;
        }
    }
}
@mixin floatLeft {
    @include break-sm-up {
        float: left;
        margin: 10px 20px 20px 0;
    }
}
@mixin floatRight {
    @include break-sm-up {
        float: right;
        margin: 10px 0 20px 20px;
    }
}
@mixin section-margin-y {
    margin-top: 35px;
    margin-bottom: 35px;

    @include break-md-up {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    @include break-xl-up {
        margin-top: 90px;
        margin-bottom: 90px;
    }
}

@mixin glow {
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 1px;
        height: 1px;
        border-radius: 50%;
        opacity: 0.5;
        
        @include break-lg-down {
            font-size: 7px;
        }
    }
}

@mixin widget-regular($color-bg: $color-orange, $color-text: $color-white) {
    @include border-radius15-35-50;
    color: $color-text;
    background-color: $color-bg;
    padding: 23px;
    position: relative;
    @include glow;
    &::before {
        background: $color-bg;
        box-shadow: 0px 0px 10em 7em $color-bg;
        top: 5.5em;
        left: 3.8em
    }
    @include break-lg-up {
        padding: 34px;
    }
    @include break-xl-up {
        padding: 53px 60px 60px;
    }
}