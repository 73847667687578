.projects-grid {
    display: grid;
    gap: 10px;
    margin-top: 20px;

    @include break-sm-up {
        grid-template-columns: repeat(2, minmax(0,1fr));
        margin-top: 40px;
        gap: 20px;
    }
    @include break-lg-up {
        gap: 45px 20px;
    }
}
.project-card {
    $padding: 20px;
    $padding--md: 40px;
    $padding--xl: 60px;
    position: relative;
    background-color: $color-white;
    @include border-radius15-35-50;
    box-shadow: 0px 7px 40px 0px $color-blue-15;
    padding: $padding;
    color: $color-blue;

    @include break-sm-up {
        display: flex;
        flex-direction: column;
    }
    @include break-md-up {
        padding: $padding--md;
    }
    @include break-xl-up {
        padding: $padding--xl;
    }

    @include glow;
    &::before {
        background: $color-orange;
        box-shadow: 0 0 9em 8em $color-orange;
        top: 8.5em;
        left: 7.8em;
        opacity: .3;
    }

    &__title {
        @include widget-title;
        @include fontWeight600;
        color: $color-orange;
        margin-bottom: 1em;

        a {
            @include anchor($color-orange, $color-blue, $color-blue-30);
        }
    }
    &__big-text {
        @include fontWeight400;
        @include text-bigger;
    }
    &__bottom {
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        @include break-sm-up {
            padding-top: 25px;
            flex-grow: 1;
        }
    }
    &__small-text {
        @include fontWeight400;
        @include text-smaller;
        margin-bottom: 20px;
        @include break-sm-up {
            margin-bottom: 35px;
        }
    }
    &__button-wrapper {
        position: absolute;
        bottom: $padding;
        @include break-md-up {
            bottom: $padding--md;
        }
        @include break-xl-up {
            bottom: $padding--xl;
        }

        .button {
            width: fit-content;
            justify-content: center;
            @include fontWeight400;
            @include break-xs-up {
                min-width: 181px;
            }
        }
    }
    &__bottom-content {
        padding-bottom: 55px;
    }
    &__bottom-image {
        $padding: 15px;
        flex: 0 0 35%;
        max-width: 35%;
        position: relative;
        padding-left: $padding;
        margin-top: auto;

        @include break-sm-up {
            flex: 0 0 30%;
            max-width: 30%;
        }

        img {
            max-width: calc(100% - $padding);
            max-height: 100%;

            @include break-sm-down {
                max-height: 100px;
                display: block;
                margin-left: auto;
            }
        }
    }

    &--prodcard &__bottom {
        flex-direction: column;
        @include break-xs-up {
            flex-direction: row;
            align-items: flex-end;
        }
    }
    &--prodcard &__bottom-image {
        max-width: 48.8%;
        flex: 0 0 48.8%;
        margin-left: auto;
        margin-bottom: -10px;
        order: -1;

        @include break-xs-up {
            margin-bottom: -50px;
            order: 0;
        }

        img {
            max-height: none;
            bottom: auto;
            position: relative;
            right: 0px;
            max-width: 130px;
            margin-left: auto;
            display: block;
            top: 0;
            width: calc(100% - $padding);

            @include break-xs-up {
                top: -30px;
            }
            @include break-sm-up {
                max-width: none;
            }
            @include break-md-up {
                right: -35px;
                top: -15px;
            }
            @include break-xl-up {
                top: 0;
                right: -50px;
            }
        }
    }
    &--prodcard &__button-wrapper,
    &--profoodsharing &__button-wrapper {
        .button {
            @include break-xl-up {
                min-width: 262px;
            }
        }
    }
    &--prodcard .button {
        color: $color-white;
        background-color: $color-orange;
        &:hover {
            color: $color-orange;
            background-color: transparent;
        }
    }
    &--profoodsharing &__bottom-content {
        @include break-xs-down {
            padding-bottom: 0;
        }
    }
    &--profoodsharing &__button-wrapper {
        @include break-xs-down {
            position: static;
        }
    }
    &--profoodsharing .button {
        background-color: $color-light-green;
        color: $color-white;

        &:hover {
            color: $color-light-green;
            border: inset 1px solid;
            background-color: transparent;
        }
    }
    &--profoodsharing &__title {
        color: $color-light-green;
    }
    &--profoodsharing &__bottom {
        flex-wrap: wrap;
        gap: 10px;
        @include break-md-up {
            flex-wrap: nowrap;
        }
    }
    &--profoodsharing &__bottom-image {
        flex: 0 0 42%;
        max-width: 42%;
        margin-left: auto;
        img {
            position: static;
        }
    }
}

.card-projects {
    @include border-radius15-35-50;
    @include card-border;
    padding: 20px;
    background-color: $color-white;
    position: relative;
    color: $color-blue;

    @include break-lg-up {
        padding: 37px;
    }
    @include glow;
    &::before {
        background: $color-blue;
        box-shadow: 0px 0px 9em 5em $color-blue;
        top: 4em;
        left: 2em;
        opacity: .2;

        @include break-lg-down {
            font-size: 4px;
        }
    }

    &__image-wrapper {
        height: 50px;
        margin-bottom: 9px;

        @include break-md-up {
            margin-bottom: 24px;
        }
        @include break-lg-up {
            height: 100px;
        }

        img {
            height: 100%;
            width: auto;
        }
    }
    &__big-text {
        margin-bottom: 0;
        font-size: 12px;
        
        @include break-md-up {
            font-size: 14px;
        }
        @include break-lg-up {
            font-size: 20px;
        }
        @include break-xl-up {
            font-size: 25px;
        }

        &--smaller {
            @include break-xl-up {
                font-size: 24px;
            }
        }
    }
    &__small-text {
        @include text-smaller;
        @include fontWeight400;
        margin-top: 1.5em;

        &--smaller {
            margin-top: 1em;
            @include text-small();
            @include break-xs-down {
                font-size: 8px;
            }
            ul {
                @include ul();
            }
        }
    }
    &__line {
        position: absolute;
        z-index: -1;
        background: url(../images/dash.svg) repeat-x 0 center;
        width: 50px;
        height: 1px;
        left: 50%;
        margin-left: -25px;
        top: calc(100% - -8px);
        transform: rotate(90deg);

        &:not(&--vert) {
            @include break-sm-up {
                left: calc(100% - 14px);
                transform: none;
                top: 50%;
                margin-top: -1px;
                margin-left: 0;
            }
        }
        @include break-xs-up {
            height: 2px;
        }
        
    }
    
    &__label {
        font-size: 8px;
        color: $color-white;
        border-radius: 23px;
        position: absolute;
        padding: 0.4em 1.7em;
        right: 8px;
        top: 8px;
        @include fontWeight600;

        &--green {
            background-color: $color-green;
        }
        &--red {
            background-color: $color-red;
        }

        @include break-sm-up {
            right: 20px;
            top: 20px;
            font-size: 10px;
        }
        @include break-lg-up {
            font-size: 14px;
            right: 30px;
            top: 30px;
        }
    }
}
.fooddrive-scheme {

    .card-projects {
        @include break-xs-down {
            padding-left: 11px;
            padding-right: 11px;
        }
    }
    &__step1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        gap: 18px;
        position: relative;

        @include break-sm-up {
            gap: 36px;
        }
        @include break-lg-up {
            gap: 60px;
        }

        .card-projects {
            &:nth-of-type(2) {
                position: absolute;
                width: calc(50% - 30px);
                right: 0;
                bottom: 114px;
                @include break-lg-down {
                    bottom: 80px;
                }
                @include break-sm-down {
                    width: calc(50% - 18px);
                    bottom: 55px;
                }

            }
            @include break-xl-up {
                padding-left: 60px;
            }
        }
    }

    &__step-next {
        margin-top: 60px;
        @include break-lg-up {
            margin-top: 94px;
        }
        .card-projects {
            max-width: 150px;
            @include mx-auto;

            @include break-xs-up {
                max-width: 200px;
            }
            @include break-lg-up {
                max-width: 337px;
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}

.line-step1 {
    position: absolute;
    top: calc(100% - 114px);
    left: 50%;
    transform: translateX(-50%);
    width: 392px;

    &--small {
        display: none;
    }

    @include break-lg-down {
        top: calc(100% - 124px);
        width: 340px;
    }
    @include break-xs-down {
        top: calc(100% - 86px);
        width: 250px;
        &--small {
            display: block;
        }
        &--big {
            display: none;
        }
    }
}