.pagination {
    $gap: 17px;
    $anchorFs: 12px;
    $anchorFsSmUp: 12px;
    $anchorFsBigger: 16px;

    display: flex;
    align-items: center;
    gap: $gap;

    &__button {
        color: $color-orange;
        &--prev {
            transform: scaleX(-1);
        }
        &:hover {
            color: $color-blue;
        }

        svg {
            @include break-sm-up {
                width: 18px;
                height: 16px;
            }
        }
    }
    &__anchors {
        display: flex;
        gap: $gap;
        font-size: $anchorFs;
        color: $color-blue;

        @include break-sm-up {
            font-size: $anchorFsBigger;
        }
    }
    &__element {
        @include fontWeight400;
        &--skip {
            font-size: 16px;
            line-height: $anchorFs;
            
            @include break-sm-up {
                line-height: $anchorFsBigger;
            }
        }
        a {
            @include anchor($color-orange, $color-blue, $color-blue-30)
        }
    }
}