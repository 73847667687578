.line-wrapper {
    position: absolute;
    pointer-events: none;
    
    .line {
        position: relative;    
        z-index: 1;

        &:first-of-type {        
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        
        svg {
            width: 100%;
            height: auto;
        }
        
        &--from-slider-mob {
            left: -5% !important;
            @include break-xxs-up {
                left: 0 !important;
            }
        }
    }

    &--desktop {
        display: none;
        @include break-sm-up {
            display: block;
        }
    }
    &--mob {
        @include break-sm-up {
            display: none;
        }
    }
    &--from-slider {
        top: -164px;
        left: 40px;
    }
    &--from-slider-desktop {
        width: 560px;
        @include break-md-up {
            width: 70%;
            top: -165px;
            left: 150px;
        }
        @include break-lg-up {
            width: 62%;
            top: -200px;
        }
        @include break-xl-up {
            left: 200px;
            top: -260px;
            width: 60%;
        }
    }
    &--from-sidebar-mob {
        // top: 55%; use when events count = 4
        top: 65%;
        left: 16%;
        width: 82%;
        height: auto;
        max-width: 280px;
        @include break-xs-up {
            left: 15%;
            // top: 61%; use when events count = 4
            top: 66%;
        }
        @include break-sm-up {
            left: 39%;
            top: 57%;
            width: 82%;
        }
        @include break-md-up {
            display: none;
        }
    }
    &--from-sidebar-desktop {
        display: none;
        @include break-md-up {
            display: block;
            width: 25%;
            top: 65%;
            left: 64%;
        }
        @include break-lg-up {
            left: 51%;
        }
    }
    &--from-tabs-mob {
        left: 7%;
        top: 65%;
        width: 56%;
        max-width: 180px;
        @include break-sm-up {
            display: none;
        }
    }
    &--from-tabs-desktop {
        display: none;
        @include break-sm-up {
            display: block;
            left: 14%;
            width: 41%;
            bottom: -130px;
        }
        @include break-lg-up {
            bottom: -160px;
        }
        @include break-xl-up {
            width: 45%;
            left: 11%;
            bottom: -240px;
        }
    }
}
.line-glow {
    position: absolute;
    z-index: 2;
    height: auto;

    &--1-mob {
        // top: 43%;
        top: 88%;
        right: -11%;
        right: 0;
        width: 108px;
        
        right: 0;
        @include break-xxs-up {
            right: -11%;
        }
        @include break-sm-up {
            display: none;
        }
    }
    &--1-desktop {
        display: none;
        @include break-sm-up {
            display: block;
            right: -10%;
            top: 61%;
            width: 130px;
        }
        @include break-md-up {
            width: 25%;
        }
    }
    &--2-mob {
        left: 29%;
        bottom: -7%;
        width: 70%;
        @include break-lg-up {
            left: 51%;
            bottom: 26%;
            width: 80%;
        }
        @include break-xl-up {
            bottom: -2%;
            left: 32%;
        }
    }
    &--3-mob {
        bottom: -34%;
        left: 75%;
        width: 40%;
        @include break-lg-up {
            bottom: -48%;
            left: 74%;
            width: 40%;
        }
        @include break-xl-up {
            bottom: -32%;
            left: 75%;
        }
    }
}