.link-tabs-section {
    margin-bottom: 15px;

    @include break-lg-up {
        margin-bottom: 26px;
    }
    @include break-xl-up {
        margin-bottom: 42px;
    }
}
.news-tags {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px 3px;
    font-size: 7px;

    @include break-md-up {
        font-size: 10px;
    }
    @include break-lg-up {
        font-size: 13px;
        gap: 7px;
        margin-bottom: 26px;
    }
    @include break-xl-up {
        font-size: 19px;
        gap: 10px;
        margin-bottom: 42px;
    }
}
.show-more-tags {
    @include anchor($color-orange, $color-blue, 0);
    text-align: left;
    margin-left: 8px;

    &__text {
        @include underline;
    }
}
.news-list {
    display: grid;
    gap: 12px;
    margin-bottom: 48px;

    @include break-sm-up {
        grid-template-columns: 1fr 1fr;
    }
    @include break-md-up {
        gap: 26px 38px;
        margin-bottom: 65px;
    }
    @include break-xl-up {        
        gap: 40px 60px;
        margin-bottom: 90px;
    }
}
