.dialog-bubble {
    position: relative;
    width: fit-content;
    flex-shrink: 0;
    z-index: 2;

    &__text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 1em 1.4em;
    }
    &--double {
        color: $color-orange;
        font-size: 14px;

        @include break-lg-up {
            font-size: 20px;
        }
        @include break-xl-up {
            font-size: 30px;
        }

        img {
            width: 12.85em;
        }
    }
}

.breadcrumbs-section {
    margin: 18px 0 12px;

    @include break-md-up {
        margin: 22px 0;
    }
    @include break-lg-up {
        margin: 34px 0 42px;
    }
}
.breadcrumbs {
    font-size: 10px;
    @include fontWeight400;

    @include break-md-up {
        font-size: 14px;
    }
    @include break-lg-up {
        font-size: 16px;
    }
    
    &__item {
        display: inline;
        font-size: 1em;
        color: $color-blue;
        line-height: 1.4;

        a {
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
        &:not(:last-child)::after {
            content: '/';
            display: inline-block;
            margin: 0 .5em;
        }
    }
}

.link-tabs {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 17px;

    @include break-md-up {
        font-size: 30px;
        gap: 5px 45px;
    }
    @include break-lg-up {
        font-size: 40px;
        gap: 10px 60px;
    }

    &__item {
        // display: inline;
        font-weight: bold;
        font-size: 1em;
        color: $color-blue;
        cursor: default;
        user-select: none;

        a, &:not(.is-active) > span {
            @include anchor($color-orange, $color-blue, $color-blue-20);
            cursor: pointer;
        }

        &--opacity30 {
            a {
                color: $color-blue-30;
            }
        }
    }
}

.refresh-button {
    font-size: 12px;
    color: $color-orange;
    @include transition-regular;
    display: flex;
    align-items: center;
    gap: 8px;
    @include fontWeight400;

    @include break-sm-up {
        font-size: 16px;
        gap: 14px;
    }

    &:hover {
        color: $color-blue;
    }

    &__icon {
        @include break-sm-up {
            width: 29px;
            height: 29px;
        }
    }
    
    &.is-loading {
        pointer-events: none;
        animation: none;
    }
    .is-loading &__icon {
        animation: spin 1.5s linear infinite;
    }
}

.share-block {
    $size: 16px;
    $size--lg: 24px;
    $size--xl: 34px;
    $gap: 5px;
    $gap--lg: 8px;
    $gap--xl: 11px;

    position: relative;
    display: flex;

    &__icon {
        position: relative;
        z-index: 1;
        width: $size;
        height: $size;
        border-radius: calc($size / 2);
        background-color: $color-orange;
        display: flex;
        align-items: center;
        justify-content: center;

        @include break-lg-up {
            width: $size--lg;
            height: $size--lg;
            border-radius: calc($size--lg / 2);
        }
        @include break-xl-up {
            width: $size--xl;
            height: $size--xl;
            border-radius: calc($size--xl / 2);
        }

        img {
            transform: translateX(-5%);
            @include break-lg-up {
                height: auto;
                width: 13px;
            }
            @include break-xl-up {
                width: 18px;
            }
        }
    }
    &__links {
        width: 0;
        overflow: hidden;
        left: 0;
        position: absolute;
        top: 0;
        @include transition-regular;
    }
    &__links-list {
        background-color: $color-blue;
        display: flex;
        align-items: center;
        gap: $gap;
        border-radius: calc($size / 2);
        padding-left: $size + $gap;
        height: $size;

        @include break-lg-up {
            gap: $gap--lg;
            border-radius: calc($size--lg / 2);
            padding-left: $size--lg + $gap--lg;
            height: $size--lg;
        }
        @include break-xl-up {
            gap: $gap--xl;
            border-radius: calc($size--xl / 2);
            padding-left: $size--xl + $gap--xl;
            height: $size--xl;
        }
    }
    &__link {
        flex-shrink: 0;
        @include transition-regular;

        &:hover {
            opacity: .7;
        }

        img {
            display: block;
        }

        @include break-lg-up {
            img {
                height: auto;
            }
            &--vk img {
                width: 18px;
            }
            &--ok img {
                width: 9px;
            }
        }
        @include break-xl-up {            
            &--vk img {
                width: 25px;
            }
            &--ok img {
                width: 12px;
            }
        }
    }
}

.accordeon-block {
    @include article-element-mb;
}
.accordeon-block-element {
    $arrowWidth: 13px;
    $arrowWidth--lg: 40px;
    $arrowHeight: 7px;
    $arrowHeight--lg: 16px;
    border-bottom: 1px solid $color-orange-40;

    &:first-child {
        border-top: 1px solid $color-orange-40;
    }

    &__head {
        padding: 13px $arrowWidth + 10px 13px 0;
        position: relative;
        cursor: pointer;
        user-select: none;
        @include break-lg-up {
            padding: {
                right: $arrowWidth--lg + 10px;
                top: 30px;
                bottom: 30px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: $arrowWidth;
            height: 7px;
            background: url(../images/arrow-down.svg) no-repeat center / contain;
            @include transition-regular;
            transform: scaleY(1);
            margin-top: calc(-1 * $arrowHeight/2);
            
            .is-active & {
                transform: scaleY(-1);
            }
            @include break-lg-up {
                width: $arrowWidth--lg;
                height: 16px;
                margin-top: calc(-1 * $arrowHeight--lg/2);
            }
        }
    }
    &__body {
        display: none;
        font-size: 10px;
        padding-bottom: 13px;
        color: $color-blue;
        @include fontWeight400;
        p {
            margin-bottom: 1.2em !important;
            &:last-child {
                margin-bottom: 0 !important;
            }
            a {
                @include anchor()
            }
        }
        @include break-sm-up {
            font-size: 12px;
            padding-bottom: 20px;
        }
        @include break-lg-up {
            font-size: 18px;
            padding-bottom: 40px;
        }
        @include break-xl-up {
            font-size: 24px;
            // padding-bottom: 40px;
        }
    }
    &__title {
        margin-bottom: 0 !important;
    }
}
.accordeon {
    &__headline {
        @include article-element-mb;
        font-size: 12px;
        color: $color-orange;
        border-bottom: 2px dashed $color-blue-30;
        @include fontWeight600;
        @include break-lg-up {
            font-size: 20px;
        }
        @include break-xl-up {
            font-size: 30px;
        }
        @include transition-regular;
        &:hover {
            color: $color-blue;
        }
    }
    &__in {
        display: none;
    }
}

.external-link {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 12px;
    color: $color-blue-40;
    @include transition-regular;
    @include break-md-up {
        font-size: 16px;
        gap: 12px;
    }
    &:hover {
        color: $color-blue;
    }
    &__arrow {
        width: 15px;
        height: auto;

        @include break-md-up {
            width: 20px;
        }
    }
}

.bubble {
    @include border-radius15-35-50;
    border: 1px solid $color-orange-20;    
    color: $color-orange;
    font-size: 14px;
    min-height: 5.933em;
    width: 13.466em;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: $color-white;
    position: relative;

    &--tac {
        justify-content: center;
    }
    .widget-bubble & {
        @include break-lg-up {
            padding: 34px 50px;
        }
    }

    .block-step + & {
        margin-top: 30px;
        @include break-sm-up {
            margin-top: 40px;
        }
        @include break-lg-up {
            margin-top: 64px;
        }
    }

    @include break-lg-up {
        font-size: 20px;
        border-width: 2px;
    }
    @include break-xl-up {
        font-size: 30px;
    }

    &__text {
        font-weight: bold;
    }

    &::before, &::after {
        content: '';
        position: absolute;
    }
    &::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 18px 18px 0;
        border-color: transparent $color-orange-20 transparent transparent;
        top: 100%;
        right: 25px;
        @include break-sm-up {
            border-width: 18px 18px 0 0;
            border-color: $color-orange-20 transparent transparent transparent;
            right: auto;
            left: 25px;
        }
        @include break-lg-up {
            border-width: 24px 24px 0 0;
            left: 40px;
        }
        @include break-xl-up {
            border-width: 38px 38px 0 0;
            left: 60px;
        }
    }
    &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 16px 16px 0;
        border-color: transparent $color-white transparent transparent;
        top: 100%;
        right: 26px;
        @include break-sm-up {
            border-width: 16px 16px 0 0;
            border-color: $color-white transparent transparent transparent;
            right: auto;
            left: 26px;
        }
        @include break-lg-up {
            border-width: 22px 22px 0 0;
            left: 40.5px;
        }
        @include break-xl-up {
            border-width: 36px 36px 0 0;
            left: 60.5px;
        }
    }
}

.select-block {
    color: $color-blue;
    @include fontWeight400;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 40px;

    &__text {
        @include text-smaller;
    }
}

.article-card-icon-video {
    display: block;
    margin-left: auto;
    width: 12px;
    height: 12px;
    @include break-xl-up {
        width: 19px;
        height: 19px;
    }
    .main-page-press__content & {
        @include break-xs-down {
            width: 8px;
            height: 8px;
        }
    }
}

.cookie-text {
    position: fixed;
    z-index: 99999999999;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-blue;

    .content {
        color: $color-white;
        a {
            border-color: $color-white !important;
            &:hover {
                color: $color-white !important;
            }
        }
    }

    &__inner {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        @include break-sm-up {
            padding: 40px 0;
            flex-direction: row;
            justify-content: space-between;
            gap: 50px;
            align-items: flex-start;
        }
    }
    &__text {
        @include break-sm-up {
            max-width: 64%;
        }
    }
}