.widget-news {
    display: grid;
    gap: 15px 0;

    @include break-md-up {
        gap: 25px 0;
    }
    @include break-xl-up {
        gap: 33px 0;
    }
}

.widget-yellow {
    position: relative;
    z-index: 2;
    border-radius: 15px;
    background-color: $color-yellow;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    @include break-lg-up {
        border-radius: 35px;
        padding: 30px;
        gap: 30px;
    }
    @include break-xl-up {
        border-radius: 50px;
        padding: 55px;
    }
    &--main-paged {
        width: 97%;
        @include break-xs-up {
            width: auto;
        }
        @include break-md-up {
            position: absolute;
            width: 290px;
            right: 50px;
            top: 130px;
        }
        @include break-lg-up {
            // right: 70px;
            right: 5%;
            width: 25%;
        }
        @include break-xl-up {
            // top: 230px;
            // right: 5%;
            top: 170px;
            width: 466px;
        }
    }

    &__title {
        color: $color-white;
        font-weight: bold;
        line-height: 1;

        &--vcondensed {
            line-height: .8;
        }
    }
    &__title-big-text {
        font-size: 64px;
        display: block;
        @include break-lg-up {
            font-size: 96px;
        }
        @include break-xl-up {
            font-size: 128px;
        }
    }
    &__title-small-text {
        font-size: 24px;
        display: block;
        &--main-paged {
            // font-size: 4em;
            font-size: 40px;
        }
        @include break-lg-up {
            font-size: 48px;
        }
        @include break-xl-up {
            font-size: 64px;

            &--smaller {
                font-size: 61px;
            }
        }
    }
    &__text {
        color: $color-blue;
        font-size: 1em;
        @include fontWeight400;
        @include break-lg-up {
            font-size: 18px;
        }
        @include break-xl-up {
            font-size: 24px;
        }

        &--distanced {
            margin-bottom: 15px;
        }
    }
    &__bottom-image {
        display: block;
        margin: 0 auto;

        &--height-limited {
            @include break-sm-down {
                max-height: 120px;
            }
        }
    }

    @include glow;
    &::before {
        background: $color-yellow;
        box-shadow: 0px 0px 9em 5em $color-yellow;
        top: 2.5em;
        left: 2.8em;
    }

    hr {
        background-color: $color-white;
        opacity: .3;
        height: 1px;
        width: 100%;
        @include break-xl-up {
            margin: 10px 0;
            height: 2px;
        }
    }

    &__button-wrapper {
        
        &--distanced {
            margin-top: 15px;
            @include break-lg-up {
                margin-top: 35px;
            }
        }
    }
}

.widget-events {
    $paddingX: 20px;

    background: $color-white;
    position: relative;
    box-shadow: 0px 10px 40px 0px rgba(12, 71, 119, 0.10);
    border-radius: 15px;
    overflow: hidden;

    &--main-page {
        margin-left: -$paddingX;
        @include break-md-up {
            margin-left: 0;
        }
    }
    
    @include break-xl-up {
        border-radius: 30px;
    }

    &__list {
        padding-bottom: 5px;
        @include break-xl-up {
            padding: 36px 0;
        }

        &--main-page {
            @include break-xs-up {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: $paddingX 0;
            }
        }
    }
    &__head {
        background-color: $color-blue;
        color: $color-white;
        @include fontWeight600;
        font-size: 16px;
        padding: $paddingX*0.5 $paddingX;
        @include break-lg-up {
            font-size: 18px;
            padding: 14px $paddingX*2 - 2px;
        }
        @include break-xl-up {
            font-size: 24px;
            padding: 24px $paddingX*3;
        }
        a {
            display: inline-block;
            border-bottom: 1px solid $color-white-30;
            @include transition-regular;
            &:hover {
                color: $color-orange;
            }
        }
    }
    .widget-events-item {
        padding: 14px $paddingX;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        &:not(:last-child) {
            border-bottom: 1px solid $color-blue-5;
        }
        &--main-page {
            @include break-xs-up {
                border-bottom: none !important;
                &:nth-child(odd) {
                    border-right: 1px solid $color-blue-20;
                }
            }
        }
        @include break-lg-up {
            padding: 14px $paddingX*2 - 2px;
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        @include break-xl-up {
            gap: 15px;
            padding: 15px $paddingX*3;
            &:not(:last-child) {
                margin-bottom: 60px;
            }
        }

        &__date {
            font-size: 14px;
            color: $color-blue;
            @include fontWeight400;
            @include break-lg-up {
                font-size: 20px;
            }
            @include break-xl-up {
                font-size: 30px;
            }
        }
        &__name {
            font-size: 12px;
            color: $color-orange;
            @include fontWeight600;
            @include break-lg-up {
                font-size: 20px;
            }
            @include break-xl-up {
                font-size: 30px;
            }
            a {
                @include underline();
                @include transition-regular;
                &:hover {
                    color: $color-blue;
                }
            }
        }
        &__venue {
            color: $color-blue;
            margin-top: 4px;
            display: flex;
            gap: 5px;
            align-items: center;
            flex-wrap: wrap;
            @include fontWeight400;

            @include break-xl-up {
                font-size: 16px;
                gap: 15px;
            }
        }
        &__venue-city {
            display: flex;
            align-items: center;
            gap: 5px;
            svg {
                flex-shrink: 0;
                @include break-xl-up {
                    width: 16px;
                    height: 21px;
                }
            }
        }
        &__venue-cities {
            a {
                color: $color-orange;
                @include underline;
                @include transition-regular;
                &:hover {
                    color: $color-blue;
                }
            }
        }
    }
}
.widget-subscription {
    border-radius: 15px;
    box-shadow: 0px 5px 20px 0px $color-blue-15;
    padding: 18px;
    font-size: 14px;

    @include break-sm-up {
        &--order-changing-down {
            order: 1;
        }
        &--order-changing-up {
            order: -1;
        }
    }
    @include break-md-up {
        font-size: 16px;
    }

    &__title {
        color: $color-blue;
        @include fontWeight600;
    }
    .widget-subscription-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        &__input-wrapper {
            input {
                width: 100%;
                color: $color-blue-40;
                border: 1px solid;
                border-radius: $form-control-radius;
                padding: 13px;
                &:focus {
                    color: $color-blue;
                }
                &::placeholder {
                    color: currentColor;
                }
            }
        }
        &__submit-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            .submit-button {
                // font-size: 1em;
                @include break-sm-down {
                    font-size: 10px;
                }
            }
        }
    }
    
}

.widget-digest {
    $imgWidwth: 44px;
    $imgWidwth--lg: 80px;
    $imgWidwth--xl: 116px;

    @include widget-regular;
    background-color: $color-white;
    position: relative;
    color: $color-blue;
    border: 1px solid $color-light-green;

    @include break-lg-up {
        border-width: 2px;
    }

    @include glow;
    &::before {
        background: $color-light-green;
        box-shadow: 0px 0px 9em 5em $color-light-green;
        top: 4.5em;
        left: 2.8em;
        opacity: .3;
    }

    &__image {
        position: absolute;
        top: 25%;
        right: 20px;
        height: auto;
        width: $imgWidwth;
        max-height: 50px;
        
        @include break-lg-down {
            &--top-alterned {
                top: 15px;
            }
        }

        @include break-lg-up {
            top: 55px;
            width: $imgWidwth--lg;
            max-height: 85px;
            top: auto;
            bottom: 20px;
        }
        @include break-xl-up {
            width: $imgWidwth--xl;
            max-height: 125px;
            right: 32px;
            bottom: 35px;
        }
    }
    &__head {
        @include widget-title;
        margin-bottom: 10px;
        @include fontWeightNormal;
        font-weight: bold;
        .digest-grid-item & {
            position: relative;
            z-index: 2;
        }
        @include break-lg-up {
            margin-bottom: 20px;
        }
        @include break-xl-up {
            margin-bottom: 30px;
        }
        &--spaced {
            margin-bottom: 15px;
            @include break-lg-up {
                margin-bottom: 25px;
            }
            @include break-xl-up {
                margin-bottom: 40px;
            }
        }
        a {
            @include anchor($color-orange, $color-blue, $color-blue-20);
            padding-bottom: 0.1em;
            line-height: 1.4;
        }
    }
    &__title {
        font-size: 24px;
        @include fontWeightNormal;
        font-weight: bold;
        margin-bottom: .3em;
        color: $color-orange;
        @include break-lg-up {
            font-size: 40px;
        }

        a {
            @include anchor()
        }
    }
    &__description {
        margin-bottom: 14px;
        padding-right: $imgWidwth + 5px;
        @include break-xl-up {
            margin-bottom: 50px;
        }
    }
    &__description-small {
        margin-bottom: .6em;
        @include fontWeight400;
        @include break-lg-up {
            font-size: 16px;
        }
    }
    &__description-big {
        font-size: 14px;
        @include fontWeight600;
        @include break-lg-up {
            font-size: 20px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
}
.widget-lifehacks {
    $gap: 6px;
    @include widget-regular;
    background-color: $color-yellow;
    color: $color-white;

    &__text {
        display: flex;
        align-items: center;
        gap: 10px + $gap;
        font-weight: bold;
        margin-bottom: 20px;

        @include break-sm-up {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }
        @include break-lg-up {
            margin-bottom: 25px;
        }
        @include break-xl-up {
            margin-bottom: 35px;
        }
    }
    &__text-big {
        font-size: 83px;
        line-height: 0.8;
        letter-spacing: calc(-1 * $gap);
        
        @include break-sm-up {
            margin-bottom: 10px;
        }
        @include break-lg-up {
            font-size: 128px;
        }
    }
    &__text-small {
        font-size: 18px;
        
        @include break-xs-down {
            word-break: break-word;
        }
        @include break-lg-up {
            font-size: 30px;
        }        
        @include break-xl-up {
            font-size: 40px;
        }
    }
}
.widget-media {
    @include widget-regular(true, true);
    border: 1px solid $color-blue-30;

    @include break-lg-up {
        border-width: 2px;
    }
    
    &__title {
        @include widget-title;
        margin-bottom: 16px;
        @include break-lg-up {
            margin-bottom: 40px;
        }
        @include break-xl-up {
            margin-bottom: 45px;
        }
        a {
            font-weight: bold;
            @include anchor($color-orange, $color-blue, $color-blue-20);
        }
    }
}
.widget-media-item {
    $gap: 20px;
    $gap-xl: 30px;

    &:not(:last-child) {
        border-bottom: 1px solid $color-blue-5;
        margin-bottom: $gap;
        padding-bottom: $gap;
        @include break-xl-up {
            margin-bottom: $gap-xl;
            padding-bottom: $gap-xl;
        }
    }
    &__image-wrapper {
        overflow: hidden;
        border-radius: 15px;
        position: relative;
        padding-top: 61%;
        margin-bottom: 10px;

        @include break-lg-up {
            padding-top: 53%;
        }

        img {
            @include abs100;
            object-fit: cover;
        }
    }
    &__info {
        margin-bottom: 10px;
        font-size: 8px;
        color: $color-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include break-lg-up {
            font-size: 10px;
            justify-content: flex-start;
            gap: 5px;
        }
        @include break-xl-up {
            font-size: 16px;
            gap: 14px;
            margin-bottom: 15px;
        }
    }
    &__info-left {
        @include break-lg-up {
            display: flex;
        }
    }
    &__date {
        flex-shrink: 0;
        @include fontWeight600;
        opacity: .5;
        
        @include break-lg-up {
            width: 96px;
        }
        @include break-xl-up {
            width: 155px;
        }
    }
    &__source {
        @include fontWeight400;
    }
    &__info-right {
        flex-shrink: 0;
    }
    &__icon-play {
        display: block;
        @include break-lg-up {
            width: 12px;
            height: 12px;
        }
        @include break-xl-up {
            width: 19px;
            height: 19px;
        }
    }
    &__title {
        font-size: 14px;
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
        a {
            @include anchor($color-blue, $color-orange, $color-blue-30);
            @include fontWeight400;
        }
    }
}

.widget-button-wrapper {
    @include break-lg-up {
        width: 133px;
    }
    @include break-xl-up {
        width: 163px;
    }
}

.widget-advice {
    @include border-radius15-35-50;
    color: $color-white;
    background-color: $color-orange;
    padding: 23px;
    position: relative;
    
    @include glow;
    &::before {
        background: $color-orange;
        box-shadow: 0px 0px 10em 7em $color-orange;
        top: 5.5em;
        left: 3.8em
    }

    .content + & {
        margin-bottom: 18px;
    }

    @include break-lg-up {
        padding: 34px;
    }
    @include break-xl-up {
        padding: 45px 60px;
    }

    &__head {
        margin-bottom: 10px;
        @include widget-header;
    }
    &__body {
    }
    &__title {
        font-size: 16px;
        @include fontWeight600;
        margin-bottom: 15px;
        @include break-lg-up {
            font-size: 24px;
        }
        @include break-xl-up {
            margin-bottom: 22px;
        }
    }
    &__text {
        font-size: 12px;
        margin-bottom: 20px;
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 19px;
            margin-bottom: 30px;
        }
    }
    &__bottom-image {
        display: none;
        @include break-sm-up {
            width: 190px;
            display: block;
            @include mx-auto;
            margin-top: 35px;
        }
        @include break-xl-up {
            width: 340px;
        }
    }
    &__button-wrapper {
        @include break-sm-up {
            display: inline-block;
        }
    }
}

.widget-media-companies {

    &__title {
        @include widget-title;
        font-weight: bold;
        
        margin-bottom: 15px;
        @include break-lg-up {
            margin-bottom: 20px;
        }
        @include break-xl-up {
            margin-bottom: 30px;
        }

        a {
            @include anchor($color-orange, $color-blue, $color-blue-20)
        }
        &--blue {
            a {
                @include anchor($color-blue, $color-orange, $color-blue-20)
            }            
        }
    }
}

.widget-light-blue {
    color: $color-blue;
    border: 1px solid $color-blue;
    border-radius: 15px;
    padding: 20px;
    position: relative;
    margin-bottom: $triangelSize;

    @include break-sm-up {
        border-color: $color-blue-30;
        border-width: 2px;
    }
    @include break-lg-up {
        border-radius: 40px;
        padding: 25px 35px;
        margin-bottom: $triangelSize--lg;
    }
    @include break-xl-up {
        padding: 45px 60px;
        margin-bottom: $triangelSize--xl;
    }
    
    &::before, &::after {
        content: '';
        position: absolute;
    }
    &::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 $triangelSize $triangelSize 0;
        border-color: transparent $color-blue transparent transparent;
        top: 100%;
        right: 25px;
        @include break-sm-up {
            border-width: $triangelSize $triangelSize 0 0;
            border-color: $color-blue-30 transparent transparent transparent;
            right: auto;
            left: 25px;
        }
        @include break-lg-up {
            border-width: $triangelSize--lg $triangelSize--lg 0 0;
            left: 40px;
        }
        @include break-xl-up {
            border-width: $triangelSize--xl $triangelSize--xl 0 0;
            left: 60px;
        }
    }
    &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 16px 16px 0;
        border-color: transparent $color-white transparent transparent;
        top: 100%;
        right: 26px;
        @include break-sm-up {
            border-width: 16px 16px 0 0;
            border-color: $color-white transparent transparent transparent;
            right: auto;
            left: 26px;
        }
        @include break-lg-up {
            border-width: 22px 22px 0 0;
            left: 40.5px;
        }
        @include break-xl-up {
            border-width: 36px 36px 0 0;
            left: 60.5px;
        }
    }
}
.widget-podcast {
    @extend .widget-light-blue;
    margin-bottom: 20px;
    @include break-md-up {
        margin-bottom: 40px;
    }
    &__head {
        @include widget-header;
        margin-bottom: 10px;
        @include break-xl-up {
            margin-bottom: 40px;
        }
    }
    &__title {
        font-size: 14px;
        @include fontWeight600;
        margin-bottom: 15px;
        @include break-lg-up {
            font-size: 18px;
        }
        @include break-xl-up {
            font-size: 24px;
            margin-bottom: 25px;
        }
    }
    &__text {
        font-size: 10px;
        margin-bottom: 20px;
        @include fontWeight400;
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 19px;
            margin-bottom: 35px;
        }

        p:not(:last-child) {
            margin-bottom: 15px;
            @include break-xl-up {
                margin-bottom: 25px;
            }
        }
    }
}

.widget-lifted {
    @include break-sm-up {
        margin-top: -7%;
    }
}

.widget-quote {
    overflow: hidden;
    @include border-radius15-35-50;
    border: 1px solid $color-blue;
    position: relative;
    z-index: 10;

    @include
     break-lg-up {
        border-width: 2px;
    }

    &__image-wrapper {
        position: relative;
        padding-top: 86%;

        img {
            @include abs100;
            object-fit: cover;
        }
    }
    &__content {
        background-color: $color-white;
        padding: 20px;
        color: $color-blue;
        @include fontWeight400;

        @include break-lg-up {
            padding: 30px 40px;
        }
    }
    &__author {
        margin-bottom: 20px;
        @include break-lg-up {
            margin-bottom: 35px;
        }
    }
    &__title {
        font-size: 14px;
        margin-bottom: 5px;

        @include fontWeight600;

        @include break-md-up {
            font-size: 18px;
            margin-bottom: 15px;
        }
        @include break-xl-up {
            font-size: 24px;
        }
    }
    &__description {
        font-size: 10px;
        @include break-md-up {
            font-size: 12px;
        }
        @include break-xl-up {
            font-size: 16px;
        }
    }
    &__text {
        font-size: 12px;
        @include break-md-up {
            font-size: 14px;
        }
        @include break-xl-up {
            font-size: 19px;
        }

        p:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}
.widget-text {
    font-size: 12px;
    margin-top: 30px;
    @include fontWeight400;
    @include break-lg-up {
        font-size: 16px;
    }
    @include break-xl-up {
        font-size: 24px;
        margin-top: 70px;
    }
    a {
        @include anchor();
    }
}

.widget-form {
    @include border-radius15-35-50;
    box-shadow: 0px 10px 40px 0px $color-orange-15;
    padding: 25px;
    @include break-xs-up {
        padding: 35px 40px;
    }
}

.widget-cta {
    padding: 20px;
    @include border-radius10-20-30;
    @include break-md-up {
        padding: 40px;
    }

    .help-grid & {
        @include break-xl-down {
            display: flex;
            align-items: flex-end;
            gap: 20px;
        }
    }

    &__title {
        @include widget-header;
        margin-bottom: 15px;
        line-height: 1;
        @include break-md-up {
            margin-bottom: 30px;
        }
        .help-grid & {
            @include break-sm-up {
                margin-bottom: 35px;
            }
            @include break-md-up {
                margin-bottom: 45px;
            }
            @include break-xl-up {
                margin-bottom: 65px;
            }
        }
    }
    &__text {
        .help-grid & {
            flex-grow: 1;
        }
    }
    .button {
        @include break-md-up {
            min-width: 183px;
        }        
        .help-grid & {
            @include break-xl-down {
                min-width: 0;
            }
        }
    }

    &--blue {
        color: $color-blue;
        border: 1px solid $color-blue-30;
        @include break-lg-up {
            border-width: 2px;
        }
        button {
            // min-width: 0;

        }
    }
}
.widget-volonteer {
    background-color: $color-orange;
    color: $color-white;
    display: flex;
    align-items: center;

    &__text {
        flex-grow: 1;
    }
    &__image-wrapper {
        margin-left: 15px;
        max-width: 60px;
        @include break-sm-up {
            max-width: 25%;
            flex: 0 0 25%;
        }
        @include break-xl-up {
            max-width: 35%;
            flex: 0 0 35%;
        }
        .help-grid & {
            margin-left: 0;
            @include break-xl-up {
                max-width: 31%;
            }
            img {
                @include break-xl-down {
                    margin-left: -20px;
                }
                @include break-md-down {
                    margin-left: -30px;
                }
                @include break-sm-down {
                    margin-left: 0;
                }
            }
        }
    }
}
.widget-support-money {
    background-color: $color-yellow;
    color: $color-white;
    position: relative;

    &__image-wrapper {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 60px;
        @include break-md-up {
            bottom: 40px;
        }
        @include break-xl-up {
            // width: 160px;
            width: 32%;
            right: 40px;
        }
        .help-grid & {
            @include break-xl-down {
                position: static;
                flex: 0 0 35%;
                max-width: 35%;

                img {
                    margin-left: -10px;
                }
            }
            @include break-sm-down {
                max-width: 60px;
                img {
                    margin-left: 0;
                }
            }
        }
    }
}

.widget-bubbled {
    &__big-text {
        @include block-title;
        color: $color-orange;
    }
    &__regular-text {
        color: $color-blue;
        @include text-regular;
        @include fontWeight400;
    }
    &__text-block:not(:last-child) {
        margin-bottom: 20px;
        @include break-lg-up {
            margin-bottom: 45px;
        }
    }
    a {
        @include anchor();
    }
    @include break-xl-up {
        .bubbled-text-block {
            border-radius: 40px !important;
        }
    }
    &__title {
        color: $color-blue;
        @include text-bigger;
        @include fontWeight600;
        margin-bottom: 1em;
    }
}

.widget-bubble {
    margin-bottom: 15px;
    @include break-lg-up {
        margin-top: -65px;
    }
}

.widget-volonteer-blue {
    display: flex;
    align-items: flex-end;
    &__image-wrapper {
        flex-shrink: 0;
        margin-left: auto;
        img {
            width: 60px;
            @include break-md-up {
                margin-left: 10px;
            }
            @include break-xl-up {
                margin-left: 0;
                width: 142px;
            }
        }
    }
    &--partners &__image-wrapper img {
        width: 55px;
        margin-left: 5px;
        @include break-md-up {
            margin-left: 16px;
        }
        @include break-xl-up {
            width: 115px;
        }
    }
    &--questions {
        align-items: center;
    }
    &--questions &__image-wrapper {
        img {
            @include break-xl-up {
                width: 124px;
            }
        }        
    }
    .help-grid &--questions &__image-wrapper {
        img {
            @include break-xl-up {
                width: 100px;
            }
            @include break-xxl-up {
                width: 124px;
            }
        }  
    }
}


.widget-simple-w-button {
    @include widget-regular();

    &--red {
        @include widget-regular($color-red, $color-white);
    }

    &__text {
        font-size: 12px;
        @include break-lg-up {
            font-size: 16px;
        }
        @include break-xl-up {
            font-size: 19px;
        }

        p:not(:last-child) {
            margin-bottom: 1.5em;
        }
    }
    &__button-wrapper {
        margin-top: 20px;
        @include break-sm-up {
            width: fit-content;
        }
        @include break-xl-up {
            margin-top: 30px;
        }
    }
    .button {
        @include break-xl-up {
            min-width: 226px;
        }
    }
}

.widget-w-buttons {
    @include border-radius15-35-50;
    box-shadow: 0px 10px 40px 0px $color-orange-15;
    padding: 25px;
    @include break-sm-up {
        padding: 35px;
    }
    @include break-xl-up {
        padding: 60px;
    }
    a {
        word-break: break-word;
    }
    &__title {
        @include text-bigger;
        color: $color-orange;
        @include fontWeight400;
        margin-bottom: 1em;
    }
}

.widget-w-big-text {
    @include border-radius15-35-50;
    @include glow;
    background-color: $color-orange;
    &::before {
        top: 2.5em;
        left: 2.8em;
        box-shadow: 0px 0px 9em 5em $color-orange;
    }
    &--red {
        background-color: $color-red;
        &::before {
            background: $color-red;
            box-shadow: 0px 0px 9em 5em $color-red;
        }        
    }
    position: relative;
    color: $color-white;
    padding: 20px;
    @include break-md-up {
        padding: 30px;
    }
    @include break-xl-up {
        padding: 50px;
    }
    p {
        font-size: 16px;
        font-weight: bold;
        @include break-md-up {
            font-size: 26px;
        }
        @include break-xl-up {
            font-size: 40px;
        }
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}