.not-found {
    padding-top: 5%;
    @include break-sm-up {
        display: flex;
        align-items: center;
    }
    &__content {
        @include break-sm-up {
            padding-right: 60px;
        }
        @include break-lg-up {
            padding-right: 160px;
        }
    }
    &__title {
        font-size: 20px;
        margin-bottom: 8px;
        @include break-lg-up {
            font-size: 48px;
            margin-bottom: 26px;
        }
        @include break-xl-up {
            font-size: 64px;
        }
    }
    &__text {
        color: $color-blue;
        font-size: 14px;
        margin-bottom: 18px;
        @include break-lg-up {
            font-size: 20px;
            margin-bottom: 51px;
        }
        @include break-xl-up {
            font-size: 30px;
        }
    }
    &__image {
        width: 200px;
        margin-top: 24px;
        @include break-sm-up {
            flex: 0 0 34%;
            max-width: 34%;
        }

        img {
            width: 100%;
        }
    }
}