@use 'sass:math';
// Default variables
$font_size: 14px !default;
$font_size_small: 12px !default;

$input_border_radius: 15px !default;
$input_height: 38px !default;
$input_height_small: 36px !default;
$dropdown_padding: 18px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #e8e8e8 !default;
$gray_lighter: #f6f6f6 !default;
$primary_light: $gray !default;
$arrow_color: $gray !default;

.select {
  display: none;
}
// Style the dropdown
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: $input_border_radius;
  box-shadow: 0px 5px 20px 0px rgba(5, 70, 120, 0.07);
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  @include text-regular;
  font-weight: normal;
  // height: $input_height;
  // line-height: $input_height - 2;
  outline: none;
  // padding-left: $dropdown_padding;
  // padding-right: $dropdown_padding + 12;
  padding: 1em;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 19.4em;
  &:hover {
    // border-color: darken($gray_light, 5%);
  }
  &:active, &:focus {
    // border-color: $primary_light;
  }
  // Arrow
  &:after {
    $arrowSize: 1em;
    // border-bottom: 1px solid $color-blue;
    // border-right: 1px solid $color-blue;
    content: '';
    display: block; 
    height: $arrowSize;
    width: $arrowSize;
    margin-top: calc(-1 * $arrowSize/2);
    pointer-events: none;
    position: absolute; 
    right: 1.4em; 
    top: 50%; 
    /* transform-origin: 66% 66%;
    transform: rotate(45deg); */
    transition: all 0.15s ease-in-out;

    background: url(../images/arrow-down_blue.svg) no-repeat center / contain;
  }
  
  &.open {
    @extend :active;
    &:after {
      transform: scaleY(-1);
    }
    .nice-select-dropdown {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }
  &.disabled {
    border-color: lighten($gray_light, 2%);
    color: $gray;
    pointer-events: none;
    &:after { 
      border-color: lighten($arrow_color, 20%);
    }
  }
  
  // Modifiers
  &.wide {
    width: 100%;
    .nice-select-dropdown {
      left: 0 !important;
      right: 0 !important;
    }
  }
  &.right {
    float: right;
    .nice-select-dropdown {
      left: auto;
      right: 0;
    }
  }
  &.small {
    font-size: $font_size_small;
    height: $input_height_small;
    line-height: $input_height_small - 2;
    &:after { 
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: $input_height_small - 2;
      min-height: $input_height_small - 2;
    }
  }

  .nice-select-dropdown{
    margin-top: 4px;
    background-color: #fff;
    border-radius: $input_border_radius;
    box-shadow: 0 0 0 1px rgba($gray_dark, .11);
    width: auto;
    min-width: 100%;
    overflow: hidden;

    pointer-events: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(- math.div(-$input-height, 2));
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9;
    opacity: 0;
  }

  // List and options
  .list {
    border-radius: $input_border_radius;
    /* overflow: hidden;
    overflow-y: auto; */
    overflow: auto;
    padding: 0;
    max-height: 210px;
    scrollbar-color: $color-white;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $color-white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-blue;
      border-radius: 4px;
    }

    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
  }
  .option {
    cursor: pointer;
    font-weight: 400;
    // line-height: $input_height + 2;
    list-style: none;
    outline: none;
    // padding-left: $dropdown_padding;
    // padding-right: $dropdown_padding + 11;
    padding: .7em 1em;
    text-align: left;
    transition: all 0.2s;
    &:hover, &.focus, &.selected.focus { 
      background-color: $gray_lighter;
    }
    &.selected { 
      font-weight: bold;
    }
    &.disabled {
      background-color: transparent;
      color: $color-blue-40;
      cursor: default;
    }
  }
  
  .optgroup{
    font-weight: bold;
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .nice-select-dropdown {
    display: none;
  }
  &.open {
    .nice-select-dropdown {
      display: block;
    }
  }
}


/* .nice-select .list::-webkit-scrollbar {
  width: 0
} */


.nice-select{
  .has-multiple {
    white-space: inherit;
    height: auto;
    padding: 7px 12px;
    min-height: 36px;
    line-height: 22px;

    span.current {
      border: 1px solid #CCC;
      background: #EEE;
      padding: 0 10px;
      border-radius: 3px;
      display: inline-block;
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 3px;
      margin-right: 3px
    }

    .multiple-options {
      display: block;
      line-height: 24px;
      padding: 0
    }
  }

  .nice-select-search-box {
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    pointer-events: none;
    border-radius: 5px 5px 0 0;
  }

  .nice-select-search {
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid $gray_light;
    border-radius: 3px;
    color: $gray_dark;
    display: inline-block;
    vertical-align: middle;
    padding: 7px 12px;
    margin: 0 10px 0 0;
    width: 100%;
    min-height: 36px;
    line-height: 22px;
    height: auto;
    outline: 0!important;
    font-size: $font_size;
  }
}
